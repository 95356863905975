<template>
    <ap-tile class="new-tile-shadow tile">
        <!-- start loading spinner -->
        <div
            v-if="loadState === 1"
            class="proposal-tile--loading" >
            <ap-spinner />
            <div class="proposal-tile--loading--caption">
                <img :src="CheckIcon" class="proposal-tile--loading--caption--icon">
                <div class="proposal-tile--loading--caption--text">Ergebnisse werden geladen...</div>
            </div>
        </div>
        <!-- end loading spinner -->

        <!-- start error message -->
        <div v-else-if="loadState === 2 && !proposal">
            Es ist ein Fehler aufgetreten,
            bitte versuchen Sie es erneut.
            Wenn der Fehler weiterhin besteht, versuchen Sie es bitte später nochmal.
            Vielen Dank für Ihre Geduld!
        </div>
        <!-- end error message -->

        <!-- start content -->
        <div class="proposal-tile" v-else-if="loadState === 2 && proposal">
                <img :src="selectImageType()" class="proposal-tile--image--icon">
            <div class="proposal-tile--years">
                <div class="proposal-tile--years--title">{{ displayZinsbindung }} Jahre</div>
                <div class="proposal-tile--label">Zinsbindung</div>
            </div>
            <div class="proposal-tile--table">
                <hr>
                <div class="proposal-tile--property">
                    <div class="proposal-tile--label">Eff. Zins</div>
                    <div class="proposal-tile--value">{{ displayEffektivZins }}</div>
                </div>
                <hr>
                <div class="proposal-tile--property">
                    <div class="proposal-tile--label">Monatliche Rate</div>
                    <div class="proposal-tile--value">{{ displayGesamtRateProMonat }}</div>
                </div>
                <hr>
                <div class="proposal-tile--property">
                    <div class="proposal-tile--label">Erfolgsaussichten</div>
                    <div class="proposal-tile--value">{{ displayMachbarkeit }}</div>
                </div>
                <hr>
            </div>
            <div class="proposal-tile--grid">
                <div class="proposal-tile--property">
                    <div class="proposal-tile--label">Tilgungssatz</div>
                    <div class="proposal-tile--value">{{ displayTilgungssatzInProzent }}</div>
                </div>
                <div class="proposal-tile--property">
                    <div class="proposal-tile--label">Gesamtlaufzeit</div>
                    <div class="proposal-tile--value">{{ displayLaufzeit }}</div>
                </div>
                <div class="proposal-tile--property">
                    <div class="proposal-tile--label">Darlehens-<br>betrag
                        <info-box propertyId="loanAmount" ignore-on-hover />
                    </div>
                    <div class="proposal-tile--value">{{ displayDarlehensSumme }}</div>
                </div>
                <div class="proposal-tile--property">
                    <div class="proposal-tile--label">Restschuld nach {{ displayZinsbindung }} Jahren</div>
                    <div class="proposal-tile--value">{{ displayRestschuldNachZinsbindungsEnde }}</div>
                </div>
            </div>
            <ap-button 
                class="proposal-tile--button dark"
                label="Angebot anfordern"
                @click="openContactDialog()" />
        </div>
        <!-- end content -->
    </ap-tile>
</template>

<script setup>
import { defineProps, toRef, ref, computed } from 'vue'
import { useStore } from 'vuex'
import UserDataSubmissionDialog from '../UserDataSubmissionDialog.vue'
import InfoBox from '@/components/MatchingInvest/InfoBox.vue'
import openDialog from '@/essentials/components/ap-dialog/open-dialog'
import CheckIcon from '@/assets/icons/check-icon.svg'
import Bank from '@/assets/icons/bank-color-circle.svg'
import BankShield from '@/assets/icons/bank-shield-color-circle.svg'
import Shield from '@/assets/icons/shield-color-circle.svg'

const props = defineProps({
    proposal: Object,
    loadState: Number
})
const proposal = toRef(props, 'proposal')

const store = useStore()

const displayZinsbindung = computed(() => proposal.value
    ? `${proposal.value.zinsbindungInJahren}`
    : '')

const displayDarlehensSumme = computed(() => proposal.value
    ? `${proposal.value.darlehensSumme.toLocaleString('de')} €`
    : '')

const displayEffektivZins = computed(() => proposal.value
    ? `${proposal.value.effektivZins.toLocaleString('de')} %`
    : '')

const displayMachbarkeit = computed(() => proposal.value
    ? `${proposal.value.machbarkeit.toLocaleString('de')} %`
    : '')

const displayGesamtRateProMonat = computed(() => proposal.value
    ? `${toLocaleAndRound(proposal.value.gesamtRateProMonat)} €`
    : '')

const displayLaufzeit = computed(() => proposal.value
    ? `${proposal.value.laufzeit} Jahre`
    : '')

const displayRestschuldNachZinsbindungsEnde = computed(() => proposal.value
    ? `${toLocaleAndRound(proposal.value.restschuldNachZinsbindungsEnde)} €`
    : '')

const displayTilgungssatzInProzent = computed(() => proposal.value
    ? `${proposal.value.tilgungssatzInProzent.toLocaleString('de')} %`
    : '')

const isSubmissionAttempted = ref(false)

const openContactDialog = async () => {
    await store.dispatch('saveAnonymousUserData', {
        action: 'initFinancingProposalOrdered',
        query: proposal.value,
        expose: store.state.expose,
    })
    isSubmissionAttempted.value = true
    const sendRequestFunction = store._actions?.saveIdentifiedUserData[0]

    openDialog(UserDataSubmissionDialog, {
        title: 'Finanzierungsangebot anfordern',
        subtitle: 'Der beste Zins aus 500+ Banken.',
        email: store.state.anonymousUserEmail,
        callToAction: 'financingProposalOrdered',  // auf keinen Fall verändern - Niko
        sendRequestFunction: sendRequestFunction,
    })
}

const selectImageType = () => {
    if (displayZinsbindung.value === '5') {
        return Bank
    } if (displayZinsbindung.value === '10') {
        return BankShield
    } if (displayZinsbindung.value === '15') {
        return Shield
    } else {
        return Bank
    }
}

const toLocaleAndRound = (number) => Math.round(number).toLocaleString('de')
</script>

<style lang="scss" scoped>
.tile {
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 19rem;
    overflow: visible;
}

.proposal-tile {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.proposal-tile--button {
    margin: 0.5rem 0;
    width: 100%;
}

.proposal-tile--image--icon {
    height: 5rem;
}

.proposal-tile--label {
    color: #616569;
    font-size: 0.875rem;
    line-height: 1rem;
    position: relative;
    text-align: center;
}

.proposal-tile--loading {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    margin: auto 0;

    &--caption {
        align-items: center;
        display: flex;
        gap: 0.5rem;

        &--icon {
            height: 1.125rem;
            opacity: 1;
            animation: fadeInOut 3s ease-in-out infinite;
        }

        &--text {
            font-size: 0.875rem;
        }
    }
}

.proposal-tile--property {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
}

.proposal-tile--table {
    width: 100%;
}

.proposal-tile--value {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
}

.proposal-tile--years {
    text-align: center;

    &--title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
    }
}

.proposal-tile--grid {
    align-items: center;
    column-gap: auto;
    display: grid;
    grid-template-columns: repeat(2, 120px);
    justify-content: space-between;
    row-gap: 1rem;
    width: 100%;
}

.new-tile-shadow {
    backdrop-filter: blur(9.5px) !important;
    border: none !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
    min-height: 30rem;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 800px 0px rgba(143, 155, 186, 0.20) !important;
}

@media (max-width: 1200px) {
    .tile {
        width: 45%;
    }
}

@media (max-width: 750px) {
    .tile {
        width: 100%;
    }
}

.tile .info-box {
    right: -1.125rem;
    top: -0.125rem;
}
</style>
