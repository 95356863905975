<template>
    <div class="investor-properties">
        <div class="investor-properties--wrapper">
            <ap-radial-progress v-if="monthlyRate"
                class="investor-properties--monthly-rate"
                :value="monthlyRate"
                unit="€"
                :min="minMonthlyRate"
                :max="maxMonthlyRate"
                :lowerBreakpoint="0"
                :upperBreakpoint="0"
                :isDecreasingOrder="true"
                label="Mtl. Rate"
                :size="radialProgressSize" />
                <info-box propertyId="monthlyRateInvestor" />
        </div>
        <div class="investor-properties--wrapper">
            <ap-radial-progress v-if="rent"
                class="investor-properties--rent"
                :value="rent"
                unit="€"
                :min="minRent"
                :max="maxRent"
                :lowerBreakpoint="30"
                :upperBreakpoint="60"
                :isDecreasingOrder="true"
                label="Kaltmiete*"
                :size="radialProgressSize" />
            <info-box propertyId="rent" />
        </div>
        <div class="investor-properties--wrapper">
            <ap-radial-progress v-if="interest"
                class="investor-properties--interest"
                :value="interest"
                unit="%"
                :min="minInterest"
                :max="maxInterest"
                :lowerBreakpoint="75"
                :upperBreakpoint="45"
                :isDecreasingOrder="true"
                label="Zins*"
                :size="radialProgressSize" />
            <info-box propertyId="interest" />
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed, ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
// import ChartIncrease from '@/assets/icons/chart-increase.svg'
import InfoBox from '@/components/MatchingInvest/InfoBox.vue'

const props = defineProps({
    listing: {
        type: Object,
        default: undefined,
    },
})
const listing = toRef(props, 'listing')

const store = useStore()

const monthlyRate = computed(() => listing.value.monthly_rate)
const minMonthlyRate = computed(() => store.state.matchingInvestListingsMeta.minMonthlyRate)
const maxMonthlyRate = computed(() => store.state.matchingInvestListingsMeta.maxMonthlyRate)

const rent = computed(() => listing.value.rent)
const minRent = computed(() => monthlyRate.value * 0.5)
const maxRent = computed(() => monthlyRate.value * 1.05)

const interest = computed(() => listing.value.interest_in_percent)
const minInterest = computed(() => store.state.matchingInvestListingsMeta.maxInterest)
const maxInterest = computed(() => store.state.matchingInvestListingsMeta.minInterest)

// responsiveness start -> Changes size of radial charts from medium to small below screensize 900px.
const screenWidth = ref(window.innerWidth)
var radialProgressSize = "medium"

const checkScreen = () => {
    screenWidth.value = window.innerWidth
    if (screenWidth.value <= 500) {
        radialProgressSize = "small"
        return
    }
    radialProgressSize = "medium"
    return
}

onBeforeMount(() => {
    window.addEventListener('resize', checkScreen)
    checkScreen()
})
// responsiveness end

</script>

<style scoped>
.investor-properties {
    display: flex;
    gap: 2rem;
    /* justify-content: space-between; */
}

.investor-properties--wrapper {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.investor-properties--caption {
    font-size: 0.75rem;
}

.investor-properties--caption--icon {
    height: 12px;
}

.investor-properties--caption--sup {
    color: inherit;
}

@media (max-width: 1200px) {
    .investor-properties {
        gap: 0;
        justify-content: space-around;
    }
}

@media (max-width: 900px) {
    .investor-properties--caption {
        display: none;
    }
}

@media (max-width: 500px) {
    .investor-properties {
        justify-content: space-between;
    }
}
</style>