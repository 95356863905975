<template>
	<button
		ref="buttonRef"
		class="ap-button"
		:class="[size, variant, {
			'has-icon': icon,
			'full-width': fullWidth
		}]"
		:disabled="disabled"
		@click="emits('click', $event)">
		<!-- TODO: find something better for icons - why not use ap-icon here? -->
		<!-- eslint-disable-next-line vue/no-v-html -->
		<span v-if="icon" class="ap-button--icon" role="img" v-html="icon" />
		{{ label }}
	</button>
</template>

<script setup>
import { defineProps, defineEmits, ref, watchEffect } from 'vue'

const props = defineProps({
	label: String,
	variant: {
		type: String,
		default: 'default',
	},
	color: String,
	size: {
		type: String,
		default: 'medium',
	},
	fullWidth: Boolean,
	icon: String,
	disabled: {
		type: Boolean,
		default: false,
	},
})

const buttonRef = ref()

watchEffect(() => {
	if (props.color) {
		buttonRef.value?.style.setProperty('--accent-color', props.color)
	}
})

const emits = defineEmits(['click'])
</script>

<style lang="scss" scoped>
.ap-button {
	background-color: var(--accent-color);
	border: 0.125rem solid var(--accent-color);
	border-radius: 0.5rem;
	color: var(--font-color);
	cursor: pointer;
	filter: drop-shadow(0 0.25rem 0.5rem rgba(black, 12%));
	font-weight: 700;
	height: fit-content;
	line-height: 1.25;
	text-align: center;
	transition: background-color 200ms;
	width: fit-content;

	&.small {
		font-size: 0.875rem;
		padding: 0.25rem 1rem;
	}

	&.medium {
		font-size: 1rem;
		padding: 0.5rem 2rem;
	}

	&.large {
		font-size: 1rem;
		padding: 0.75rem 3rem;
	}

	&.default {
		--accent-color: #3fe395;
		--font-color: white;
	}

	&.dark {
		--accent-color: #39B3B7;
		--font-color: white;
	}

	&.has-icon {
		display: inline-flex;
	}

	&.full-width {
		width: 100%;
	}
}

.ap-button:hover:not(:disabled) {
	background-color: var(--font-color);
	color: var(--accent-color);
}

.ap-button:disabled {
	background-color: #D9D9D9;
	border-color: #D9D9D9;
	color: white;
	cursor: not-allowed;
}

.ap-button--icon {
	display: inline-block;
	height: 1rem;
	vertical-align: middle;
	width: 1rem;

	svg {
		display: block;
		fill: currentcolor;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: 575px) {
    .ap-button {
        width: 100%;
    }
}
</style>
