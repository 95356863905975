<template>
    <div class="unsubscribe-view">
        <ap-tile
            v-if="hasSentRequestToUnsubscribe"
            class="unsubscribe-view--body" >
            <div class="unsubscribe-view--body--subscribe">
                <div>{{ successfullyUnsubscribedMesssage }}</div>
                <ap-button
                    label="Suchauftrag wieder aufnehmen"
                    @click="orderSearchRequest()" />
            </div>
        </ap-tile>
        <ap-tile v-else-if="hasSentRequestSubscribe"
            class="unsubscribe-view--body" >
            <div class="unsubscribe-view--body--title">{{ successfullySubscribedMesssage }}</div>

        </ap-tile>
        <ap-tile 
            v-else
            class="unsubscribe-view--body" >
            <div class="unsubscribe-view--body--title">{{ whyUnsubscribingQuestion }}</div>
            <div class="unsubscribe-view--body--form">

                <label v-for="(reason, index) in unsubscribeReasons" :key=index>
                    <input @change="selectUnsubscribeReason(reason.id)" type="radio" name="unsubscribe-reason" >
                    <span class="radio-label">{{ reason.label }}</span>
                </label>
                <hr>
                <label>
                    <input @change="selectUnsubscribeReason('otherReason')" type="radio" name="unsubscribe-reason" >
                    <span class="radio-label">Anderer Grund:</span>
                    <input v-model="message" type="text" name="other_reason" >
                </label>
                <ap-button
                    :disabled="!isReasonGiven"
                    label="Abbestellen"
                    @click="unsubscribeUser()" />
            </div>
            <div v-if="showErrorMessage" class="error-message">
                Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.
                Wenn der Fehler weiterhin besteht, versuchen Sie es bitte später nochmal.
                Vielen Dank für Ihre Geduld!
            </div>
        </ap-tile>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const hasSentRequestToUnsubscribe = ref(false)
const hasSentRequestSubscribe = ref(false)
const message = ref('')
const unsubscribeReason = ref('')

const unsubscribeReasons = [
    {id: 'foundPropertyWithAp', label: 'Immobilie über Across Property gefunden'},
    {id: 'foundPropertyElsewhere', label: 'Immobilie anderswo gefunden'},
    {id: 'notInterestedAnymore', label: 'Präferenzen geändert / Ich suche nicht mehr'},
    {id: 'tooManyNotifications', label: 'Zu viele Benachrichtigungen'},
    {id: 'noRelevantMatches', label: 'Unpassende Vorschläge'},
]
const isReasonGiven = computed(() => !!unsubscribeReason.value)

const whyUnsubscribingQuestion = 'Wieso möchtest du deinen Suchauftrag abbestellen?'
const successfullyUnsubscribedMesssage = 'Dein Suchauftrag ist nun abbestellt.'
const successfullySubscribedMesssage = 'Dein Suchauftrag wurde wieder aufgenommen. Du kannst diese Fenster nun schließen.'

const selectUnsubscribeReason = (reason) => {
    unsubscribeReason.value = reason
}

const showErrorMessage = ref(false)

const unsubscribeUser = async () => {
    try {
        await store.dispatch('saveIdentifiedUserData', {
            action: {
                action: 'searchRequestCanceled',
                reason: unsubscribeReason.value,
                message: message.value,
            },
            contact: {},
            acceptedPrivacyPolicy: false,
            verifiedEmail: false,
            identifier: route.query.identifier,
        })
        hasSentRequestToUnsubscribe.value = true
        showErrorMessage.value = false
    } catch (error) {
        console.log(error)
        showErrorMessage.value = true
    }
}

const orderSearchRequest = async () => {
    try {
        await store.dispatch('saveIdentifiedUserData', {
            action: {
                action: 'searchRequestOrdered',
                reason: unsubscribeReason.value,
                message: message.value,
            },
            contact: {},
            acceptedPrivacyPolicy: false,
            verifiedEmail: false,
            identifier: route.query.identifier,
        })
        hasSentRequestSubscribe.value = true
        hasSentRequestToUnsubscribe.value = false
        showErrorMessage.value = false
    } catch (error) {
        console.log(error)
        showErrorMessage.value = true
    }
}

</script>

<style lang="scss" scoped>

label {
    display: block;
    margin-bottom: 1rem;
}
input[type="radio"] {
    display: none;
}
input[type="radio"]:checked + .radio-label::before {
    border-color: #39B3B7;
    background-color: #39B3B7;
}
input[type="radio"]:checked + .radio-label::after {
    opacity: 1;
}
input[type="text"] {
    display: none;
    margin-top: 0.375rem;
    padding: 0.375rem;
    border: 0.0625rem solid #ccc;
    border-radius: 5px;
    min-height: 7rem;
    width: 100%;
}
input[type="radio"]:checked + .radio-label + input[type="text"] {
    display: block;
}
.radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    color: #333;
}
.radio-label::before {
    content: "";
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid #39B3B7;
    border-radius: 0.25rem;
    background-color: white;
    transition: border-color 0.3s;
    margin-right: 0.625rem;
}
.radio-label::after {
    content: "\f00c"; /* Unicode character for checkmark (font-awesome) */
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 0.875rem;
    color: white;
    opacity: 0;
    transition: opacity 0.3s;
}
.unsubscribe-view {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8FBFE;
    height: 100vh;

    &--body {
        margin: 3rem;
        width: 1200px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &--title {
            text-align: center;
            font-weight: 600;
            font-size: 1.2rem;
        }

        &--subscribe {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }
}

.unsubscribe-view--body--form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.error-message {
    color: #fe5f55;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
}
</style>