<template>
        <ap-tile class="location-feature-list new-tile-shadow">
            <div
                v-for="(category, index) in categoryOptions"
                :key="index"
                class="location-feature-list--category-group" >

                <div
                    class="location-feature-list--category-group--switch"
                    @click="requestLocationFeatures(index)" >
                    
                    <div class="location-feature-list--category-group--switch--title">
                        <img
                            :src="makeLocationFeatureCategoryIconLink(category.label)"
                            class="location-feature-list--category-group--switch--title--category-icon" >
                        {{ category.label }}
                    </div>
                </div>
                <div
                    v-if="category.id === selectedCategory.id"
                    class="location-feature-list--category-group--locations" >

                    <ap-spinner
                        v-if="isLocationFeaturesLoading"
                        class="location-feature-list--category-group--locations--loader" />

                    <div
                        v-else-if="!isLocationFeaturesLoading && !!locationFeatures"
                        class="location-feature-list--category-group--locations--container" >

                        <div
                            v-for="(locationFeature, indexSecondary) in locationFeatures" :key="indexSecondary"
                            class="location-feature-list--category-group--locations--container--location"
                            @mouseover="selectLocationFeature(indexSecondary)"
                            @mouseleave="selectLocationFeature(-1)" 
                            @click="scrollToMap()">

                            <div
                                class="location-feature-list--category-group--locations--container--location--title" >
                                <img
                                    :src="makeLocationFeatureIconLink(locationFeature.category)"
                                    class="location-feature-list--category-group--locations--container--location--title--icon" >
                                {{ locationFeature.category }}:
                                {{ locationFeature.label }}
                            </div>
                            <div
                                class="location-feature-list--category-group--locations--container--location--distance" >
                                {{ locationFeature.distance_m }} m 
                            </div>

                        </div>

                    </div>
                    <div v-else>Ein Fehler ist aufgetreten.</div>
                </div>
                <hr class="location-feature-list--category-group--separator">
            </div>
        </ap-tile>
</template>

<script setup>
import { onMounted, ref, computed, defineEmits } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const isLocationFeaturesLoading = ref(false)

const selectedCategory = ref({ id: 'infrastructure_2', label: 'Infrastruktur' })
const categoryOptions = [
    { id: 'infrastructure_2', label: 'Infrastruktur' },
    { id: 'general_needs', label: 'Allgemeiner Bedarf' },
    { id: 'education', label: 'Bildung' },
    { id: 'health', label: 'Gesundheit' },
    { id: 'recreation', label: 'Freizeit' },
    { id: 'social_facilities', label: 'Soziale Einrichtungen' },
]

const locationFeatures = computed(() => store.state.locationFeatures.slice(0, 5))

const selectLocationFeature = (featureIndex) => {
    emit(
        'select-location-feature',
        { featureIndex },
    )
}

const requestLocationFeatures = async (index) => {
    selectedCategory.value = categoryOptions[index]
    const params = {
        lat: store.state.expose.geometry.coordinates[1],
        lng: store.state.expose.geometry.coordinates[0],
        category: selectedCategory.value.id,
    }
    isLocationFeaturesLoading.value = true
    await store.dispatch('getLocationFeatures', params)
    isLocationFeaturesLoading.value = false
}

const makeLocationFeatureIconLink = (category) => {
    return `https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/location-feature-icons/${category.replace('ä', 'ae').replace(' ', '_')}.svg`
}

const makeLocationFeatureCategoryIconLink = (category) => {
    return `https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/location-feature-icons/${category.replace('ä', 'ae').replace(' ', '_')}.svg`
}

onMounted(async () => {
    requestLocationFeatures(0)
})

const emit = defineEmits(['select-location-feature'])

const scrollToMap = () => {
    const anchor = '#map';
      window.location.href = anchor;
}

</script>

<style lang="scss" scoped>
.location-feature-list {
    background: #FFF !important;
    border: none !important;
    min-height: 38.75rem;
    min-width: 22rem;
    padding: 1rem !important;
    width: 35rem;
}

.location-feature-list--category-group--locations--container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
}

.location-feature-list--category-group--locations--container--location {
    align-items: center;
    border-radius: 0.25rem;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 0.5rem;
    transition: background 200ms;
}

.location-feature-list--category-group--locations--container--location:hover {
    background: #F4F4F4;
}

.location-feature-list--category-group--locations--container--location--distance {
    min-width: 3rem;
    text-align: right;
}

.location-feature-list--category-group--locations--container--location--title {
    display: flex;
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    transition: color 200ms;
}

.location-feature-list--category-group--locations--container--location:hover .location-feature-list--category-group--locations--container--location--title {
    color: #39B3B7;
}

.location-feature-list--category-group--locations--container--location--title--icon { 
    height: 1rem;
}

.location-feature-list--category-group--locations--loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.location-feature-list--category-group--separator {
    margin: 0.5rem 0;

}

.location-feature-list--category-group--switch {
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    transition: background 200ms;

    &--title--category-icon {
        height: 1.5rem;
    }
    
    &--chevron {
        height: 0.75rem;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    &--title {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1rem;
    }
}

.location-feature-list--category-group--switch:hover {
    background: #F4F4F4;
}

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

@media (max-width: 900px) {
    .location-feature-list {
        width: 100%;
        min-width: initial;
        background-color: none !important;
    }
}
</style>