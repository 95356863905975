 <template>
    <div class="main-criteria">
        <control-bar />
        <div class="main-criteria--title-section">
            <h1>{{ store.state.expose.title }}</h1>
            <secondary-properties :listing="store.state.expose"/>
        </div>
        <hr class="main-criteria--separator">
        <primary-properties :listing="store.state.expose"/>
        <hr class="main-criteria--separator">
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import ControlBar from './ControlBar.vue'
import PrimaryProperties from '../RealEstateProperties/PrimaryProperties.vue'
import SecondaryProperties from '../RealEstateProperties/SecondaryProperties.vue'

const store = useStore()

</script>

<style lang="scss" scoped>
h1 {
    font-weight: 600;
    line-height: 3rem;
}

.main-criteria {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.5rem;

    &--separator {
        margin: 0;
    }

    &--title-section {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}

.primary-properties {
    justify-content: space-between;
    margin-right: 2rem;    
}

@media (max-width: 1200px) {
    .main-criteria {
        border-radius: 2rem 2rem 0 0;
        background-color: #FFFFFF;
        margin-top: -2rem;
        padding: 2rem 1.5rem 0;
        z-index: 99
    }
}

@media (max-width: 575px) {
    h1 {
        font-size: 1.6rem !important;
        line-height: 2.5rem !important;
    }

    .main-criteria {
        gap: 1rem;
    }
}
</style>