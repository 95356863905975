<template>
    <svg
        class="ap-radial-progress"
        :class="[size]"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
            class="ap-radial-progress--offset"
            r="45"
            cx="50"
            cy="50" />
        <path
            ref="pathRef"
            class="ap-radial-progress--bar"
            d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
            :stroke="progressColor"
            :stroke-dasharray="length"
            :stroke-dashoffset="pathStrokeDashoffset" />
        <g class="ap-radial-progress--text-content" dominant-baseline="central">
            <text
                v-if="!hideValue"
                class="ap-radial-progress--text-content--percentage"
                x="50"
                :y="displayValueYPosition"
                :fill="progressColor">
                {{ displayValue }}
            </text>
            <text
                v-if="label"
                class="ap-radial-progress--text-content--label"
                x="50"
                :y="labelYPosition">
                {{ label }}
            </text>
        </g>
    </svg>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';

const props = defineProps({
    value: {
        type: Number,
        required: true,
    },
    unit: {
        type: String,
        default: '%',
    },
    min: {
        // TODO: allow also strings
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: 100,
        // TODO: include validator that max must be higher than min (apply also for slider)
    },
    // TODO: include validator that lower and upper breakpoints cannot be the same
    lowerBreakpoint: {
        type: Number,
        default: 25,
    },
    upperBreakpoint: {
        type: Number,
        default: 50,
    },
    label: String,
    size: {
        type: String,
        default: 'medium',
    },
    hideValue: {
        type: Boolean,
        default: false,
    },
    isDecreasingOrder: {
        type: Boolean,
        default: true,
    },
})

const pathRef = ref()
const minProgress = 1
const maxProgress = 98
const progress = computed(() => Math.min(Math.max((props.value - props.min) / (props.max - props.min) * 100 , minProgress), maxProgress))
const length = computed(() => pathRef.value?.getTotalLength() ?? 0)

// TODO: test if this works in Safari - otherwise include hack from https://codepen.io/zslabs/pen/yjdNZJ
const pathStrokeDashoffset = computed(() => length.value * (100 - progress.value) / 100)
const displayValue = computed(() => `${props.value.toLocaleString('de')} ${props.unit}`)
const displayValueYPosition = computed(() => props.label ? 40 : 50)
const labelYPosition = computed(() => props.hideValue ? 50 : 60)
const isDecreasingOrder = ref(props.isDecreasingOrder)//computed(() => props.upperBreakpoint > props.lowerBreakpoint)
const isInBadRange = computed(() => isDecreasingOrder.value ? isInLowerRange() : isInUpperRange())
const isInGoodRange = computed(() => isDecreasingOrder.value ? isInUpperRange() : isInLowerRange())
const progressColor = computed(() => {
    if (isInBadRange.value) {
        return '#F23985'
    }
    if (isInGoodRange.value) {
        return '#3FE395'
    }
    return '#F3B502'
})

const isInRange = (value, min, max) => {
    if (max === props.max) {
        return value >= min && value <= max
    }
    return value >= min && value < max
}
const isInLowerRange = () => isInRange(progress.value, minProgress, Math.min(props.lowerBreakpoint, props.upperBreakpoint))
const isInUpperRange = () => isInRange(progress.value, Math.max(props.lowerBreakpoint, props.upperBreakpoint), maxProgress+1)

</script>

<style lang="scss" scoped>
.ap-radial-progress {
    display: inline-flex;
    vertical-align: bottom;

    &.small {
        height: 5.75rem;
        width: 5.75rem;
    }

    &.medium {
        height: 6.5rem;
        width: 6.5rem;
    }

    &.large {
        height: 8rem;
        width: 8rem;
    }

    &--offset {
        fill: none;
        stroke: rgba(#C2C9D1, 25%);
        stroke-dasharray: 0;
        stroke-width: 0.3125rem;
    }

    &--bar {
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 0.3125rem;
        transform: rotate(-90deg) scaleX(-1);
        transform-origin: center center;
        transition: stroke-dashoffset 1000ms cubic-bezier(0.43, 0.41, 0.22, 0.91);
    }

    &--text-content {
        text-anchor: middle;
    }

    &--text-content--percentage {
        line-height: 1.75rem;
        font-size: 1.125rem;
        font-weight: 600;
    }

    &--text-content--label {
        fill: #91979D;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.125rem;
    }
}
</style>