<template>
    <div class="construction-year-filter">
        <label>Baujahr</label>
        <div class="construction-year-filter--inputs">
            <ap-input
                type="number"
                v-model="store.state.matchingInvestInputs.minConstructionYear"
                id="min-price"
                @blur="checkMinConstructionYear()" />
                <hr class ="construction-year-filter--inputs--hyphon">
            <ap-input
                type="number"
                v-model="store.state.matchingInvestInputs.maxConstructionYear"
                id="min-price"
                @blur= "checkMaxConstructionYear()" />
        </div>
        <hr class="construction-year-filter--separator">
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const minConstructionYearInput = 1900
const maxConstructionYearInput = 2025
const minConstructionYear = computed(() => store.state.matchingInvestInputs.minConstructionYear)
const maxConstructionYear = computed(() => store.state.matchingInvestInputs.maxConstructionYear)

const checkMinConstructionYear = () => {
    if (minConstructionYear.value < minConstructionYearInput)
    store.state.matchingInvestInputs.minConstructionYear = minConstructionYearInput
    if (minConstructionYear.value > maxConstructionYear.value)
    store.state.matchingInvestInputs.minConstructionYear = store.state.matchingInvestInputs.maxConstructionYear
    if (isReactive.value) {
        store.dispatch("updateUrl")
        store.dispatch("getMatchingInvestment")
    }
}
const checkMaxConstructionYear = () => {
    if (maxConstructionYear.value > maxConstructionYearInput)
    store.state.matchingInvestInputs.maxConstructionYear = maxConstructionYearInput
    if (minConstructionYear.value > maxConstructionYear.value)
    store.state.matchingInvestInputs.maxConstructionYear = store.state.matchingInvestInputs.minConstructionYear
    if (isReactive.value) {
        store.dispatch("updateUrl")
        store.dispatch("getMatchingInvestment")
    }
}

</script>

<style lang="scss" scoped>
.construction-year-filter {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.construction-year-filter .ap-slider {
    height: 40px !important;
}

.construction-year-filter .ap-input {
    width: 100%;
}

.construction-year-filter label {
    font-weight: 600;
}

.construction-year-filter--inputs {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.construction-year-filter--separator {
    margin: 1rem 0 0 0;
}

.construction-year-filter--inputs--hyphon {
    width: 10px;
}
</style>