<template>
    <div class="object-details">
        <h4 class="object-details--title">Objektdetails</h4>
        <hr class="object-details--separator">
        <div class="object-details--list">
            <div v-for="(feature, index) in propertyFeaturesTable" :key="index" class="object-details--list--item">
                <div v-if="feature.label" class="object-details--list--item--label">{{ feature.label }}:</div>
                <div class="object-details--list--item--value">{{ feature.value }}</div>
            </div>
        </div>
        <hr class="object-details--separator">
        <div class="object-details--table">
            <h6 class="object-details--table--title">Ausstattung</h6>
            <div class="object-details--table--content">
                <div v-for="(feature, index) in propertyFeaturesList" :key="index" class="object-details--table--content--item">
                    <img :src="CheckCircle" class="object-details--table--content--item--icon">
                    <div v-if="feature.label">{{ feature.label }}: {{ feature.value }}</div>
                    <div v-else>{{ feature.value }}</div>
                </div>
            </div>
        </div>
        <hr class="object-details--separator">
    </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import CheckCircle from '@/assets/icons/check-circle.svg'

const store = useStore()
const isBuyApartment = computed(() => store.state.matchingInvestExpose.propertyType === 'wohnung_kaufen')
const houseFeature = computed(() => store.state.expose.features.HOUSE)
const propertyFeature = computed(() => store.state.expose.features.PROPERTY)
const apartmentFeature = computed(() => store.state.expose.features.APARTMENT)
const buyTableFeatures = computed(() => filterDisplayTableFeatures(apartmentFeature.value))
const buyListFeatures = computed(() => filterDisplayListFeatures(apartmentFeature.value))
const rentTableFeatures = computed(() => houseFeature.value
    ? filterDisplayTableFeatures(houseFeature.value)
    : filterDisplayTableFeatures(propertyFeature.value))
const rentListFeatures = computed(() => houseFeature.value
    ? filterDisplayListFeatures(houseFeature.value)
    : filterDisplayListFeatures(propertyFeature.value))

const propertyFeaturesTable = computed(() => isBuyApartment.value
    ? buyTableFeatures.value
    : rentTableFeatures.value)
const propertyFeaturesList = computed(() => isBuyApartment.value
    ? buyListFeatures.value
    : rentListFeatures.value)

const filterDisplayListFeatures = (features) => features
    ? Object.values(features).filter(feature => feature.displayType === 'LIST')
    : []
const filterDisplayTableFeatures = (features) => features
    ? Object.values(features).filter(feature => feature.displayType === 'TABLE')
    : []
</script>

<style lang="scss" scoped>

.object-details--title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
}

.object-details--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 3rem;
    row-gap: 1.5rem;
}

.object-details--list--item {
    width: calc(33.3% - 2rem);
}
.object-details--list--item--label {
    font-size: 0.875rem;
}
.object-details--list--item--value {
    font-weight: 600;
}

.object-details--table {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--title {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.75rem;
    }
}

.object-details--table--content {
    column-gap: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1rem;


}

.object-details--table--content--item {
    align-items: center;
    display: flex;
    gap: 0.375rem;
    width: calc(50% - 2rem);

    &--icon {
        height: 1.25rem;
    }
}

@media (max-width: 575px) {

    .object-details--list {
        flex-direction: column;
    }

    .object-details--table--content {
        flex-direction: column;

        &--item {
            width: 100%;
        }
    }
}
</style>