<template>

    <ap-tile class="cashflow-tile new-tile-shadow">
        <div class="cashflow-tile--body">
            
            <h5 class="cashflow-tile--title">{{ displayTitle }}</h5>

            <div class="cashflow-tile--body--content">
                <div class="cashflow-tile--body--content--tile-left ap-tile">
                    <div class="investor-properties">
                        <div class="investor-properties--wrapper">
                            <ap-radial-progress v-if="monthlyRate"
                                :value="monthlyRate"
                                unit="€"
                                :min="minMonthlyRate"
                                :max="maxMonthlyRate"
                                :lowerBreakpoint="0"
                                :upperBreakpoint="0"
                                :isDecreasingOrder="true"
                                label="Mtl. Rate"
                                size="medium" />
                            <info-box propertyId="monthlyRateInvestor" />
                            
                        </div>

                        <div class="investor-properties--wrapper">
                            <ap-radial-progress v-if="rent"
                                :value="rent"
                                unit="€"
                                :min="minRent"
                                :max="maxRent"
                                :lowerBreakpoint="30"
                                :upperBreakpoint="60"
                                :isDecreasingOrder="true"
                                label="Kaltmiete*"
                                size="medium" />
                            <info-box class="info-box-rent" propertyId="rent" />
                            <a href="#rent-comparison" class="investor-properties--wrapper--caption">
                                <img :src="ChartIncrease" class="investor-properties--wrapper--caption--icon">
                                m<sup class="investor-properties--wrapper--caption--sup">2</sup> Miete anzeigen
                            </a>
                        </div>
                    </div>
                    <ap-tile v-if="displayCashflowBeforeTax" class="cashflow-tile--body--content--tile-left--calculation-box">
                        <div class="cashflow-tile--body--content--tile-left--calculation-box--title">Monatlicher Überschuss (Cashflow)</div>
                        <div class="cashflow-tile--body--content--tile-left--calculation-box--result">                    
                            <div class="cashflow-tile--body--content--tile-left--calculation-box--result--equals">=</div>
                            <div class="cashflow-tile--body--content--tile-left--calculation-box--result--number">{{ displayCashflowBeforeTax }}</div>
                        </div>
                    </ap-tile>
                </div>

                <div class="cashflow-tile--body--content--tile-right ap-tile">
                    <div class="cashflow-tile--body--content--tile-right--title">Modellierte Zinseinschätzung*</div>
                    <div class="cashflow-tile--body--content--tile-right--grid">
                        <div class="cashflow-tile--body--content--tile-right--grid--description">
                            Dieser Wert wird auf Ihren bisherigen Angaben und den marktüblichen Zinsen für diese Immobilie modelliert. 
                        </div>
                        <div class="investor-properties">
                            <ap-radial-progress v-if="interest"
                                :value="interest"
                                unit="%"
                                :min="minInterest"
                                :max="maxInterest"
                                :lowerBreakpoint="75"
                                :upperBreakpoint="45"
                                :isDecreasingOrder="true"
                                label="Eff. Zins*"
                                size="medium" />
                        </div>
                    </div>
                    <ap-button
                        label="Zinsvorschlag Berechnen"
                        variant="dark"
                        full-width
                        @click="scrollToFinancingCTA()" />
                </div>
            </div>
        </div>
    </ap-tile>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ChartIncrease from '@/assets/icons/chart-increase.svg'
import InfoBox from '@/components/MatchingInvest/InfoBox.vue'

const store = useStore()

const displayTitle = ref('Monatliche Überschuss Rechnung')


const monthlyRate = computed(() => store.state.expose.monthly_rate)
const minMonthlyRate = computed(() => store.state.exposeMeta.minMonthlyRate)
const maxMonthlyRate = computed(() => store.state.exposeMeta.maxMonthlyRate)

const rent = computed(() => store.state.expose.rent)
const minRent = computed(() => monthlyRate.value * 0.5)
const maxRent = computed(() => monthlyRate.value * 1.05)

const interest = computed(() => store.state.expose.interest_in_percent)
const minInterest = computed(() => store.state.exposeMeta.maxInterest)
const maxInterest = computed(() => store.state.exposeMeta.minInterest)

const displayCashflowBeforeTax = computed(() => store.state.expose.cashflow_before_tax
    ? `${store.state.expose.cashflow_before_tax.toLocaleString('de')} €`
    : undefined)

const scrollToFinancingCTA = () => {
    const url = '#financing-cta'
    window.location.href = url
}

</script>

<style lang="scss">
.cashflow-tile {
    overflow: visible !important;
    width: 100% !important;

    &--title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
    }
}

.cashflow-tile--investor {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cashflow-tile--body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--content {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}

.cashflow-tile--body--content .ap-tile {
    background-color: #FAFAFA;
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    width: 50%;
}

.cashflow-tile--body--content--tile-left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 100;

    &--description {
        font-size: 14px;
        line-height: 22px;
    }
}

.cashflow-tile--body--content--tile-left--calculation-box {
    background-color: #EBF7F8 !important;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1rem 2rem !important;
    width: 100% !important;

    &--title {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px; 
    }

    &--result {
        align-items: center;
        display: flex;
        gap: 0.75rem;

        &--equals {
            font-size: 48px;
            font-weight: 600;
            line-height: 48px;
            color: #616569;
        }

        &--number {
            border-radius: 1.25rem;
            background-color: #FFFFFF;
            font-size: 22px;
            font-weight: 600;
            line-height: 22px;
            padding: 0.5rem 1rem;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
        }
    }
}

// hide interest in left column of "Monatliche Überschuss Rechnung"
div.cashflow-tile--body--content--tile-left.ap-tile > div.investor-properties > svg.ap-radial-progress.medium.investor-properties--interest {
    display: none;
}

// hide interest in left column of "Ausgaben Planung"
 div.cashflow-tile--body--content--tile-left.ap-tile > div.owner-occupant-properties > svg.ap-radial-progress.medium.owner-occupant-properties--interest {
    display: none;
}

.cashflow-tile--body--content--tile-right {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &--title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }
}

.cashflow-tile--body--content--tile-right--grid {
    display: flex;
    gap: 1rem;

    &--description {
        font-size: 14px;
        line-height: 22px;
    }
}

// hide rent and monthly rate in right column of "Monatliche Überschuss Rechnung" 
div.cashflow-tile--body--content--tile-right.ap-tile > div.cashflow-tile--body--content--tile-right--grid > div.investor-properties > div {
    display: none;
}

div.cashflow-tile--body--content--tile-right.ap-tile > div.cashflow-tile--body--content--tile-right--grid > div.investor-properties > svg.ap-radial-progress.medium.investor-properties--monthly-rate {
    display: none;
}


// hide creditTerm and monthly rate in right column of "Ausgaben Planung" 
div.cashflow-tile--body--content--tile-right--grid > div.owner-occupant-properties > svg.ap-radial-progress.medium.owner-occupant-properties--monthly-rate {
    display: none;
}

div.cashflow-tile--body--content--tile-right--grid > div.owner-occupant-properties > svg.ap-radial-progress.medium.owner-occupant-properties--credit-term {
    display: none;
}

.investor-properties {
    align-items: flex-start;
    display: flex;
    gap: 2rem;
    /* justify-content: space-between; */
} 

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

.investor-properties--wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &--caption {
    font-size: 0.75rem;
    
        &--icon {
            height: 12px;
        }
        
        &--sup {
            color: inherit;
        }
    }
}

.info-box-rent {
    bottom: 1.125rem !important;
}

</style>