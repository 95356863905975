<template>
    <div class="general-properties">
        <div v-if="displayAddress" class="general-properties--property">
            <img class="general-properties--property-icon" :src="LocationPin">
            {{ displayAddress }}
            <span v-if="displayDistrict">, {{ displayDistrict }}</span>
        </div>
        <div 
            v-if="displayDwell"
            class="general-properties--property">
            <img class="general-properties--property-icon" :src="ClockPassedTime">
            {{ displayDwell }}
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import ClockPassedTime from '@/assets/icons/clock-passed-time.svg'
import LocationPin from '@/assets/icons/location-pin.svg'

const props = defineProps({
    listing: {
        type: Object,
        default: undefined,
    },
})
const listing = toRef(props, 'listing')

const displayAddress = computed(() => `${listing.value.postcode} ${listing.value.city}`)
const displayDistrict = computed(() => listing.value.district && !listing.value.city.includes(listing.value.district) ? `${listing.value.district}`: '')
const displayDwell = computed(() => {
    if (listing.value.dwell === 0) return 'Online seit heute'
    else if (listing.value.dwell === 1) return 'Online seit gestern'
    else return `Online seit ${listing.value.dwell} Tagen`
})

</script>

<style lang="scss" scoped>
.general-properties {
    display: flex;
    font-size: 0.875rem;
    gap: 1rem;
    line-height: 1.375rem;

    &--property {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
}

.general-properties--property-icon {
    height: 1.25rem;
    margin-right: 0.375rem;
}

@media (max-width: 500px) {
    .general-properties {
        flex-direction: column;
        gap: 0.25rem;
    }
}
</style>