<template>
    <div class="solar-financing-finder-contact-dialog-form">
        <div class="solar-financing-finder-contact-dialog-form--header">
            <div class="solar-financing-finder-contact-dialog-form--header--airplane-icon">
                <img :src="airplaneIcon" />
            </div>
            <h3>
                {{ title }}
            </h3>
            <p>
                {{ subtitle }}
            </p>
        </div>
        <div class="solar-financing-finder-contact-dialog-form--body">
            <ap-input
                v-model="firstName"
                label="Vorname*"
                disallow-white-spaces
                @isValid="toggleIsValidFirstName($event)" />
            <ap-input
                v-model="lastName"
                label="Nachname*"
                disallow-white-spaces
                @isValid="toggleIsValidLastName($event)" />
            <ap-input
                v-model="email"
                label="E-Mail*"
                type="email"
                ref="emailRef"
                disallow-white-spaces />
            <ap-input
                v-model="phone"
                label="Telefon*"
                type="tel"
                @isValid="toggleIsValidPhone($event)"
                @enter="sendRequest()" />
            <div class="checkbox">
                <input type="checkbox" v-model="hasAcceptedPrivacyPolicy" id="has-accepted-privacy-policy" />
                <label for="has-accepted-privacy-policy">Ich erkläre mich mit der <a href="https://www.acrossproperty.com/datenschutzerklaerung/" target="_blank" >Datenschutzerklärung</a> einverstanden.</label>
            </div>
            <p v-if="showErrorMessage" class="solar-financing-finder-contact-dialog-form--body--error-message">
                Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.
                Wenn der Fehler weiterhin besteht, versuchen Sie es bitte später nochmal.
                Vielen Dank für Ihre Geduld!
            </p>
        </div>
        <ap-button label="Anfrage Senden" full-width :disabled="!isValidForm" @click="sendRequest()" />
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'

const props = defineProps({
    showErrorMessage: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        default: '',
    },
    email: String,
})

const email = ref(props.email)
const firstName = ref()
const lastName = ref()
const phone = ref()

const isValidFirstName = ref(false)
const isValidLastName = ref(false)
const isValidEmail = computed(() => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return emailRegex.test(email.value)
})
const isValidPhone = ref(false)
const hasAcceptedPrivacyPolicy = ref(false)

const isValidForm = computed(() => 
    isValidFirstName.value &&
    isValidLastName.value &&
    isValidEmail.value &&
    isValidPhone.value &&
    hasAcceptedPrivacyPolicy.value)

const toggleIsValidFirstName = (isValid) => {
    isValidFirstName.value = isValid
}

const toggleIsValidLastName = (isValid) => {
    isValidLastName.value = isValid
}
const toggleIsValidPhone = (isValid) => {
    isValidPhone.value = isValid
}

const sendRequest = () => {
    const contact = {
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        phone: phone.value,
    }
    const action = {
        action: 'filledForm',
        query: contact,
    }
    const acceptedPrivacyPolicy = hasAcceptedPrivacyPolicy.value
    const verifiedEmail = false
    const identifier = email.value
    if (isValidForm.value) {
        emit(
            'send-request', {
            action,
            contact,
            acceptedPrivacyPolicy,
            verifiedEmail,
            identifier,
        })
    }
}

const emit = defineEmits(['send-request'])

const airplaneIcon = ref('https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/icons/airplane-icon.svg')

</script>

<style lang="scss" scoped>

a {
    color: #39B3B7;
    transition: color 300ms;
    
    &:hover {
        color: #2B898C;
    }
}
.checkbox {
    display: flex;
    gap: 1rem;
}
.solar-financing-finder-contact-dialog-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.solar-financing-finder-contact-dialog-form--header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;

// TODO: use ap-icon as soon as moved to clean repo
    &--airplane-icon {
        align-items: center;
        background-color: #D1FADF;
        border: 0.375rem solid #ECFDF3;
        border-radius: 1.75rem;
        display: flex;
        height: 3rem;
        justify-content: center;
        margin-bottom: 0.5rem;
        width: 3rem;

        > img {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

.solar-financing-finder-contact-dialog-form--body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &--error-message {
        color: #fe5f55;
        margin-top: 1rem;
    }
}
</style>