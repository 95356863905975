<template>
    <div class="pagination-control">
        <button v-if="!isFirstPage" class="pagination-control--ascending-page-button" @click="switchPage(currentPage - 1)"><img class="pagination-control--ascending-page-button--chevron" :src="ChevronGrey"></button>
        <button v-else disabled class="pagination-control--ascending-page-button" @click="switchPage(currentPage - 1)"><img class="pagination-control--ascending-page-button--chevron" :src="ChevronGrey"></button>
        
        <div v-if="!pageOptionRange.includes(1)" class="pagination-control--page-button" @click="switchPage(1)">1</div>
        <div v-if="!pageOptionRange.includes(1)">...</div>
        <div v-for="(pageNumber, index) in pageOptionRange" v-bind:key="index" @click="switchPage(pageNumber)">
            <div v-if="currentPage === pageNumber" class="pagination-control--page-button pagination-control--selected-page">
                {{ pageNumber }}
            </div>
            <div v-else class="pagination-control--page-button">
                {{ pageNumber }}
            </div>
        </div>
        <div v-if="!pageOptionRange.includes(totalNumberOfPages)">...</div>
        <div v-if="!pageOptionRange.includes(totalNumberOfPages)" class="pagination-control--page-button" @click="switchPage(totalNumberOfPages)">{{ totalNumberOfPages }}</div>
        
        <button v-if="!isLastPage" class="pagination-control--ascending-page-button" @click="switchPage(currentPage + 1)"><img class="pagination-control--ascending-page-button--chevron right" :src="ChevronGrey"></button>
        <button v-else disabled class="pagination-control--ascending-page-button" @click="switchPage(currentPage + 1)"><img class="pagination-control--ascending-page-button--chevron right" :src="ChevronGrey"></button>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import ChevronGrey from '@/assets/icons/chevron-grey.svg'

const store = useStore()

const isFirstPage = computed(() => store.state.matchingInvestInputs.page == 1)
const isLastPage = computed(() => store.state.matchingInvestListings.length < store.state.matchingInvestInputs.listingsPerPage)

const switchPage = (newPageNumber) => {
    store.state.matchingInvestInputs.page = Math.min(Math.max(1, newPageNumber), totalNumberOfPages.value)
    store.dispatch("updateUrl")
    store.dispatch("getMatchingInvestment")
}

const currentPage = computed(() => store.state.matchingInvestInputs.page)
const totalNumberOfPages = computed(() => store.state.matchingInvestListingsMeta.numberPages)

const firstPageOption = computed(() => 
    Math.max(currentPage.value - 2, 1)
)
const lastPageOption = computed(() =>
    Math.min(currentPage.value + 2, totalNumberOfPages.value)
)
const pageOptionRange = computed(() =>
    Array.from(
        { length: lastPageOption.value - firstPageOption.value + 1 },
        ( v, k ) => firstPageOption.value + k )
)

</script>

<style lang="scss" scoped>
.pagination-control--ascending-page-button {
    align-items: center;
    backdrop-filter: blur(9.5px);
    background-color: #FFF;
    border: 0.0625rem solid #D9D9D9;
    border-radius: 50%;
    display: flex;
    height: 2rem;
    justify-content: center;
    transition: background-color 200ms, filter 200ms;
    width: 2rem;
}

.pagination-control--ascending-page-button:hover {
    background-color: #F8FBFE;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
}

.pagination-control--ascending-page-button--chevron {
    height: 1rem;
}

.pagination-control--ascending-page-button--chevron.right {
    transform: scaleX(-1);
}

.pagination-control--page-button {
    align-items: center;
    background-color: #FFFFFF;
    border: 0.0625rem solid #39B3B7;
    border-radius: 0.25rem;
    color: #39B3B7;
    cursor: pointer;
    display: flex;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    transition: box-shadow 200ms;
}
.pagination-control--page-button:hover {
    box-shadow: 0 0 0px 4px #39b3b734;

}



.pagination-control--selected-page {
    background-color: #39B3B7;
    color: #FFFFFF;
}

.pagination-control--selected-page:hover {
    box-shadow: none;
}


.pagination-control {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
}

</style>