<template>
    <div class="listings-display">
        <div class="listing-display--title" ref="listingsRef">
            <div class="listing-display--title--text">
            <span class="listing-display--title--text--gradient">{{ numberOfHits }} Inserate</span> 
            in 
            </div>
            <location-input-summary
                :is-reactive="true"
                :is-disabled="true" />
        </div>

        <property-sort :is-reactive="true" />

        <ap-spinner v-if="store.state.loadStateData == 1" />

        <template v-if="store.state.loadStateData == 2">
            <div v-if="!hasListings">
                <search-suggestions />
            </div>
            <div v-else class="listings">
                <div v-for="(listing, index) in listings" :key="index" class="tile-iter">
                    <listing-tile
                        :listing="listing"
                        @click="openListing(listing)" />
                    <banner-tile-c-t-a v-if="index % 7 === 0" />
                </div>
                <banner-tile-c-t-a />
            </div>
        </template>
        <pagination-control />
    </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import BannerTileCTA from '@/components/MatchingInvest/CallToAction/BannerTileCTA.vue'
import ListingTile from '@/components/MatchingInvest/ListingsDisplay/ListingTile.vue'
import LocationInputSummary from './SearchInputs/LocationInputSummary.vue'
import PaginationControl from '@/components/MatchingInvest/ListingsDisplay/PaginationControl.vue'
import PropertySort from '@/components/MatchingInvest/ListingsDisplay/PropertySort.vue'
import SearchSuggestions from '@/components/MatchingInvest/ListingsDisplay/SearchSuggestions.vue'

const store = useStore()
const router = useRouter()

const listingsRef = ref(null)
const listings = computed(() => store.state.matchingInvestListings)
const hasListings = computed(() => listings.value?.length > 0)
const numberOfHits = computed(() => store.state.matchingInvestListingsMeta.numberOfHits?.toLocaleString('de'))

const openListing = (listing) => {
    listing.already_seen = true
    const routeData = router.resolve({
        name: 'expose',
        query: {
            sourceId: listing.source_id,
            propertyType: store.state.matchingInvestInputs.propertyType,
            ownFunds: store.state.matchingInvestInputs.ownFunds,
            maxMonthlyRate: store.state.matchingInvestInputs.maxMonthlyRate,
            isInvestor: store.state.matchingInvestInputs.isInvestor,
            incomeTaxRate: store.state.matchingInvestInputs.incomeTaxRate,
        }
    })
    window.open(routeData.href, '_blank')
}

watchEffect(() => {
    if (hasListings.value && listingsRef.value) {
        listingsRef.value.scrollIntoView()
    }
})
</script>

<style scoped lang="scss">
.listings-display {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 3rem;
    width: 100%;
}

.listing-display--title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: Outfit;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;

    > .location-boxes {
        margin-left: 0;
    }
}

.listing-display--title--text--gradient {
background: linear-gradient(180deg, #3FE395 0%, #39B3B7 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.listings {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.tile-iter {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media (max-width: 575px) {
    .listing-display--title {
        align-items: flex-start;
        flex-direction: column;
        gap: 0; 
    }
}
</style>