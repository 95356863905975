import { useStore } from "vuex"


export default () => {
    const store = useStore()
    const updateScreenSize = () => {
        if (window.innerWidth <= 575)
        store.state.screenSize = 'xs'
        else if (window.innerWidth <= 750)
        store.state.screenSize = 'sm'
        else if (window.innerWidth <= 900)
        store.state.screenSize = 'md'
        else if (window.innerWidth <= 1200)
        store.state.screenSize = 'lg'
        else
        store.state.screenSize = 'xl'
    }
    window.addEventListener('resize', updateScreenSize)
	updateScreenSize()
}