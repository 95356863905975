import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import router from './router'

const vueLocalStorage = new VuexPersistence({
    reducer: (state) => ({
        customerData: state.customerData,
        financingData: state.financingData,

        anonymousUserId: state.anonymousUserId,
        anonymousUserEmail: state.anonymousUserEmail,
    }),
})

const store = createStore({

    state: {
        // GENEREL
        // backendUrl: 'http://127.0.0.1:8000',
        backendUrl: 'https://ap-mvp.herokuapp.com',

        anonymousUserId: '',
        anonymousUserEmail: '',

        identifiedUserStatus: {
            isUserNew: true,
            isEmailVerified: false,
        },

        screenSize: 'md',
        // input options
        inputOptions: {
            communities: [
                'München',
                'Berlin',
                'Hamburg',
                'Köln',
                'Düsseldorf',
                'Stuttgart',
                'Frankfurt',
                'Leipzig',
                'Essen',
            ]
        },

        // loading states
        loadStateData: 0, // 0: default, 1: loading, 2: done loading, 3: failed
        // chart data
        chartsData: {},

        // EVALUATION VARIABLES
        financingData: {
            propertyName: '',
            financingPurpose: "KAUF",
            price: undefined,
            modernizationCost: 0,
            propertyType: "EIGENTUMSWOHNUNG",
            comercialUsage: false,
            street: '',
            houseNumber: '',
            postcode: undefined,
            city: undefined,
            constructionYear: undefined,
            livingArea: undefined,
            rent: 500,
            isRentedOut: false,
            income: undefined,
            explorationMode: false,
            // courtageInPercent: 3.57,
        },

        customerData: {
            birthDate: undefined,
            income: undefined,
            ownFunds: undefined,
            securities: 0,
            monthlyRate: undefined,
            employmentType: 'ANGESTELLTER',
            fixedInterest: false,
            commitmentInterestFreeTime: false,
        },
        financingProposals: [],

        // Matching Investment Inputs
        matchingInvestInputs: {
            locations: [],
            maxMonthlyRate: 1500,
            ownFunds: 30000,
            minPrice: 0,
            page: 1,
            listingsPerPage: 20,
            sortBy: "dwell",
            sortAscending: true,
            isImmoRoulette: false,
            userRole: '',
            isInvestor: true,
            incomeTaxRate: 42,
            minRooms: 0,
            minLivingArea: 10,
            propertyType: 'wohnung_kaufen',
            minConstructionYear: 1900,
            maxConstructionYear: 2025,
            featureFilters: {
                isRentedOut: false,
                hasBalcony: false,
                isGroundFloor: false,
                isTopFloor: false,
            },
            energyFilters: {
                isSolarEnergy: false,
                isGeothermalEnergy: false,
                isPelletsHeating: false,
                isWoodHeating: false,
                isDistrictHeating: false,
                isLiquidGasEnergy: false,
                isOlEnergy: false,
                isGasEnergy: false,
                isElectricEnergy: false,
                isCombinedHeatAndPowerUnit: false,
                isCoalEnergy: false,
            },
        },
        matchingInvestListings: [],
        matchingInvestListingsMeta: {
            numberOfHits: undefined,
            numberPages: undefined,
            startListingNumber: undefined,
            endListingNumber: undefined,
            minInterest: undefined,
            maxInterest: undefined,
            maxMonthlyRate: undefined,
            minMonthlyRate: undefined,
            minCreditTerm: undefined,
            maxCreditTerm: undefined,
        },
        postcodeGeoJson: [],
        locationFeatures: [],
        numberOfMatchingInvestListings: undefined,
        matchingInvestExpose: {
            sourceId: undefined,
            propertyType: undefined,
            ownFunds: undefined,
            maxMonthlyRate: undefined,
            isInvestor: undefined,
            incomeTaxRate: undefined,
        },
        expose: {},
        exposeStats: {},
        exposeMeta: {
            minInterest: undefined,
            maxInterest: undefined,
            maxMonthlyRate: undefined,
            minMonthlyRate: undefined,
            minCreditTerm: undefined,
            maxCreditTerm: undefined,
        },
    },

    mutations: {
        setIdentifiedUserStatus(state, userStatus) {
            state.identifiedUserStatus.isUserNew = userStatus.isUserNew
            state.identifiedUserStatus.isEmailVerified = userStatus.isEmailVerified
        },
        setInputOptions(state, inputOptions) {
            state.inputOptions = inputOptions
        },
        setLoadStateData(state, loadStateData) {
            state.loadStateData = loadStateData
        },
        setFinancingProposals(state, proposals) {
            state.financingProposals = proposals
        },
        clearFinancingProposals(state) {
            state.financingProposals = []
        },
        setMatchingInvestments(state, listings) {
            state.matchingInvestListings = listings
        },
        setMatchingInvestListingsMeta(state, metaData) {
            state.matchingInvestListingsMeta.numberOfHits = metaData.numberOfHits
            state.matchingInvestListingsMeta.numberPages = metaData.numberPages
            state.matchingInvestListingsMeta.startListingNumber = metaData.startListingNumber
            state.matchingInvestListingsMeta.endListingNumber = metaData.endListingNumber
            state.matchingInvestListingsMeta.minInterest = metaData.minInterest
            state.matchingInvestListingsMeta.maxInterest = metaData.maxInterest
            state.matchingInvestListingsMeta.minMonthlyRate = metaData.minMonthlyRate
            state.matchingInvestListingsMeta.maxMonthlyRate = metaData.maxMonthlyRate
            state.matchingInvestListingsMeta.minCreditTerm = metaData.minCreditTerm
            state.matchingInvestListingsMeta.maxCreditTerm = metaData.maxCreditTerm
        },
        setPostcodeGeoJson(state, geoJson) {
            state.postcodeGeoJson = geoJson
        },
        setLocationFeatures(state, features) {
            state.locationFeatures = features
        },
        setNumberOfMatchingInvestments(state, mumberOfListings) {
            state.numberOfMatchingInvestListings = mumberOfListings
        },
        setExpose(state, expose) {
            state.expose = expose
        },
        setExposeStats(state, exposeStats) {
            state.exposeStats = exposeStats
        },
        setExposeMeta(state, exposeMeta) {
            state.exposeMeta.minInterest = exposeMeta.minInterest
            state.exposeMeta.maxInterest = exposeMeta.maxInterest
            state.exposeMeta.maxMonthlyRate = exposeMeta.maxMonthlyRate
            state.exposeMeta.minMonthlyRate = exposeMeta.minMonthlyRate
            state.exposeMeta.minCreditTerm = exposeMeta.minCreditTerm
            state.exposeMeta.maxCreditTerm = exposeMeta.maxCreditTerm
        },
    },

    actions: {
        async saveAnonymousUserData({ state }, action) {
            action['anonymousUserId'] = state.anonymousUserId
            await axios.post(state.backendUrl + '/next-sale/matching-investments/data-acquisition/', {
                anonymousUserId: state.anonymousUserId,
                action: action,
            })
        },

        async saveIdentifiedUserData({ state, commit }, { action, contact, acceptedPrivacyPolicy, verifiedEmail, identifier }) {
            action['anonymousUserId'] = state.anonymousUserId
            if (state.expose.source_id) action['source_id'] = state.expose.source_id
            const { data } = await axios.post(state.backendUrl + '/next-sale/matching-investments/data-acquisition/user/', {
                acceptedPrivacyPolicy: acceptedPrivacyPolicy,
                action: action,
                anonymousUserId: state.anonymousUserId,
                contact: contact,
                identifier: identifier,
                verifiedEmail: verifiedEmail,
            })
            commit('setIdentifiedUserStatus', data)
            return data
        },

        async getInputOptions({ state, commit }) {
            const response = await axios.get(state.backendUrl + '/input-options-api/' + state.community)
            commit('setInputOptions', response.data.inputOptions)
        },

        async getFinancingProposalsCustomerSpecific({ state, commit }) {
            commit('setLoadStateData', 1)
            commit('clearFinancingProposals')

            try {
                const { data } = await axios.post(state.backendUrl + '/baufi/customer-property-specific/', {
                    financingData: state.financingData,
                    customerData: state.customerData,
                })
                commit('setFinancingProposals', data.vorschlaege)
                commit("setLoadStateData", 2)
            } catch (error) {
                console.log(error)
                commit("setLoadStateData", 3)
            }
        },

        updateUrl ({ state }) {
            router.replace({
                path: router.currentRoute.value.path,
                query: {
                    locations: state.matchingInvestInputs.locations,
                    propertyType: state.matchingInvestInputs.propertyType,
                    ownFunds: state.matchingInvestInputs.ownFunds,
                    maxMonthlyRate: state.matchingInvestInputs.maxMonthlyRate,
                    minPrice: state.matchingInvestInputs.minPrice,
                    page: state.matchingInvestInputs.page,
                    listingsPerPage: state.matchingInvestInputs.listingsPerPage,
                    sortBy: state.matchingInvestInputs.sortBy,
                    sortAscending: state.matchingInvestInputs.sortAscending,
                    isImmoRoulette: state.matchingInvestInputs.isImmoRoulette,
                    userRole: state.matchingInvestInputs.userRole,
                    isInvestor: state.matchingInvestInputs.isInvestor,
                    incomeTaxRate: state.matchingInvestInputs.incomeTaxRate,
                    minRooms: state.matchingInvestInputs.minRooms,
                    minLivingArea: state.matchingInvestInputs.minLivingArea,
                    minConstructionYear: state.matchingInvestInputs.minConstructionYear,
                    maxConstructionYear: state.matchingInvestInputs.maxConstructionYear,
                }
            })
        },

        async getMatchingInvestment({ state, commit }) {
            commit('setLoadStateData', 1)
            await store.dispatch('saveAnonymousUserData', {
                action: 'pageView',
                path: router.currentRoute.value.path,
                query: state.matchingInvestInputs,
                numberOfHits: store.state.matchingInvestListingsMeta.numberOfHits,
            })
            try {
                const { data } = await axios.post(state.backendUrl + '/next-sale/matching-investments/', {
                    locations: state.matchingInvestInputs.locations,
                    propertyType: state.matchingInvestInputs.propertyType,
                    ownFunds: state.matchingInvestInputs.ownFunds,
                    maxMonthlyRate: state.matchingInvestInputs.maxMonthlyRate,
                    minPrice: state.matchingInvestInputs.minPrice,
                    page: state.matchingInvestInputs.page,
                    listingsPerPage: state.matchingInvestInputs.listingsPerPage,
                    sortBy: state.matchingInvestInputs.sortBy,
                    sortAscending: state.matchingInvestInputs.sortAscending,
                    isImmoRoulette: state.matchingInvestInputs.isImmoRoulette,
                    userRole: state.matchingInvestInputs.userRole,
                    isInvestor: state.matchingInvestInputs.isInvestor,
                    incomeTaxRate: state.matchingInvestInputs.incomeTaxRate,
                    minRooms: state.matchingInvestInputs.minRooms,
                    minLivingArea: state.matchingInvestInputs.minLivingArea,
                    minConstructionYear: state.matchingInvestInputs.minConstructionYear,
                    maxConstructionYear: state.matchingInvestInputs.maxConstructionYear,
                    featureFilters: state.matchingInvestInputs.featureFilters,
                    energyFilters: state.matchingInvestInputs.energyFilters,
                })
                commit("setMatchingInvestments", data.listings)
                commit("setMatchingInvestListingsMeta", data.meta)
                commit("setLoadStateData", 2)
            } catch (error) {
                console.log(error)
                commit("setLoadStateData", 3)
            }
        },

        async getMatchingInvestmentExpose({ state, commit }) {
            commit('setLoadStateData', 1)
            try {
                const { data } = await axios.post(state.backendUrl + '/next-sale/expose/', {
                    sourceId: state.matchingInvestExpose.sourceId,
                    propertyType: state.matchingInvestExpose.propertyType,
                    ownFunds: state.matchingInvestExpose.ownFunds,
                    maxMonthlyRate: state.matchingInvestExpose.maxMonthlyRate,
                    isInvestor: state.matchingInvestExpose.isInvestor,
                    incomeTaxRate: state.matchingInvestExpose.incomeTaxRate,
                })
                commit('setExpose', data.expose)
                commit('setExposeMeta', data.meta)
                commit("setLoadStateData", 2)
                return 2
            } catch (error) {
                commit("setLoadStateData", 3)
                return 3
            }
        },

        async getMedianAreaPrice({ state, commit}) {
            commit('setExposeStats', {})
            try {
                const { data } = await axios.post(state.backendUrl + '/next-sale/expose/price-comparison/', {
                    propertyType: store.state.matchingInvestExpose.propertyType,
                    lat: state.expose.geometry.coordinates[1],
                    lng: state.expose.geometry.coordinates[0],
                })
                commit('setExposeStats', data)
            } catch (error) {
                console.log(error)
            }
        },

        async getGeoJson({ state, commit }, postcode) {
            try {
                const { data } = await axios.get(state.backendUrl + '/geo-api/' + postcode)
                commit('setPostcodeGeoJson', data.postcodeGeoJson)
            } catch (error) {
                console.log(error)
            }
        },
        
        async getLocationFeatures({ state, commit }, params) {
            commit('setLocationFeatures', [])
            try {
                const { data } = await axios.get(state.backendUrl + '/property/location', {params})
                commit('setLocationFeatures', data.locationFeatures)
            } catch (error) {
                console.log(error)
            }
        },
    },

    plugins: [vueLocalStorage.plugin],

})

export default store