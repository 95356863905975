<template>
    <div class="consultant-cta ap-tile new-tile-shadow">

        <img
            class="consultant-cta--contact-person-img"
            :src="displayConsultant.picture" alt="Across Property Consultant">

        <div class="consultant-cta--content">

            <div class="consultant-cta--content--teaser">
                <h6 class="consultant-cta--content--teaser--title">
                    Fragen zur Finanzierung oder dem Wert dieser Immobilie?
                </h6>
                <div class="consultant-cta--content--teaser--description">
                    Wir helfen dir diese Investition einzuschätzen oder nehmen einen kostenfreien Suchauftrag für dich an.
                </div>
            </div>

            <ap-button 
                class="consultant-cta--content--button dark"
                label="Kostenlose Erstberatung"
                @click="openContactDialog()" />

            <div class="consultant-cta--content--contact-info">
                <div class="consultant-cta--content--contact-info--name">
                    {{ displayConsultant.name }}
                </div>
                <div class="consultant-cta--content--contact-info--telephone">
                    <img
                        :src="Telephone" class="consultant-cta--content--contact-info--telephone--icon">
                    <div class="consultant-cta--content--contact-info--telephone--number">
                        {{ displayConsultant.phone }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import UserDataSubmissionDialog from './UserDataSubmissionDialog.vue'
import openDialog from '@/essentials/components/ap-dialog/open-dialog'
import Telephone from '@/assets/icons/telephone.svg'

const store = useStore()

const randomIndex = Math.round(Math.random())

const consultants = [
    {
        name: 'Ben Hutchison-Bird',
        picture: 'https://across-property-images.s3.eu-central-1.amazonaws.com/mortgage-advisors/ben_hutchison-bird.png',
        phone: '+49 176 924 76 593',
    },
    {
        name: 'Nikolas Vornehm',
        picture: 'https://across-property-images.s3.eu-central-1.amazonaws.com/mortgage-advisors/nikolas_vornehm.png',
        phone: '+49 157 316 62 285',
    }
]

const displayConsultant = ref(consultants[randomIndex])

const openContactDialog = async () => {
    const sendRequestFunction = store._actions?.saveIdentifiedUserData[0]

    openDialog(UserDataSubmissionDialog, {
        title: 'Lassen Sie sich zur Immobilie und dessen Finanzierung beraten.',
        subtitle: 'Unsere Experten gehen speziell auf Ihre Wünsche ein.',
        email: store.state.anonymousUserEmail,
        callToAction: 'consultantOrdered', // auf keinen Fall verändern - Niko
        sendRequestFunction: sendRequestFunction,
    })
}

</script>

<style lang="scss" scoped>
.consultant-cta {
    background: #3B4868;
    padding: 2.25rem 1.5rem 1.625rem;
    position: sticky;
    top: 50px;
    margin-top: 2.25rem;
    width: 25rem;
}

.consultant-cta--contact-person-img {
    height: 72px;
    left: calc(50% - 36px);
    position: absolute;
    top: -36px;
}

.consultant-cta--content {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    gap: 1rem;
    text-align: center;
    
    &--button {
        min-width: 100%;
    }

    &--contact-info {
        align-self: flex-start;
        text-align: left;
        margin-top: 0.5rem;

        &--name {
            font-weight: 600;
            line-height: 1.75rem;
        }
    }

    &--teaser {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        &--title {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.75rem;
            max-width: 260px;
        }
    }
}

.consultant-cta--content--contact-info {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

.consultant-cta--content--contact-info--telephone {
    display: flex;
    gap: 0.375rem;

    &--icon {
        height: 1.375rem;
    }
}

.consultant-cta--content--teaser--title, .consultant-cta--content--teaser--description, .consultant-cta--content--contact-info--name, .consultant-cta--content--contact-info--telephone--number {
    color: #FFF;
}

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

</style>