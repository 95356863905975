<template>
    <div
    class="ap-bar">
        <div class="ap-bar--headline" :style="fontColor">{{ headline }}</div>
        <div class="ap-bar--value" :style="fontColor">{{ valueLabel }}</div>
        <div class="ap-bar--label">{{ label }}</div>
        <svg
            class="ap-bar--chart"
            :viewBox="'0 0 ' + svgWidth +  ' 100'"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                v-if="primaryHeight"
                :width="svgWidth"
                :height="primaryHeight"
                :y="100-primaryHeight"
                rx="8"
                :fill="primaryColor" />
            <rect
                v-if="primaryHeight"
                :width="svgWidth"
                :height="primaryHeight/2"
                x="0"
                :y="100-primaryHeight+primaryHeight/2"
                :fill="primaryColor" />
            <rect
                v-if="primaryHeight"
                :width="svgWidth"
                :height="secondaryHeight"
                x="0"
                id="difference-marker"
                :y="100-props.secondaryHeight"
                :fill="secondaryColor" />
            <line
                v-if="secondaryLabel"
                x1="55"
                :y1="lineY1"
                x2="55"
                :y2="lineY2"
                stroke="#000" 
                stroke-width="1"
                marker-start="url(#arrowhead)"
                marker-end="url(#arrowhead)" />

            <defs>
                <marker 
                    id='arrowhead'
                    orient="auto-start-reverse" 
                    markerWidth='3'
                    markerHeight='4' 
                    refX='0.1' 
                    refY='2'
                    >
                    <path d='M0,0 V4 L2,2 Z' fill="black" />
                </marker>
            </defs>

            <foreignObject
                v-if="secondaryLabel"
                x="30%"
                :y="100-props.secondaryHeight-9-4"
                width="100"
                height="100">
                <div class="difference-value">{{ props.secondaryLabel }}</div>
            </foreignObject>

        </svg>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    primaryHeight: {
        type: Number,
        required: true,
    },
    secondaryHeight: {
        type: Number,
        default: 0,
        required: false,
    },
    secondaryIsLine: {
        type: Boolean,
        default: false,
    },
    secondaryLabel: {
        type: String,
        default: '',
    },
    primaryColor: {
        type: String,
        default: '#3fe395',
    },
    secondaryColor: {
        type: String,
        default: '#39B3B7',
    },
    labelColor: {
        type: String,
        default: '#3fe395',
    },
    headline: {
        type: String,
        required: false,
    },
    valueLabel: {
        type: String,
    },
    label: {
        type: String,
    }
})

const store = useStore()

const primaryHeight = computed(() => props.primaryHeight)
const secondaryHeight = computed(() => props.secondaryIsLine
    ? 1
    : props.secondaryHeight)
const primaryColor = computed(() => props.primaryColor)
const secondaryColor = computed(() => props.secondaryColor)
const labelColor = computed(() => props.labelColor)


const headline = computed(() => props.headline)
const fontColor = `
    color: ${labelColor.value};
`
const valueLabel = computed(() => props.valueLabel)
const label = computed(() => props.label)

const displayOnPhone = computed(() => ['xs'].includes(store.state.screenSize))
const svgWidth = displayOnPhone.value ? 48 : 66.66

const lineY1 = computed(() => primaryHeight.value > props.secondaryHeight
    ? 100-primaryHeight.value+2
    : 100-primaryHeight.value-2)
const lineY2 = computed(() => primaryHeight.value > props.secondaryHeight
    ? 100-props.secondaryHeight-2
    : 100-props.secondaryHeight+3)

</script>

<style lang="scss" scoped>
.ap-bar {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
}

.ap-bar--chart {
    height: 15rem;
}

.ap-bar--difference {
    background-color: #F3B502;
    border-radius: 0.25rem;
    color: #FFFFFF;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0.125rem 0.25rem;
    position: absolute;
}

.ap-bar--headline {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2.25rem;
}

.ap-bar--label {
    color: #616569;
    margin-bottom: 1rem;
    text-align: center;
}

.ap-bar--value {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2.25rem;
}

@media (max-width: 575px) {
    .ap-bar {
        justify-content: flex-end;

        &--label {
            font-size: 0.875rem;
        }
    }
}

.difference-value {
    background-color: #F3B502;
    border-radius: 0.125rem;
    color: #FFFFFF;
    font-size: 45%;
    line-height: 100%;
    padding: 1% 2%;
    text-align: center;
    height: 9%;
    width: 30%;
}

</style>