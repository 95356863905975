<template>
    <div class="primary-properties">
        <div v-if="displayPrice" class="primary-properties--property">
            <label>Angbotspreis:</label>
            <div class="primary-properties--value primary-properties--value--highlight">{{ displayPrice }}</div>
            <div class="primary-properties--subvalue">{{ displayPriceQm }}</div>
        </div>
        <div v-if="displayLivingArea"  class="primary-properties--property">
            <label>Wohnfläche:</label>
            <div class="primary-properties--value">{{ displayLivingArea }}</div>
        </div>
        <div v-if="displayRooms"  class="primary-properties--property">
            <label>Zimmer:</label>
            <div class="primary-properties--value">{{ displayRooms }}</div>
        </div>
        <div v-if="displayConstructionYear"  class="primary-properties--property">
            <label>Baujahr:</label>
            <div class="primary-properties--value">{{ displayConstructionYear }}</div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'

const props = defineProps({
    listing: {
        type: Object,
        default: undefined,
    },
})
const listing = toRef(props, 'listing')

const displayPrice = computed(() => listing.value.price ? `${listing.value.price.toLocaleString('de')} €`: '')
const displayPriceQm = computed(() => listing.value.price_m2 ? `(${listing.value.price_m2.toLocaleString('de')} € / m²)`: '')

const displayLivingArea = computed(() => listing.value.livingArea ? `${listing.value.livingArea.toLocaleString('de')} m²`: '')
const displayRooms = computed(() => listing.value.rooms ? `${listing.value.rooms}`: '')
const displayConstructionYear = computed(() => `${listing.value.constructionYear}`)

</script>

<style lang="scss" scoped>
label {
    color: #91979D;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.primary-properties {
    display: flex;
    gap: 3.5rem;

    &--value {
        color: #3B4868;
        font-size: 1.5rem;
        line-height: 2.25rem;

        &--highlight {
            font-weight: 600;
        }
    }

    &--subvalue {
        color: #39B3B7;
        font-size: 0.75rem;
        line-height: 1.125rem;
    } 
}

@media (max-width: 1200px) {
    .primary-properties {
        gap: 2rem;
        justify-content: space-around;
    }
}

@media (max-width: 900px) {
    .primary-properties--value {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

@media (max-width: 500px) {
    .primary-properties {
        display: grid;
        flex-wrap: wrap;
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        padding-left: 0.5rem;
    }
}
</style>