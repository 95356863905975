<template>
    <div class="energy-details">
        <h6 class="energy-details--title">Energie</h6>
        <div class="energy-details--content">
            <div v-for="(feature, index) in energytFeatures" :key="index" class="energy-details--content--item">
                <div v-if="feature.label" class="energy-details--content--item--label">{{ feature.label }}:</div>
                <div class="energy-details--content--item--value">{{ feature.value }}</div>
            </div>
            <div class="energy-details--content--item" v-if="displayEnergyPerformanceCertificate">
                <div class="energy-details--content--item--label">{{ displayEnergyPerformanceCertificateLabel }}: </div>
                <div class="energy-details--content--item--value">{{ displayEnergyPerformanceCertificate }}</div> 
            </div>
            <div class="energy-details--content--item" v-if="displayEnergyPerformanceCertificateValidUntil">
                <div class="energy-details--content--item--label">{{ displayEnergyPerformanceCertificateValidUntilLabel }}:</div> 
                <div class="energy-details--content--item--value">{{ displayEnergyPerformanceCertificateValidUntil }}</div>    
            </div>
            <div class="energy-details--content--item" v-if="displayEnergyEfficiencyClass">
                <div class="energy-details--content--item--label">{{ displayEnergyEfficiencyClassLabel }}:</div>
                <div class="energy-details--content--item--value">{{ displayEnergyEfficiencyClass }}</div>
            </div>
            <div class="energy-details--content--item" v-if="displayEnergyConsumption">
                <div class="energy-details--content--item--label">{{ displayEnergyConsumptionLabel }}:</div>
                <div class="energy-details--content--item--value">{{ displayEnergyConsumption }} kWh/(m<sup>2</sup>*a)</div>
            </div>
            <div class="energy-details--content--item" v-if="displayEnergyType">
                <div class="energy-details--content--item--label">{{ displayEnergyTypeLabel }}:</div>
                <div class="energy-details--content--item--value">{{ displayEnergyType }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const displayEnergyPerformanceCertificate = computed(() => store.state.expose.energy.energyPerformanceCertificate)
const displayEnergyPerformanceCertificateValidUntil = computed(() => store.state.expose.energy.energyPerformanceCertificateValidUntil
    ? new Date(store.state.expose.energy.energyPerformanceCertificateValidUntil).toLocaleDateString('de')
    : undefined)
const displayEnergyEfficiencyClass = computed(() => store.state.expose.energy.energyEfficiencyClass)
const displayEnergyConsumption = computed(() => store.state.expose.energy.energyConsumption?.toLocaleString('de'))
const displayEnergyType = computed(() => store.state.expose.energy.energyType)

const displayEnergyPerformanceCertificateLabel = 'Energieausweis'
const displayEnergyPerformanceCertificateValidUntilLabel = 'Gültig bis'
const displayEnergyEfficiencyClassLabel = 'Energieeffizienzklasse'
const displayEnergyConsumptionLabel = displayEnergyPerformanceCertificate.value === 'VERBRAUCHSAUSWEIS'
    ? 'Energieverbrauch'
    : 'Energiebedarf'
const displayEnergyTypeLabel = 'Energieträger'

const energytFeatures = computed(() => store.state.expose.features.ENERGY
    ? Object.values(store.state.expose.features.ENERGY)
    : [] )

</script>

<style lang="scss" scoped>

.energy-details--title {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
}

.energy-details--content {
    column-gap: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1rem;
}

.energy-details--content--item {
    width: calc(50% - 2rem);

    &--label {
        font-size: 0.875rem;
    }

    &--value {
        font-weight: 600;
    }
}

@media (max-width: 575px) {
    .energy-details--content { 
        flex-direction: column;

        &--item { 
            width: 100%;
        }
    }
}
</style>