<template>
    <div class="location-input" :class="locationInputClass" v-on-click-outside="closeInputOptionsDropdown">
        <LocationInputSummary
            v-if="isUnlimitedOptions"
            :is-reactive="isReactive" />
        <input
            v-model="store.state.community"
            class="location-input--text-field"
            type="text"
            placeholder="Suche nach Stadt / Landkreis / Gemeinde"
            @input="requestMatchingInputOptions()"
            @focus="showInputOptions()"
            @keyup.delete="removeLastLocation()" />
            <div
                v-if="isLocationInputFocused"
                class="location-input--options">
                <div v-if="showMaxSelectedInfo" class="location-input--options--no-options">
                    Es dürfen nur maximal 3 Standorte ausgewählt werden. Bitte entfernen Sie zunächst einen, um einen anderen auszuwählen.
                </div>
                <div
                    v-else
                    v-for="(option, index) in allPossibleLocations"
                    :key="index"
                    :value="option"
                    class="location-input--options--option"
                    @click="selectLocationInputOption(option)" >
                    {{ option }}
                </div>
                <div v-if="isAllPossibleLocationsEmpty"
                    class="location-input--options--no-options">
                    Es gibt keine weiteren Optionen.
                </div>
            </div>
            
        </div>
    <!-- <span class="warning" v-if="showErrorMessage">Bitte einen der Vorschläge auswählen</span> -->
</template>

<script setup>
import { defineProps, toRef, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { debounce } from 'lodash';
import LocationInputSummary from './LocationInputSummary.vue'

const props = defineProps({
    isUnlimitedOptions: {
        type: Boolean,
        default: false,
    },
    isReactive: {
        type: Boolean,
        default: false,
    }
})
const isUnlimitedOptions = toRef(props, 'isUnlimitedOptions')
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const selectedLocations = computed(() => store.state.matchingInvestInputs.locations)
const allPossibleLocations = computed(() => store.state.inputOptions.communities.filter(loc => !selectedLocations.value.includes(loc)))
const isValidLocationInputOption = computed(() => !!store.state.inputOptions.communities?.includes(store.state.community))
const isLocationInputFocused = ref(false)
// const showErrorMessage = computed(() => !!store.state.community && !isValidLocationInputOption.value)
const isAllPossibleLocationsEmpty = computed(() => allPossibleLocations.value.length === 0)
const showMaxSelectedInfo = computed(() => selectedLocations.value.length === 3)

const selectLocationInputOption = async (option) => {
    store.state.community = option
    await requestMatchingInputOptions()
}

const requestMatchingInputOptions = async () => {
    await requestNewInputOptions()
    saveInputOption()
}

const requestNewInputOptions = debounce( async () => {
    if (store.state.community) await store.dispatch('getInputOptions')
}, 200)

const saveInputOption = () => {
    if (isValidLocationInputOption.value && isUnlimitedOptions.value) {
        if (!store.state.matchingInvestInputs.locations.includes(store.state.community)) {
            store.state.matchingInvestInputs.locations.push(store.state.community)
            if (isReactive.value) {
                store.dispatch('updateUrl')
                store.dispatch('getMatchingInvestment')
            }
        }
        store.state.community = ''
    }
    if (isValidLocationInputOption.value && !isUnlimitedOptions.value) {
        store.state.matchingInvestInputs.locations = [store.state.community]
        if (isReactive.value) {
            store.dispatch('updateUrl')
            store.dispatch('getMatchingInvestment')
        }
    }
}

const showInputOptions = () => {
    isLocationInputFocused.value = true
}
const closeInputOptionsDropdown = () => {
    isLocationInputFocused.value = false
}

const previousInputLength = ref(0)
const removeLastLocation = () => {
    if (!store.state.community && !previousInputLength.value) {
        store.state.matchingInvestInputs.locations.pop()
    }
    previousInputLength.value = store.state.community.length
    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

const locationInputClass = computed(() => isLocationInputFocused.value
    ? 'location-input--active'
    : undefined)
</script>

<style lang="scss" scoped>
.location-input {
    background-color: #FFFFFF;
    border: 0.0625rem solid #C2C9D1;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.3125rem rgba(0, 0, 0, 0.1);
    display: flex;
    height: 3.625rem;
    position: relative;
    padding: 0.5rem 0;
    transition: border 200ms, box-shadow 200ms;
    width: 100%;
}

.location-input:hover, .location-input--active {
    border: #39B3B7 0.0625rem solid;
    box-shadow: 0 0 0.3125rem rgba(57, 179, 183, 0.3333333333);
}

.location-input--text-field {
    border: none;
    margin-left: 0.5rem;
    outline: none;
    width: 100%;
}

.location-input--text-field :first-child {
    font-weight: 600;
    height: 3.625rem;
}


.location-input--options {
    background-color: #FFFFFF;
    border: 0.0625rem solid #C2C9D1;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0;
    margin-top: 4rem;
    padding: 0.5rem 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    max-height: 300px;
    overflow: auto;

}

.location-input--options--option {
    color: #3B4868;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: background-color 150ms;

    &:hover {
        background-color: #F0F1F3;
    }
}

.location-input--options--no-options {
    color: #3B4868;
    padding: 0.5rem 1rem;
}
</style>
