<template>
    <div class="page-content">
        <start-search-view v-if="isStartSearch" />
        <search-results-view v-if="isResultsView" />
        <expose-view v-if="isExposeView" />
        <ap-footer />
    </div>
</template>

<script setup>
import { onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import StartSearchView from '@/components/MatchingInvest/StartSearchView.vue'
import SearchResultsView from '@/components/MatchingInvest/SearchResultsView.vue'
import ExposeView from '@/components/MatchingInvest/ExposeView.vue'
import UserDataSubmissionDialog from '@/components/MatchingInvest/CallToAction/UserDataSubmissionDialog.vue'
import openDialog from '@/essentials/components/ap-dialog/open-dialog'

const route = useRoute()
const store = useStore()

onBeforeMount( async () => {
    if (route.name == 'email-verification') {
        await store.dispatch('saveIdentifiedUserData', {
            action: {action: 'verifiedEmail'},
            contact: {},
            acceptedPrivacyPolicy: false,
            verifiedEmail: true,
            identifier: route.query.id,
        })
        openContactDialog()
    }
    if (!store.state.anonymousUserId) {
        store.state.anonymousUserId = uuidv4()
    }
})

const openContactDialog = async () => {
    openDialog(UserDataSubmissionDialog, {
        hasVerifiedEmail: store.state.identifiedUserStatus.isEmailVerified,
    })
}

const isStartSearch = computed(() =>
    route.name == 'start' ||
    route.name == 'email-verification')
const isResultsView = computed(() => route.name == 'results')
const isExposeView = computed(() => route.name == 'expose')
</script>