<template>
    <div class="search-box">
        <ap-tile class="search-box--tile">
            <property-usage-toggle :isTab="true" />
            <div class="search-box--tile--content">
                <div class="search-box--tile--content--body">
                    <property-type-toggle />
                    <div class="search-box--tile--content--body--input-column">
                        <div class="search-box--tile--content--body--input-column--location">
                            <location-input :isUnlimitedOptions="true"/>
                        </div>
                        <div class="search-box--tile--content--body--input-column--inputs">
                            <monthly-rate-input :isSlider="false"/>
                            <own-funds-input :isSlider="false"/>
                            <rooms-input />
                            <living-area-input :isSlider="false"/>
                        </div>

                    </div>
                    <ap-button
                        class="search-box--tile--content--body--button"
                        label="Suchen"
                        :disabled="!isValidLocationInputOption"
                        @click="findMatchingInvestments()" />
                </div>
            </div>
        </ap-tile>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import PropertyUsageToggle from './PropertyUsageToggle.vue'
import PropertyTypeToggle from './PropertyTypeToggle.vue'
import LocationInput from './LocationInput.vue'
import MonthlyRateInput from './MonthlyRateInput.vue'
import OwnFundsInput from './OwnFundsInput.vue'
import RoomsInput from './RoomsInput.vue'
import LivingAreaInput from './LivingAreaInput.vue'

const store = useStore()
const router = useRouter()
store.commit('setLoadStateData', 0)

const isValidLocationInputOption = computed(() => store.state.matchingInvestInputs.locations.length > 0)

const findMatchingInvestments = () => router.push({
    name: 'results',
    query: {
        locations: store.state.matchingInvestInputs.locations,
        propertyType: store.state.matchingInvestInputs.propertyType,
        ownFunds: store.state.matchingInvestInputs.ownFunds,
        maxMonthlyRate: store.state.matchingInvestInputs.maxMonthlyRate,
        minPrice: store.state.matchingInvestInputs.minPrice,
        page: store.state.matchingInvestInputs.page,
        listingsPerPage: store.state.matchingInvestInputs.listingsPerPage,
        sortBy: store.state.matchingInvestInputs.sortBy,
        sortAscending: store.state.matchingInvestInputs.sortAscending,
        isImmoRoulette: store.state.matchingInvestInputs.isImmoRoulette,
        userRole: store.state.matchingInvestInputs.userRole,
        isInvestor: store.state.matchingInvestInputs.isInvestor,
        incomeTaxRate: store.state.matchingInvestInputs.incomeTaxRate,
        minRooms: store.state.matchingInvestInputs.minRooms,
        minLivingArea: store.state.matchingInvestInputs.minLivingArea,
        minConstructionYear: store.state.matchingInvestInputs.minConstructionYear,
        maxConstructionYear: store.state.matchingInvestInputs.maxConstructionYear,
    }
})

</script>

<style lang="scss" scoped>
.search-box {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.search-box--tile {
    border: none;
    overflow: visible !important;
    padding: 0 !important;
    width: 54.25rem !important;

}
.search-box--tile--content {
    border-top: 1px solid #D9D9D9;
    display: flex;
    justify-content: center;
    
    &--body {
        background-color: #FFF;
        display: flex;
        gap: 0.75rem;
        padding: 1.5rem 1rem;
        max-width: 47rem;
        justify-content: center;

        &--button {
            height: 48px !important;
            margin: 0.3125rem 0;
        }
    }
}

.search-box--tile--content--body {
    border-radius: 1rem;
}

.search-box--tile--content--body--input-column {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    &--inputs {
        display: flex;
        gap: 0.75rem;
    }
    
    &--location {
        display: flex;
    }
}
.search-box--tile--content--body--input-column--row, label {
    color: #616569;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

@media (max-width: 750px) {
    .search-box--tile--content--body--input-column--inputs {
        flex-wrap: wrap;
        column-gap: 1rem;
        justify-content: space-between;

        > * {
            width: calc(50% - 0.5rem);

        }
        
    }
}

@media (max-width: 575px) {
    .search-box {
        margin-bottom: 5rem;
    }

    .search-box--tile--content--body {
        flex-direction: column;
    }

    .search-box--tile--content--body--input-column--inputs {
        flex-wrap: wrap;
        row-gap: 0.375rem;

        > * {
            width: 100%;
        }
    }
}
</style>
