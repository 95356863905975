<template>
    <div class="input-fields">

        <div class="input-fields--notice">
            <div class="input-fields--notice--icon">
                    <img :src="UserIcon" alt="" class="input-fields--notice--icon--user">
            </div>
            <div class="input-fields--notice--description">
                Diese Angaben sind notwendig um dir eine verlässliche Vorhersage zur Finanzierbarkeit zu geben.
            </div>
        </div>

        <div class="input-fields--form">
            <div class="input-fields--form--inputs">

                <div class="input-fields--form--inputs--input">
                    <label class="label">Beschäftigungsverhältnis</label>
                    <ap-dropdown
                        class="input-fields--form--inputs--input--field"
                        :value="selectedEmploymentType"
                        :options="employmentTypeOptions"
                        @select="selectEmploymentType($event)" />
                </div>

                <div class="input-fields--form--inputs--input">
                    <label class="label">Geburtsdatum</label>
                    <ap-input
                        class="input-fields--form--inputs--input--field"
                        type="date"
                        v-model="birthdate" />
                    <div v-if="showErrorMessages && !isUserAdult" class="error-message">*Du musst min. 18 Jahre alt sein</div>
                </div>

                <div class="input-fields--form--inputs--input">
                    <label class="label">Haushaltseinkommen mtl. (netto)</label>
                    <ap-input
                        class="input-fields--form--inputs--input--field"
                        type="number"
                        v-model="income"
                        unit="€"
                        @change="checkIncome()" />
                    <div v-if="showErrorMessages && !income" class="error-message">*Pflichtfeld</div>
                </div>

                <div class="input-fields--form--inputs--input">
                    <label class="label">Eigenkapital</label>
                    <ap-input
                        class="input-fields--form--inputs--input--field"
                        type="number"
                        v-model="ownFunds"
                        unit="€"
                        @change="checkOwnFunds()" />
                    <div v-if="showErrorMessages && !ownFunds" class="error-message">*Pflichtfeld</div>
                </div>

            </div>

            <ap-button
                class="calculate-button"
                label="Finanzierung für Sie persönlich berechnen"
                @click="loadFinancingProposals()" />

        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue'
import { useStore } from 'vuex'
import UserIcon from '@/assets/icons/user-icon.svg'

const store = useStore()

const selectedEmploymentType = ref({id: 'ANGESTELLTER', title: 'Angestellt'})
const employmentTypeOptions = [
    {id: 'ANGESTELLTER', title: 'Angestellt'},
    {id: 'ARBEITER', title: 'Arbeiter:in'},
    {id: 'BEAMTER', title: 'Verbeamtet'},
    {id: 'FREIBERUFLER', title: 'Freiberuflich'},
    {id: 'SELBSTAENDIGER', title: 'Selbstständig'},
    {id: 'RENTNER', title: 'In Rente'},
    {id: 'HAUSFRAU', title: 'Hausmann/-frau'},
    {id: 'ARBEITSLOSER', title: 'Arbeitslos'},
]
const selectEmploymentType = (option) => {
    selectedEmploymentType.value = option
}
const income = ref(store.state.customerData.income)
const birthdate = ref(store.state.customerData.birthDate)
const ownFunds = ref(store.state.matchingInvestExpose.ownFunds)

const checkIncome = () => {
    if (income.value < 0) income.value = 0
    if (income.value > 1000000) income.value = 1000000
}
const checkOwnFunds = () => {
    if (ownFunds.value < 0) ownFunds.value = 0
}
const isUserAdult = computed(() => {
    const now = new Date()
    const birthdateObject = new Date(birthdate.value)
    return now.getFullYear() - birthdateObject.getFullYear() >= 18
})

const isFormComplete = computed(() => !!income.value && !!isUserAdult.value && ownFunds.value >= 0)
const showErrorMessages = ref(false)

const loadFinancingProposals = async () => {
    if (isFormComplete.value) {
        emit(
            'request-financing-proposals', {
                income: income.value,
                selectedEmploymentType: selectedEmploymentType.value.id,
                birthdate: birthdate.value,
                ownFunds: ownFunds.value,
        })
    } else {
        showErrorMessages.value = true
    }
    scrollToProposals()
}

const emit = defineEmits(['request-financing-proposals'])

const scrollToProposals = () => {
    const url = '#financing-proposals'
    setTimeout(() => {
        window.location.href = url
    }, "500")
}


</script>

<style lang="scss" scoped>
$notice-height: 5.75rem;

.calculate-button {
    width: 100%;
}

.error-message {
    color: #fe5f55;
    font-size: small;
}

.input-fields {
    width: 50%;
}
.input-fields--form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
    width: 100%;

    &--inputs {
        column-gap: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 1rem;
    }
}
.input-fields--form--inputs--input {
    width: calc(50% - 1rem);
}
.input-fields--notice {
    align-items: center;
    background: rgba(63, 227, 149, 0.20);
    display: flex;
    gap: 1rem;
    padding: 1.5rem 1.5rem 1.5rem 0;
    height: 5.75rem;

    &--description {
        font-size: 0.875rem;
        line-height: 1.375rem;
    }

    &--icon {
        align-items: center;
        background-color: #3FE395;
        border-radius: 50%;
        display: flex;
        justify-content: center;

        &--user {
            height: 40px;
        }
    }
}
.input-fields--notice:before {
    background: rgba(63, 227, 149, 0.20);
    content: "";
    height: 5.75rem;
    left: 0rem;
    position: absolute;
    width: 1.5rem;
}

@media (max-width: 900px) {
    .input-fields--notice {
        background: none;
    }
    
    .input-fields--notice:before {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .input-fields--form--inputs--input {
        width: 100%;
    }

    .input-fields--notice{
        padding: 0;
    }

    .input-fields--notice:before {
        height: 7rem;
    }
}

</style>