<template>
            <div class="expose-cta">
            <div class="expose-cta--icon">
                <img :src="AddPicture" class="expose-cta--icon--svg">
            </div>
            <div class="expose-cta--content">
                <div class="expose-cta--content--description">
                    Erhalte Zugriff auf alle Bilder und Details zu diesem Inserat. 
                </div>
                <div class="expose-cta--content--link" @click="openExpose()">Exposé öffnen</div>
            </div>
        </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
// import UserDataSubmissionDialog from './UserDataSubmissionDialog.vue'
// import openDialog from '@/essentials/components/ap-dialog/open-dialog'
import AddPicture from '@/assets/icons/add-picture.svg'
import { computed } from 'vue'

const route = useRoute()
const store = useStore()
const exposeUrl = computed(() => store.state.expose.url)
const openExpose = async () => {
    await store.dispatch('saveAnonymousUserData', {
            action: 'exposeOrdered',
            path: route.fullPath,
    })
    window.open(exposeUrl.value, '_blank', 'noreferrer')
}

// const openContactDialog = async () => {
//     const sendRequestFunction = store._actions?.saveIdentifiedUserData[0]

//     const isEmailVerified = await openDialog(UserDataSubmissionDialog, {
//         title: 'In nur zwei Schritten zum Exposé weitergeleitet',
//         subtitle: 'Unter welchem Kontakt möchten Sie das Exposé erhalten und zu dieser Immobilie und Finanzierung informiert werden?',
//         email: store.state.anonymousUserEmail,
//         callToAction: 'exposeOrdered', // auf keinen Fall verändern - Niko
//         sendRequestFunction,
//     })

//     if(isEmailVerified) {
//         setTimeout(() => {
//             window.open(exposeUrl.value, '_blank', 'noreferrer')
//         }, 1000)
//     }
// }
</script>

<style lang="scss" scoped>
.expose-cta {
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 3rem 2rem;
    width: 18.5rem;
    text-align: center;
    position: absolute;

    &--content {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &--description {
            font-size: 0.875rem;
        }

        &--link {
            cursor: pointer;
            text-decoration: underline !important;
            color: #39B3B7;
            transition: color 300ms;

            &:hover {
                color: #2B898C;
            }
        }
    }

}

.expose-cta--icon {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    height: 3rem;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: -1.5rem;
    transform: translate(-50%, 0);
    width: 3rem;

    &--svg {
        width: 1.75rem;
    }
}

</style>