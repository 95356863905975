<template>
    <div class="rooms-input">
        <label for="rooms-input--field">Zimmer ab</label>
        <ap-dropdown
            id="rooms-input--field"
            :value="selectedNumberOfRooms"
            :options="numberOfRoomsOptions"
            @select="selectNumberOfRooms($event)" />
    </div>
</template>

<script setup>
import { defineProps, toRef, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false,
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()
const numberOfRoomsOptions = [
    {id: 1, title: '1 Zi.'},
    {id: 2, title: '2 Zi.'},
    {id: 3, title: '3 Zi.'},
    {id: 4, title: '4 Zi.'},
    {id: 5, title: '5 Zi.'},
    {id: 6, title: '6 Zi.'},
]
const selectedNumberOfRooms = ref(numberOfRoomsOptions.find(numberOfRooms => numberOfRooms.id === store.state.matchingInvestInputs.minRooms) ?? numberOfRoomsOptions[0])

const selectNumberOfRooms = (option) => {
    selectedNumberOfRooms.value = option
    store.state.matchingInvestInputs.minRooms = option.id
    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

</script>

<style lang="scss" scoped>
</style>