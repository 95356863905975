<template>
    <div class="title-image-listings">
        <img class="title-image-listings--real-estate-image" :src="imgUrl" alt="Wohnung mit Fensterblick und Möbeln">
        <div class="title-image-listings--disclaimer">Musterbild: Originalaufnahmen im Exposé</div>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'

const props = defineProps({
    rooms: {
        type: Number,
        default: undefined,
    },
    constructionYear: {
        type: Number,
        default: undefined,
    },
    livingArea: {
        type: Number,
        default: undefined,
    },
})
const rooms = toRef(props, 'rooms')
const constructionYear = toRef(props, 'constructionYear')
const livingArea = toRef(props, 'livingArea')

const imgUrl = computed(() => {

    const bj = computed(() => constructionYear.value > 2000 ? 1 : 0)
    const z = computed(() => rooms.value > 2 ? 1 : 0)
    const r = computed(() => Math.round(livingArea.value) % 12)

    const url = `https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/real-estate-images/ap-apartment_bj${bj.value}_z${z.value}_r${r.value}.jpg`

    return url
})

</script>

<style scoped lang="scss">
.title-image-listings {
    border-radius: 0.5rem;
    display: flex;
    max-height: 12rem;
    max-width: 15.5rem;
    min-width: 15.5rem;
    overflow: hidden;
    position: relative;

    &--disclaimer {
        bottom: 0.5rem;
        color: #FFF;
        font-size: 12px;
        opacity: 0.9;
        position: absolute;
        right: 0.7rem;
        text-align: right;
        font-style: italic;
        font-weight: 600;
    }

    &--real-estate-image {
        filter: blur(4px);
        height: calc(100% + 1rem);
        object-fit: cover;
        width: calc(100% + 1rem);

    }
}


@media(max-width: 1200px) {
    
    .title-image-listings {
        max-height: 300px;
        max-width: 100%;
    }
}
</style>