<template>
    <div class="title-image-expose new-tile-shadow">
        <img :src="imgUrl" alt="Bild" class="title-image-expose--background" >
        <expose-c-t-a />
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import ExposeCTA from '@/components/MatchingInvest/CallToAction/ExposeCTA.vue'

const store = useStore()
const rooms = computed(() => store.state.expose.rooms)
const constructionYear = computed(() => store.state.expose.constructionYear)
const livingArea = computed(() => store.state.expose.livingArea)

const imgUrl = computed(() => {

    const bj = computed(() => constructionYear.value > 2000 ? 1 : 0)
    const z = computed(() => rooms.value > 2 ? 1 : 0)
    const r = computed(() => Math.round(livingArea.value) % 12)

    const url = `https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/real-estate-images/ap-apartment_bj${bj.value}_z${z.value}_r${r.value}.jpg`

    return url
})

</script>

<style lang="scss" scoped>
.title-image-expose {
    align-items: center;
    border-radius: 0.5rem;
    display: flex;
    height: auto;
    justify-content: center;
    max-height: 24.125rem;
    max-width: 31rem;
    min-width: 31rem;
    overflow: hidden;
    position: relative;
}

.title-image-expose--background {
    filter: blur(5px);
    height: calc(100% + 1rem);
    object-fit: cover;
    width: calc(100% + 1rem);
}

.new-tile-shadow {
    backdrop-filter: blur(9.5px) !important;
    border: none !important;
    box-shadow: -10px 80px 140px 0px rgba(143, 155, 186, 0.20) !important;
    transition: box-shadow 300ms;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 80px 80px 0px rgba(143, 155, 186, 0.20) !important;
}

@media (max-width: 1200px) {
    
    .title-image-expose {
        border-radius: 0;
        height: 19rem;
        max-width: 100%;
        min-width: initial;
    }
}
</style>