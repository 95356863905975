<template>
    <div v-if="isTab" class="property-usage-toggle--head">
        <div class="property-usage-toggle-wrapper">
            <div
                class="property-usage-toggle-wrapper--button"
                :class="{ selected: !isInvestor }"
                @click="selectView(viewOptions[0])">
                {{ viewOptions[0].title }}
            </div>
            <div
                class="property-usage-toggle-wrapper--button"
                :class="{ selected: isInvestor }"
                @click="selectView(viewOptions[1])">
                {{ viewOptions[1].title }}
            </div>
        </div>
    </div>
    <div v-else>
        <ap-selector-switch
            :value="selectedView"
            :options="viewOptions"
            @select="selectView($event)" />
    </div>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue'
import { booleanProp } from 'vue-ts-types'
import { useStore } from 'vuex'

const props = defineProps({
    isTab: booleanProp().withDefault(true),
    isReactive: booleanProp().withDefault(false)
})

const store = useStore()
const isReactive = computed(() => props.isReactive)
const isInvestor = computed(() => store.state.matchingInvestInputs.isInvestor)

const viewOptions = computed(() => [
    {id: 0, value: false, title: 'Eigenheim'},
    {id: 1, value: true, title: 'Kapitalanlage'},
])
const selectedView = ref(isInvestor.value ? viewOptions.value[1] : viewOptions.value[0])

const selectView = (newView) => {
    selectedView.value = newView.value ? viewOptions.value[1] : viewOptions.value[0]
    updateIsInvestor(newView.value)

    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

const updateIsInvestor = (isInvestor) => {
    store.state.matchingInvestInputs.isInvestor = isInvestor
    store.state.matchingInvestInputs.page = 1
}
</script>

<style lang="scss" scoped>

.property-usage-toggle--head {
    background-color: #d9d9d9;
    border-radius: 0.5rem 0.5rem 0 0;
}

.property-usage-toggle-wrapper {
    display: flex;
    text-align: center;

    :first-child {
        border-radius: 0.5rem 0 0 0;
    }

    :last-child {
        border-radius: 0 0.5rem 0 0;
    }
}

.property-usage-toggle-wrapper--button {
    background-color: #d9d9d9;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    color: #808080;
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 1.25rem 0;
    transition: background-color 200ms ease-in;
    width: 50%;

    &.selected {
        background:  #FFF;
        border: 1px solid #D9D9D9;
        border-bottom: none;
        box-shadow: unset;
        color: #3b4867;
        cursor: unset;
        transition: background-color 200ms ease-in;
    }
}

@media (max-width: 500px) {
    .property-usage-toggle-wrapper--button {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}
</style>