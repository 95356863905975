 <template>
    <div class="control-bar">
        <div class="control-bar--back-to-results" @click="closeTab()">
            <img :src="ChevronLeft" class="control-bar--back-to-results--chevron-icon">
            Zur Ergebnisliste
        </div>
        <div class="control-bar--actions">
            <div class="control-bar--actions--print" @click="printPage()">
                <img :src="Printer" class="control-bar--back-to-results--icon">
            </div>
            <div class="control-bar--actions--share" @click="togglePopup()">
                <img :src="ShareSocials" class="control-bar--back-to-results--icon">
            </div>
            <social-sharing-modal
                v-if="popupTrigger"
                @toggle-popup="togglePopup($event)" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

import SocialSharingModal from './SocialSharingModal.vue'

import ChevronLeft from '@/assets/icons/chevron-left.svg'
import Printer from '@/assets/icons/printer.svg'
import ShareSocials from '@/assets/icons/share-socials.svg'

const closeTab = () => {
    window.close()
}

const printPage = () => {
    window.print()
}

const popupTrigger = ref(false)

const togglePopup = () => {
    popupTrigger.value = !popupTrigger.value
}

</script>

<style lang="scss" scoped>
.control-bar {
    display: flex;
    justify-content: space-between;

    &--actions {
        display: flex;
        gap: 0.75rem;
        height: 1.75rem;
        position: relative;
        width: 3.5rem;

        &--print {
            display: flex;
            height: 1.75rem;
            width: 1.75rem;
        }

        &--share {
            display: flex;
            height: 1.75rem;
            width: 1.75rem;
        }
    }

    &--back-to-results {
        align-items: center;
        color: #39B3B7;
        display: flex;
        font-size: 14px;
        gap: 0.375rem;
        line-height: 22px;
        text-decoration: none;
        position: relative;
        transition: color 200ms ease-in-out;

        &--icon {
            cursor: pointer;
        }
    }

    &--back-to-results:hover {
        // text-decoration: underline;
        color: #35989c;
        cursor: pointer;
    }
}

.control-bar--back-to-results--chevron-icon {
    height: 0.75rem;
    transition: transform 200ms ease-in-out;
}

.control-bar--back-to-results:hover .control-bar--back-to-results--chevron-icon {
    transform: translateX(-0.25rem);
}
</style>