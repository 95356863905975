<template>
    <div class="monthly-rate-input">
        <label for="monthly-rate-input--field">Rate bis</label>
        <ap-input
            id="monthly-rate-input--field"
            v-model="store.state.matchingInvestInputs.maxMonthlyRate"
            type="number"
            size="small"
            unit="€"
            @change="checkBounds()" />
    </div>
</template>

<script setup>
import { defineProps, toRef, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false,
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const minValue = ref(100)
const maxValue = ref(10000)

const checkBounds = () => {
    if (store.state.matchingInvestInputs.maxMonthlyRate < minValue.value) store.state.matchingInvestInputs.maxMonthlyRate = minValue.value
    if (store.state.matchingInvestInputs.maxMonthlyRate > maxValue.value) store.state.matchingInvestInputs.maxMonthlyRate = maxValue.value
    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

</script>

<style lang="scss" scoped>
#monthly-rate-input--field > :first-child {
    height: 2.5rem;
}
</style>