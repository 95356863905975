<template>
    <ap-button
        class="toggle-mobile-filter"
        label="Filter"
        @click="toggleMobileFilterMenu()"/>
    <div v-show="isFilterMenuOpen" class="filter-menu-mobile">
        <div class="filter-menu-mobile--topbar">
            <div class="filter-menu-mobile--topbar--title">Filter</div>
            <div class="filter-menu-mobile--topbar--close" @click="close()">
                <img :src="CloseCross">
            </div>
        </div>
        <div class="filter-menu-mobile--content">
            <property-usage-toggle :isTab="false" :is-reactive="false" />
            <hr class="filter-menu-mobile--content--separator">
            <property-type-toggle :is-reactive="false"/>
            <monthly-rate-input :is-reactive="false"/>
            <own-funds-input :is-reactive="false"/>
            <rooms-input :is-reactive="true"/>
            <living-area-input :is-reactive="false"/>
            <result-filters :is-reactive="false"/>
        </div>
        <div class="filter-menu-mobile--bottom-bar">
            <ap-button 
                label="Ergebnisse Anzeigen"
                @click="toggleMobileFilterMenu()"/>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import PropertyUsageToggle from '../SearchInputs/PropertyUsageToggle.vue'
import ResultFilters from './ResultFilters.vue'
import CloseCross from '@/assets/icons/close-cross.svg'
import PropertyTypeToggle from '../SearchInputs/PropertyTypeToggle.vue'
import MonthlyRateInput from '../SearchInputs/MonthlyRateInput.vue'
import OwnFundsInput from '../SearchInputs/OwnFundsInput.vue'
import RoomsInput from '../SearchInputs/RoomsInput.vue'
import LivingAreaInput from '../SearchInputs/LivingAreaInput.vue'

const store = useStore()

const isFilterMenuOpen = ref(false)

const toggleMobileFilterMenu = async () => {
    isFilterMenuOpen.value = !isFilterMenuOpen.value
    if (!isFilterMenuOpen.value) {
        await sortListings()
    }
}

const close = () => {
    isFilterMenuOpen.value = false
}

const sortListings = async () => {
    await store.dispatch("updateUrl")
    await store.dispatch("getMatchingInvestment")
}
</script>

<style lang="scss" scoped>
$filter-menu-mobile--topbar--height: 4rem;
$filter-menu-mobile--bottombar--height: 4.5rem;

.filter-menu-mobile {
    background-color: #F8FBFE;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    &--bottom-bar {
        align-items: center;
        background-color: #3B4868;
        bottom: 0;
        display: flex;
        height: $filter-menu-mobile--bottombar--height;
        justify-content: flex-end;
        left: 0;
        padding: 0 1.5rem;
        position: fixed;
        width: 100%;
        z-index: 1001;
    }

    &--content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: $filter-menu-mobile--bottombar--height + 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: $filter-menu-mobile--topbar--height + 1.5rem;

        &--separator {
            margin: 0;
        }
    }

    &--topbar {
        align-items: center;
        background-color: #FFFFFF;
        border-bottom: 1px solid #D9D9D9;
        display: flex;
        height: $filter-menu-mobile--topbar--height;
        justify-content: space-between;
        left: 0;
        padding: 0 1.5rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1001;
    }
}

.filter-menu-mobile--topbar {

    &--close {
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 0.25rem;
        transition: background-color 150ms;
    
        &:hover {
            background-color: #EEEEEE;
        }
    
        > img {
            height: 2rem;
        }
    }

    &--title {
        line-height: 1.875rem;
        font-size: 1.25rem;
        font-weight: 600;
    }
}

.ap-selector-switch > button[data-v-ec41cf2c] {
    transition: background-color 200ms;
}

.ap-selector-switch > button[data-v-ec41cf2c]:hover {
    background-color: #39b3b724 !important;
}

.filter-menu-mobile .selected-option[data-v-ec41cf2c]  {
    font-weight: 600;
    background-color: #39B3B7 !important;
}

.filter-menu-mobile .selected-option[data-v-ec41cf2c]:hover  {
    font-weight: 600;
    background-color: #39B3B7 !important;
}

.property-type-toggle {
    max-width: 100%;
}
</style>