<template>
    <div class="financing-tool-description">
        <div class="financing-tool-description--head">
            <h6 class="financing-tool-description--head--title">
                Mit <span class="financing-tool-description--head--title--highlight">nur 4 Angaben</span>  zum persönlichen Finanzierungsvorschlag!
            </h6>
            <img :src="HouseLoan" class="decoration">
        </div> 
        <div
            class="financing-tool-description--benefits-list--item">
                <img :src="CheckIconBlue" class="financing-tool-description--benefits-list--item--icon">
                <div class="financing-tool-description--benefits-list--item--text outlier">Keine Kontaktdaten notwendig</div>
        </div>
        <div class="financing-tool-description--benefits-list">
            <div
                v-for="(bullet, index) in bulletPoints"
                :key="index"
                class="financing-tool-description--benefits-list--item">
                    <img :src="CheckIcon" class="financing-tool-description--benefits-list--item--icon">
                    <div class="financing-tool-description--benefits-list--item--text">{{ bullet }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import CheckIcon from '@/assets/icons/check-icon.svg'
import CheckIconBlue from '@/assets/icons/check-icon-blue.svg'
import HouseLoan from '@/assets/icons/house-loan.svg'

const bulletPoints = [
    'Machbarkeit prüfen',
    'Persönliche Zinsindikation und Vorschläge erhalten',
    'Angebote von +500 Finanzierungsinstituten vergleichen',
]

</script>

<style lang="scss" scoped>
.financing-tool-description {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-self: flex-start;
}

.financing-tool-description--benefits-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    
    &--item {
        align-items: center;
        display: flex;
        gap: 0.5rem;

        &--icon {
            height: 1.125rem;
        }

        &--text {
            font-size: 0.875rem;
            line-height: 1.375rem;
        }
    }
}

.financing-tool-description--head {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &--title {
        
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.75rem;
        max-width: 60%;
        margin: initial;
        
        &--highlight {
            color: #3FE395;
            text-decoration: underline;
        }
    }
}

.outlier {
    color: #39B3B7;
}
</style>