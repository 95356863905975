<template>
    <div class="search-results">
        <search-bar />
        <div class="search-results--body">
            <filter-menu-side v-if="showFilterMenuSide"/>
            <listings-display-view />
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import SearchBar from './SearchInputs/SearchBar.vue'
import FilterMenuSide from './ListingsDisplay/FilterMenuSide.vue'
import ListingsDisplayView from './ListingsDisplayView.vue'

const store = useStore()
const route = useRoute()

onBeforeMount(async () => {
    store.state.matchingInvestInputs.locations = typeof route.query.locations === 'string'
    ? [route.query.locations]
    : route.query.locations
    store.state.matchingInvestInputs.propertyType = route.query.propertyType
    store.state.matchingInvestInputs.ownFunds = Number(route.query.ownFunds)
    store.state.matchingInvestInputs.maxMonthlyRate = Number(route.query.maxMonthlyRate)
    store.state.matchingInvestInputs.minPrice = Number(route.query.minPrice)
    store.state.matchingInvestInputs.page = Number(route.query.page)
    store.state.matchingInvestInputs.listingsPerPage = Number(route.query.listingsPerPage)
    store.state.matchingInvestInputs.sortBy = route.query.sortBy
    store.state.matchingInvestInputs.sortAscending = (route.query.sortAscending === 'true')
    store.state.matchingInvestInputs.isImmoRoulette = (route.query.isImmoRoulette === 'true')
    store.state.matchingInvestInputs.userRole = route.query.userRole
    store.state.matchingInvestInputs.isInvestor = (route.query.isInvestor === 'true')
    store.state.matchingInvestInputs.incomeTaxRate = Number(route.query.incomeTaxRate)
    store.state.matchingInvestInputs.minRooms = Number(route.query.minRooms)
    store.state.matchingInvestInputs.minLivingArea = Number(route.query.minLivingArea)
    store.state.matchingInvestInputs.minConstructionYear = Number(route.query.minConstructionYear)
    store.state.matchingInvestInputs.maxConstructionYear = Number(route.query.maxConstructionYear)
    await store.dispatch('getMatchingInvestment')
})

const showFilterMenuSide = computed(() => ['lg', 'xl'].includes(store.state.screenSize))



</script>

<style lang="scss" scoped>
.search-results {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
}

.search-results--body {
    display: flex;
    gap: 2rem;
    max-width: 75rem;
    justify-content: center;
    padding: 0 1.5rem;
    width: 75rem;
}

@media (max-width: 1200px) {
    .search-results--body {
        max-width: 100%;
    }
}
</style>