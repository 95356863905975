<template>
    <div class="listing-tile ap-tile">
        <h5 v-if="alreadySeen === true" class="listing-tile--title--seen">{{ displayTitle }}</h5>
        <h5 v-else class="listing-tile--title">{{ displayTitle }}</h5>

        <secondary-properties :listing="listing"/>
        <div class="listing-tile--body">
            <title-image-listings
                :rooms="listing.rooms"
                :constructionYear="listing.constructionYear"
                :livingArea="listing.livingArea"/>
            <div class="listing-tile--body--main">
                <primary-properties :listing="listing" />
                <investor-properties
                    v-if="isUserInvestor"
                    :listing="listing" />
                <owner-occupant-properties
                    v-else
                    :listing="listing" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import { useStore } from 'vuex'
import TitleImageListings from './TitleImageListings.vue'
import PrimaryProperties from '../RealEstateProperties/PrimaryProperties.vue'
import SecondaryProperties from '../RealEstateProperties/SecondaryProperties.vue'
import InvestorProperties from '../RealEstateProperties/InvestorProperties.vue'
import OwnerOccupantProperties from '../RealEstateProperties/OwnerOccupantProperties.vue'

const props = defineProps({
    listing: {
        type: Object,
        default: undefined,
    },
})

const listing = toRef(props, 'listing')
const alreadySeen = computed(() => listing.value.already_seen)
const displayTitle = computed(() => listing.value.title.length <= 60
    ? listing.value.title
    : `${listing.value.title.substring(0, 60)}...`)

const store = useStore()
const isUserInvestor = computed(() => store.state.matchingInvestInputs.isInvestor)


</script>

<style lang="scss" scoped>

h5 {
    // TODO: remove when default headings have been cleaned up
    line-height: 1.875rem;
    margin: 0;
}
.listing-tile {
    background-color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    box-shadow: -4px 0px 140px 0px rgba(143, 155, 186, 0.20);
    backdrop-filter: blur(9.5px);

    &--body {
        display: flex;
        gap: 2.25rem;

        &--main {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    &--title {
        font-weight: 600;
        &--seen {
            font-weight: 600;
            color: #3b48689a;
        }
    }
}

.listing-tile:hover {
    box-shadow: -4px 0px 80px 0px rgba(84, 96, 124, 0.241);
}

@media (max-width: 1200px) {

    .listing-tile--body {
        flex-direction: column;
    }

    .listing-tile--body--main {
        gap: 2rem
    }
}
</style>