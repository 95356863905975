import { createApp } from 'vue'
import store from './store'
import router from './router/index'
import App from './App.vue'
import loadGlobalComponents from './essentials/loaders/load-components'
import loadGlobalDirectives from './essentials/loaders/load-directives'

import OpenLayersMap from "vue3-openlayers"
import "vue3-openlayers/dist/vue3-openlayers.css"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"

const app = createApp(App)

loadGlobalComponents(app)
loadGlobalDirectives(app)

app
    .use(store)
    .use(router)
    .use(OpenLayersMap)
    .mount('#app')