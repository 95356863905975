<template>
    <div class="top-bar">
        <div class="top-bar--content">
            <a href="https://www.acrossproperty.com/">
                <img :src="apLogo" alt="Across Property Logo" class="ap-logo">
            </a>

            <!-- desktop nav start-->
            <nav v-show="!mobile" class="desktop-nav">
                <a href="https://www.acrossproperty.com/">Home</a>
                <router-link to="/">
                    Immobiliensuche
                </router-link>
                <a href="https://www.acrossproperty.com/makler">Für Makler</a>
                <a href="https://www.acrossproperty.com/ueber-uns/">Über Uns</a>
            </nav>
            <!-- desktop nav end -->

            <!-- mobile-nav start -->
            <div class="mobile-nav-icon" v-show="mobile" @click="toggleMobileNav" :class="{ 'icon-active' : mobileNav }">
                <img :src="BurgerBars" class="mobile-nav-icon--burger">
            </div>
            <transition name="mobile-nav-animation">
                <nav v-show="mobileNav" class="mobile-nav--dropdown-nav">
                    <div class="mobile-nav--dropdown-nav--close-outside" @click="toggleMobileNav"></div>
                    <a class="mobile-nav--link" href="https://www.acrossproperty.com/">Home</a>
                    <hr>
                    <a class="mobile-nav--link" href="https://www.acrossproperty.com/makler">Für Makler</a>
                    <hr>
                    <router-link class="mobile-nav--link" to="/">
                        Suche
                    </router-link>
                    <hr>
                    <a href="https://www.acrossproperty.com/ueber-uns/" class="mobile-nav--link">Über Uns</a>
                    <hr>
                </nav>
            </transition>
            <!-- mobile-nav end -->
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import apLogo from '@/assets/ap-logo-2023.png'
import BurgerBars from '@/assets/icons/burger-bars.svg'

// responsive start
const mobile = ref(false)
const mobileNav = ref(false)
const windowWidth = ref(window.innerWidth)

const toggleMobileNav = () => {
    mobileNav.value = !mobileNav.value
}

onBeforeMount(() => {
    window.addEventListener('resize', checkScreen)
    checkScreen()
})

const checkScreen = () => {
    windowWidth.value = window.innerWidth
    if (windowWidth.value <= 900) {
        mobile.value = true
        return
    }
    mobile.value = false
    mobileNav.value = false
    return
}
// responsive end

</script>

<style lang="scss" scoped>

$mobile-menu-width: 18.5rem;

.ap-logo {
    width: 220px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

/* TODO: remove !important after removing bootstrap-overrides */
nav a {
    color: #3B4868 !important;
    font-weight: 400 !important;
    padding: 0.5rem;
    position: relative;
    
    &:hover {
        color: #39B3B7 !important;
    }

    &:active {
        color: #5cd1d9 !important;
    }

    &::after {    
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 0.125rem;
        left: 50%;
        position: absolute;
        background: #39B3B7;
        transition: width 300ms ease 0ms, left 300ms ease 0ms;
        width: 0;
    }

    &:hover::after { 
        left: 0; 
        width: 100%; 
    }
}

.desktop-nav {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.icon-active {
    transform: rotate(180deg);
}

.mobile-nav-animation-enter-active, .mobile-nav-animation-leave-active {
    transition: 500ms ease-in-out all;
}

.mobile-nav-animation-enter-from, .mobile-nav-animation-leave-to {
    transform: translateX(-18.75rem);
}

.mobile-nav-animation-enter-to, .mobile-nav-animation-leave-from {
    transform: translateX(0);
}

.mobile-nav--dropdown-nav {
    background-color: #FFF;
    box-shadow: 0.25rem 0 1.25rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    max-width: $mobile-menu-width;
    padding: 2rem;
    position: fixed;
    top: 0;
    width: 100%;
}

.mobile-nav--dropdown-nav--close-outside {
    // use color for testing
    // background-color: red;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: $mobile-menu-width;
    width: 100vw;
}

.mobile-nav-icon {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: right;
    right: 2.5rem;
    width: 2rem;
    height: 100%;
    cursor: pointer;
    transition: transform ease 0.5s;

    &--burger {
        width: inherit;
    }
}

.top-bar {
    background-color: #FFF;
    position: relative;
    z-index: 1000;

    &--content {
        align-items: center;
        position: relative;
        display: flex;
        gap: 2rem;
        height: 4rem;
        justify-content: flex-start;
        padding-left: 2rem;
        padding-right: 2rem;
        margin: auto;
        max-width: 1200px;
        
        &--ap-logo {
            width: 13.5rem;
        }
    
    }
}
</style>
