<template>
    <ap-dropdown
        class="property-type-toggle"
        :value="selectedPropertyType"
        :options="propertyTypeOptions"
        @select="selectPropertyType($event)" />
</template>

<script setup>
import { defineProps, toRef, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false,
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const propertyTypeOptions = [{id: 'haus_kaufen', title: 'Haus'}, {id: 'wohnung_kaufen', title: 'Wohnung'}]
const selectedPropertyType = store.state.matchingInvestInputs.propertyType == 'wohnung_kaufen'
    ? ref(propertyTypeOptions[1])
    : ref(propertyTypeOptions[0])

const selectPropertyType = (option) => {
    selectedPropertyType.value = option
    store.state.matchingInvestInputs.propertyType = option.id
    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

</script>

<style lang="scss" scoped>
.property-type-toggle {
    background: linear-gradient(135deg, #3FE395 0%, #39B3B7 100%);
    border: none !important;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    margin: 0.625rem 0;
    //TODO: Add color: white; to text in property-type-toggle
    min-width: 7rem ;
    max-width: 7rem;
}
.property-type-toggle > :first-child { 
    color: #FFF;
    font-weight: 700;
    padding-left: 0.875rem;
}

@media (max-width: 575px) {
    .property-type-toggle {
        max-width: 100%;
    }
}
</style>