<template>
    <div class="location-boxes" >
        <template v-if="isDisabled">
            {{ locationsInResult }}
        </template> 
        <template v-else >
            <div v-for="(location, index) in locationsInTags" :key="index" class="location-boxes--box">
                <div class="location-boxes--box--content">{{ location }}</div>
                <span class="remove-location" @click="removeLocation(location)">&#x2715;</span></div>
        </template>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isDisabled: {
        type: Boolean,
        default: false,
    },
    isReactive: {
        type: Boolean,
        default: false,
    }
})
const isDisabled = toRef(props, 'isDisabled')
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const locations = computed(() => store.state.matchingInvestInputs.locations)
const locationsInTags = computed(() => locations.value.slice(-3))
const locationsInResult = computed(() => locations.value.join(', '))

const removeLocation = (locationToRemove) => {
    store.state.matchingInvestInputs.locations = store.state.matchingInvestInputs.locations.filter(location => location !== locationToRemove)
    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

</script>

<style lang="scss" scoped>
.location-boxes {
    display: flex;
    margin-left: 0.5rem;
    gap: 0.5rem;
}

.location-boxes--box {
    background-color: #3B4868;
	border: 0.0625rem solid #3B4868;
    border-radius: 0.25rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.375rem 0.75rem;
    max-width: 200px;


    &--content {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.location-boxes--box--content, .remove-location {
    color: #FFF;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.875rem;
}

.remove-location {
    cursor: pointer;
    background: #3B4868;
    border-radius: 50%;
    padding: 0.25rem 0.354625rem;
    transition: background-color 200ms;
}

.remove-location:hover {
background-color: #FFFFFF34;
border-radius: 50%;
padding: 0.25rem 0.354625rem;
}
</style>