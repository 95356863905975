<template>
    <ap-spinner v-if="loadState === 1" class="expose--loader" />
    <div v-else-if="loadState === 2" class="expose">
        <div class="expose--summary">
            <!-- head area -->
            <main-criteria />
            <title-image-expose />
        </div>
        <div class="expose--body">
            <div class="expose--body--row">
                <div class="expose--body--row--col-left">
                    <cashflow-tile-investor v-if="isInvestor"/>
                    <cashflow-tile-owner-occupant v-else/>
                </div>
                <div class="expose--body--row--col-right" v-if="showConsultantCTADesktop">
                    <consultant-c-t-a />
                </div>
            </div>

            <location-analysis />
            <div class="expose--body--row">
                <div class="expose--body--row--col-left">
                    <property-comparison />
                    <banner-tile-c-t-a />
                    <ap-tile class="expose--body--main-column--object-details new-tile-shadow">
                        <object-details />
                        <energy-details />
                    </ap-tile>
                </div>
                <div class="expose--body--row--col-right" v-if="showConsultantCTADesktop">
                    <consultant-c-t-a />
                </div>
            </div>
            <financing-c-t-a />
        </div>
    </div>
    <consultant-c-t-a-mobile v-if="!showConsultantCTADesktop"/>
</template>

<script setup>
import { onMounted, ref, computed, watchEffect } from 'vue';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import BannerTileCTA from '@/components/MatchingInvest/CallToAction/BannerTileCTA.vue'
import ConsultantCTA from '@/components/MatchingInvest/CallToAction/ConsultantCTA.vue'
import ConsultantCTAMobile from '@/components/MatchingInvest/CallToAction/ConsultantCTAMobile.vue'
import CashflowTileInvestor from './Expose/CashflowTileInvestor.vue'
import CashflowTileOwnerOccupant from './Expose/CashflowTileOwnerOccupant.vue'
import EnergyDetails from './Expose/EnergyDetails.vue'
import FinancingCTA from '@/components/MatchingInvest/CallToAction/FinancingCTA.vue'
import LocationAnalysis from './Expose/LocationAnalysis.vue'
import MainCriteria from './Expose/MainCriteria.vue'
import ObjectDetails from './Expose/ObjectDetails.vue'
import PropertyComparison from './Expose/PropertyComparison.vue'
import TitleImageExpose from './Expose/TitleImageExpose.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const loadState = ref(1)
const isInvestor = computed(() => store.state.matchingInvestExpose.isInvestor)

onMounted(async () => {
    store.state.matchingInvestExpose.sourceId = route.query.sourceId
    store.state.matchingInvestExpose.propertyType = route.query.propertyType
    store.state.matchingInvestExpose.ownFunds = Number(route.query.ownFunds)
    store.state.matchingInvestExpose.maxMonthlyRate = Number(route.query.maxMonthlyRate)
    store.state.matchingInvestExpose.isInvestor = (route.query.isInvestor === 'true')
    store.state.matchingInvestExpose.incomeTaxRate = Number(route.query.incomeTaxRate)
    loadState.value = await store.dispatch('getMatchingInvestmentExpose')
    await store.dispatch('saveAnonymousUserData', {
        action: 'pageView',
        path: route.path,
        query: route.query,
        expose: store.state.expose,
    })
})

const showConsultantCTADesktop = computed(() => ['xl'].includes(store.state.screenSize))

watchEffect(() => {
    if (loadState.value === 3) {
        router.push('not-found')
    }
})
</script>

<style scoped lang="scss">
.expose {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 1200px;
    margin: auto;
    padding: 1rem 1.5rem 0;
    width: 1200px;

    &--summary {
        display: flex;
        gap: 2rem;
    }
}

.expose--body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;
    z-index: 99;
}

.expose--body--row {
    display: flex;
    gap: 2rem;

    &--col-left {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-top: 2.25rem;
    } 
}

.expose--body--main-column--object-details {
    width: 100%;
}

.expose--loader {
height: 90vh;
}

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

@media (max-width: 1200px) {
    .expose {
        max-width: 100%;
        padding: 0;
        
        &--body {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 0;
        }

        &--summary {
            flex-direction: column-reverse;
            gap: 0;
        }
    }

    .expose--body--row--col-left {
        padding-top: 0; 
        width: 100%;
    }
}
</style>