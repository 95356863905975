<template>
    <ap-tile class="property-comparison new-tile-shadow" id="rent-comparison">
        <h4 class="property-comparison--title">Immobilienanalyse:
            <span>{{ selectedAnalysisChart.title }}</span>
        </h4>
        <ap-dropdown
            :value="selectedAnalysisChart"
            :options="analysisChartOptions"
            @select="selectAnalysisChart($event)" />
        <buy-rent-comparison v-if="isKaufenMietenVergleich"/>
        <price-comparison v-if="isKaufpreisVergleich"/>
        <rent-comparison v-if="isMieteVergleich"/>
    </ap-tile>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import PriceComparison from './PropertyComparison/PriceComparison.vue'
import RentComparison from './PropertyComparison/RentComparison.vue'
import BuyRentComparison from './PropertyComparison/BuyRentComparison.vue'

const store = useStore()

const isInvestor = computed(() => store.state.matchingInvestExpose.isInvestor)

onMounted(() => {
    store.dispatch('getMedianAreaPrice')
})

const selectedAnalysisChart = ref({id: 'KAUFEN_MIETEN', title: 'Mieten-Kaufen-Vergleich'})
const analysisChartOptions = [
    {id: 'KAUFEN_MIETEN', title: 'Mieten-Kaufen-Vergleich'},
    {id: 'KAUFPREIS_VERGLEICH', title: 'Kaufpreis pro m²'},
]
if (isInvestor.value) analysisChartOptions.push({id: 'MIETE_VERGLEICH', title: 'Miete pro m²'})

const selectAnalysisChart = (option) => {
    selectedAnalysisChart.value = option
}

const isKaufenMietenVergleich = computed(() => selectedAnalysisChart.value.id  === 'KAUFEN_MIETEN')
const isKaufpreisVergleich = computed(() => selectedAnalysisChart.value.id  === 'KAUFPREIS_VERGLEICH')
const isMieteVergleich = computed(() => selectedAnalysisChart.value.id  === 'MIETE_VERGLEICH')

</script>

<style lang="scss" scoped>
.property-comparison {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--title {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2.25rem;

        > span {
            color: #39B3B7;
        }
    }
}

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}
</style>