export default {
    links: {
        linkedin: 'https://www.linkedin.com/company/across-property/',
        acrossProperty: {
            aboutUs: 'https://www.acrossproperty.com/ueber-uns/',
            contactUs: 'https://www.acrossproperty.com/#makler-kontakt',
            impressum: 'https://www.acrossproperty.com/impressum/',
            landingPage: 'https://www.acrossproperty.com',
            privacyPolicy: 'https://www.acrossproperty.com/datenschutzerklaerung/',
            wiki: 'https://www.acrossproperty.com/wiki/'
        }
    },
    emails: {
        acrossProperty: {
            info: 'info@muc.acrossproperty.com'
        }
    }
}