<template>
    <hr>
    <div class="financing-proposals">
        <div class="financing-proposals--intro">
            <h4 class="financing-proposals--intro--title">Deine Finanzierungsoptionen</h4>
            Anhand Deiner angegeben Informationen haben wir die folgenden Finanzierungsoptionen für Dich gefunden.
        </div>
        <div class="financing-proposals--tiles">
            <proposal-tile
                id="proposals-1"
                :proposal="financingProposals[0]"
                :loadState="loadStates[0]"/>
            <proposal-tile
                id="proposals-2"
                :proposal="financingProposals[1]"
                :loadState="loadStates[1]"/>
            <proposal-tile
                id="proposals-3"
                :proposal="financingProposals[2]"
                :loadState="loadStates[2]"/>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import ProposalTile from './ProposalTile.vue'

const store = useStore()
const financingProposals = ref([])
const loadStates = ref([0, 0, 0])

const scrollToProposal = (id) => {
    const url = `#proposals-${id}`
    setTimeout(() => {
        window.location.href = url
    }, "500")
}

onMounted( async () => {
    await store.dispatch('saveAnonymousUserData', {
        action: 'financingProposalsQueried',
        query: {
            financingData: store.state.financingData,
            customerData: store.state.customerData,
        },
        expose: store.state.expose,
    })
    for (let i = 1; i <= 3; i++) {
        try {

            store.state.customerData.fixedInterest = i * 5
            loadStates.value[i-1] = 1
            await store.dispatch('getFinancingProposalsCustomerSpecific')
            financingProposals.value.push(store.state.financingProposals[0])
            loadStates.value[i-1] = 2
            scrollToProposal(i)
        } catch (error) {
            console.log(error)
            financingProposals.value.push({})
        }
    }

    await store.dispatch('saveAnonymousUserData', {
        action: 'financingRequest',
        query: {
            financingData: store.state.financingData,
            customerData: store.state.customerData,
        },
        results: financingProposals.value
    })
})

</script>

<style lang="scss" scoped>
.financing-proposals {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    &--intro {
        color: #616569;
        text-align: center;

        &--title {
            color: #39B3B7;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2.25rem; 
        }
    }

    &--tiles {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }
}
</style>
