import ApTile from '../components/ApTile.vue'
import ApBar from '../components/ApBar.vue'
import ApCheckbox from '../components/ApCheckbox.vue'
import ApFooter from '../components/ApFooter.vue'
import ApSlider from '../components/ApSlider.vue'
import ApInput from '../components/ap-input/ApInput.vue'
import ApSelectorSwitch from '../components/ApSelectorSwitch.vue'
import ApSwitch from '../components/ApSwitch.vue'
import ApDropdown from '../components/ApDropdown.vue'
import ApButton from '../components/ApButton.vue'
import ApDialog from '../components/ap-dialog/ApDialog.vue'
import ApStatusBar from '../components/ApStatusBar.vue'
import ApColorPicker from '../components/ApColorPicker.vue'
import ApCopyButton from '../components/ApCopyButton.vue'
import ApSpinner from '../components/ApSpinner.vue'
import ApRadialProgress from '../components/ApRadialProgress.vue'

export default (app) => {
	essentialComponents.forEach((essential) =>
		app.component(essential.name, essential.component)
	)
}

const essentialComponents = [
	{ name: 'ApTile', component: ApTile },
	{ name: 'ApBar', component: ApBar },
	{ name: 'ApCheckbox', component: ApCheckbox },
	{ name: 'ApFooter', component: ApFooter },
	{ name: 'ApSlider', component: ApSlider },
	{ name: 'ApInput', component: ApInput },
	{ name: 'ApSelectorSwitch', component: ApSelectorSwitch },
	{ name: 'ApSwitch', component: ApSwitch },
	{ name: 'ApDropdown', component: ApDropdown },
	{ name: 'ApButton', component: ApButton },
	{ name: 'ApDialog', component: ApDialog },
	{ name: 'ApStatusBar', component: ApStatusBar },
	{ name: 'ApColorPicker', component: ApColorPicker },
	{ name: 'ApCopyButton', component: ApCopyButton },
	{ name: 'ApSpinner', component: ApSpinner },
	{ name: 'ApRadialProgress', component: ApRadialProgress },
]
