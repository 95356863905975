<template>
    <ap-tile class="location-analysis new-tile-shadow">
        <h5 class="location-analysis--title">Standortübersicht</h5>
        <div class="location-analysis--description">
            Daten basieren auf folgender Standortinformation: {{ address }}
        </div>
        <div class="location-analysis--content">
            <location-map
                :selected-location-feature="selectedLocationFeature" id="map"/>
            <location-feature-list
                @select-location-feature="selectLocationFeature($event)" />
        </div>
    </ap-tile>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import LocationMap from './LocationAnalysis/LocationMap.vue'
import LocationFeatureList from './LocationAnalysis/LocationFeatureList.vue'

const store = useStore()
const address = computed(() => `${store.state.expose.address ?? ''} ${store.state.expose.postcode} ${store.state.expose.city}`)

const selectedLocationFeature = ref(-1)
const selectLocationFeature = ({ featureIndex }) => {
    selectedLocationFeature.value = featureIndex
}

</script>

<style lang="scss" scoped>
.location-analysis {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100% !important;
    
    &--content {
        display: flex;
        gap: 2rem;
        // flex-direction: column;
    }

    &--description {
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding-bottom: 1.5rem;
    }

    &--title {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2.25rem;
    }
}

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

@media (max-width: 900px) {

    .location-analysis--content {
        flex-direction: column;
    }
}
</style>