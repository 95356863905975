<template>
<div class="social-sharing-icons">

    <div class="social-sharing-icons--channel" @click="shareToWhatsApp">
        <img class="social-sharing-icons--channel--icon" :src="Whatsapp">
        <div>Whatsapp</div>
    </div>

    <div class="social-sharing-icons--channel" @click="shareViaEmail">
        <img class="social-sharing-icons--channel--icon" :src="Email">
        <div>Email</div>
    </div>

    <div class="social-sharing-icons--channel" @click="shareToTelegram">
        <img class="social-sharing-icons--channel--icon" :src="Telegram">
        <div>Telegram</div>
    </div>
</div>

</template>

<script setup>
import Whatsapp from '@/assets/images/WhatsApp.svg'
import Telegram from '@/assets/images/Telegram.svg'
import Email from '@/assets/images/Email.png'

const shareToWhatsApp = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Diese Immobilie habe ich bei AcrossProperty gefunden: ');
    const whatsappUrl = `https://api.whatsapp.com/send?text=${text} ${url}`;
    window.open(whatsappUrl, '_blank');
}

const shareViaEmail = () => {
    const subject = encodeURIComponent('Diese Immobilie habe ich bei AcrossProperty gefunden:');
    const body = encodeURIComponent(`Diese Immobilie habe ich bei AcrossProperty gefunden: \n${window.location.href}`);
    const emailUrl = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = emailUrl;
}

const shareToTelegram = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Diese Immobilie habe ich bei AcrossProperty gefunden:');
    const telegramUrl = `https://t.me/share/url?url=${url}&text=${text}`;
    window.open(telegramUrl, '_blank');
}

// const share = () => {
//     if (navigator.share) {
//         navigator.share({
//             text: 'Diese Immobilie könnte dir gefallen',
//             url: 'https://www.acrossproperty.com/',
//             title: 'AcrossProperty'
//         })
//     } else {
//         navigator.clipboard.writeText("code copied")
//     }
// }

</script>

<style lang="scss" scoped>
.social-sharing-icons {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    &--channel {
        align-items: center;
        border-radius: 0.5rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem 1.5rem;
        transition: background-color 200ms ease-in-out;
        width: 5rem;

        &:hover {
            background-color: #EEEEEE;
        }

        &--icon {
            height: 2.5rem;
        }
    }
}
</style>