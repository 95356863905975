<template>
    <div class="social-sharing-modal">
        <div class="background-close" @click="togglePopup()" />
        <div class="social-sharing-modal--popup-inner ap-tile fade-in-up">
            <h6 class="social-sharing-modal--popup-inner--title">Diese Immobilie teilen via:</h6>
            <hr>
            <social-sharing-icons />
            <div class="social-sharing-modal--popup-inner--close-button" @click="togglePopup()">
                <img :src="CloseCross" class="social-sharing-modal--popup-inner--close-button--icon">
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from 'vue'

import SocialSharingIcons from './SocialSharingIcons.vue'

import CloseCross from '@/assets/icons/close-cross.svg'

const emit = defineEmits(['toggle-popup'])
const togglePopup = () => emit('toggle-popup')

</script>

<style lang="scss" scoped>
.fade-in-up {
    -webkit-animation: fadeInUp 0.5s ease-in-out forwards;
    animation: fadeInUp 0.5s ease-out forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: .5;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.social-sharing-modal {
    position: absolute;
    z-index: 99;
    top: 2.5rem;
    right: 0rem;

    &--popup-inner {
        background: #FFF;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 32px -2px;
        padding: 1.5rem;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 32px -2px;
        }

        &--title {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.75rem;
        }
    }
}

.social-sharing-modal--popup-inner--close-button {
    align-items: center;

    border-radius: 50%;
    padding: 0.25rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    transition: background-color 150ms;

    &:hover {
        background-color: #EEEEEE;
    }

    &--icon {
        height: 1.5rem;
    }
}

.background-close {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
</style>