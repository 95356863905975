<template>
	<div class="ap-input" :data-size="size">
		<label v-if="label" :for="id">
			{{ label }}
		</label>
		<div :class="inputWrapperClass">
			<input
				ref="inputRef"
				v-model="inputValue"
				:class="{ invalid: isInvalid }"
				:disabled="disabled"
				v-bind="inputTypeAttrs"
				:list="list"
				lang="de"
				:id="id"
				@blur="onBlur()"
				@focus="onFocus()"
				@input="onInput()"
				@keyup.enter="onEnter()"
				@keyup.esc="blur()" />
				<div v-if="props.unit">{{ props.unit }}</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref, defineProps, defineEmits, defineExpose } from 'vue'
import inputTypes from './input-types'
import generateRandomId from '@/essentials/helpers/generate-random-id'
import { nextTick } from 'vue'

const props = defineProps({
	modelValue: [Number, String],
	placeholder: String,
	label: String,
	type: {
		type: String,
		default: 'text',
	},
	size: {
		type: String,
		default: 'medium'
	},
	list: String,
	disabled: Boolean,
	invalid: Boolean,
	disallowWhiteSpaces: {
		type: Boolean,
		default: false,
	},
	unit: {
		type: String,
		default: '',
	}
})

const inputRef = ref()
const id = generateRandomId()
const isInvalid = ref(false)
const isFocused = ref(false)

const inputValue = computed({
	get() {
		return props.modelValue
	},
	async set(value) {
		// Needed for v-model, see https://vuejs.org/guide/components/v-model.html#v-model-arguments
		emit('update:modelValue', value)
		await nextTick()
		checkValidity()
		emit('is-valid', !isInvalid.value)
	},
})

const inputTypeAttrs = computed(() => ({
	...inputTypes[props.type]?.attrs,
	placeholder: props.placeholder ?? inputTypes[props.type]?.attrs.placeholder,
}))

const blur = () => inputRef.value?.blur()
const focus = () => inputRef.value?.focus()

const checkValidity = () => {
	const isEmpty = props.disallowWhiteSpaces ? inputValue.value?.trim() === '' : false
	isInvalid.value = props.invalid || !inputRef.value?.validity.valid || isEmpty
}

const onBlur = () => {
    isFocused.value = false
	emit('blur', inputValue.value)
	blur()
}

const onFocus = () => {
    isFocused.value = true
	emit('focus', inputValue.value)
	focus()
}

const onInput = () => emit('input', inputValue.value)

const onEnter = () => {
	emit('enter', inputValue.value)
	blur()
}

const emit = defineEmits(['enter', 'blur', 'focus', 'input', 'update:modelValue', 'is-valid'])

onMounted(() => checkValidity())

defineExpose({
	blur,
	focus,
	checkValidity,
})

const inputWrapperClass = computed(() => isFocused.value
    ? 'input-wrapper input-wrapper--active'
    : 'input-wrapper')


</script>

<style lang="scss" scoped>
input {
	border: none;
	outline: none;
	font-size: 14px;
	max-height: 100vh;
	overflow: "auto";
	width: 100%;
}

.input-wrapper {
	align-items: center;
    background-color: #FFFFFF;
    border: 0.0625rem solid #C2C9D1;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.3125rem rgba(0, 0, 0, 0.1);
    display: flex;
    height: 2.5rem;
	justify-content: space-between;
    position: relative;
    padding: 0 0.5rem;
    transition: border 200ms, box-shadow 200ms;
    width: 100%;
}

.input-wrapper:hover, .input-wrapper--active {
    border: #39B3B7 0.0625rem solid;
    box-shadow: 0 0 0.3125rem rgba(57, 179, 183, 0.3333333333);
}

.ap-input {
	&[data-size='small'] {
		--border-radius: 0.375rem;
		--font-size: 0.75rem;
		--height: 2rem;
		--padding-rl: 0.5rem;
		--padding-tb: 0.625rem;
	}

	&[data-size='medium'] {
		--border-radius: 0.5rem;
		--font-size: 0.875rem;
		--height: 2.625rem;
		--padding-rl: 0.5rem;
		--padding-tb: 0.625rem;
	}

	&[data-size='large'] {
		--border-radius: 0.75rem;
		--font-size: 1rem;
		--height: 3.75rem;
		--padding-rl: 0.75rem;
		--padding-tb: 0.825rem;
	}

	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	position: relative;

	> label {
		font-size: var(--font-size);
		font-weight: 500;
		line-height: 1.25;
	}

	> input {
		background-color: white;
		border: 0.0625rem #C2C9D1 solid;
		border-radius: var(--border-radius);
		box-shadow: 0 0.1rem 0.3125rem rgba(black, 10%);
		color: #3B4868;
		font-size: var(--font-size);
		height: calc(var(--height) - 2 * 0.0625rem);
		margin: 0;
		padding: var(--padding-tb) var(--padding-rl);
		transition: border-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
		width: 100%;
	}

	> input:disabled {
		background-color: #FAFAFA;
		border: 0.0625rem #A5A5A5 solid;
		box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 25%);
	}

	> input:not(:disabled):focus,
	> input:not(:disabled):hover {
		border: #39B3B7 0.0625rem solid;
		box-shadow: 0 0 0.3125rem #39B3B755;
	}

	> input:focus-visible {
		outline: none;
	}

	> input::placeholder {
		color: rgba(black, 0.25);
	}

	> input:not(:focus, :placeholder-shown, :disabled).invalid {
		background-color: #ffebed;
		box-shadow: inset 0 0 0.125rem 0.125rem #fe5f55;
		color: #fe5f55;
	}

	// hide arrows from number input for Chrome, Safari, Edge, Opera
	> input[type='number']::-webkit-inner-spin-button,
	> input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	// hide arrows from number input for Firefox
	> input[type='number'] {
		-moz-appearance: textfield;
	}
}
</style>
