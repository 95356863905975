export default () => {
    const realEstateProperties = [
        {
            id: 'title',
            displayName: 'Titel',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'dwell',
            displayName: 'Tage online',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'return',
            displayName: 'Rendite',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'cashflow_before_tax',
            displayName: 'Cashflow brutto',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'cashflow_after_tax_initial',
            displayName: 'Cashflow netto, initial',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'cashflow_after_tax_10_year_average',
            displayName: 'Cashflow netto, (10-Jahres Mittel)',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'AfA_per_month',
            displayName: 'AfA pro Monat',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'AfA_in_percent',
            displayName: 'AfA in %',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'interest_depreciation_per_month_initial',
            displayName: 'Initiale Zinsabschreibung pro Monat',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'total_tax_depreciation_per_month_initial',
            displayName: 'Initiale Steuerabschreibung gesamt pro Monat',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'interest_depreciation_per_month_10_year_average',
            displayName: 'Zinsabschreibung pro Monat 10-Jahres Durchschnitt',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'total_tax_deprication_per_month_10_year_average',
            displayName: 'Steuerabschreibung gesamt pro Monat (10-Jahres Mittel)',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'price',
            displayName: 'Preis',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'price_m2',
            displayName: 'Preis / qm',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'rent',
            displayName: 'Miete (pot.)',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'rent_m2',
            displayName: 'Miete / qm (pot.)',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'monthly_rate',
            displayName: 'Monatsrate',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'loan_amount',
            displayName: 'Kreditbetrag',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'credit_term',
            displayName: 'Kreditlaufzeit',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'additional_cost',
            displayName: 'Kaufnebenkosten [€]',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'additional_cost_in_percent',
            displayName: 'Kaufnebenkosten [%]',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'total_cost',
            displayName: 'Gesamtkosten',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'loan_to_value_ratio_in_percent',
            displayName: 'Beleihungsauslauf',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'interest_in_percent',
            displayName: 'Zins',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'amortization_in_percent',
            displayName: 'Tilgung',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'postcode',
            displayName: 'PLZ',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'city',
            displayName: 'Stadt/Gemeinde',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'district',
            displayName: 'Stadtteil',
            isSortableForInvestors: false,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'livingArea',
            displayName: 'Wohnfläche',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'rooms',
            displayName: 'Zimmer',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'constructionYear',
            displayName: 'Baujahr',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: true,
        },
        {
            id: 'capital_in_ten_years_at_2_percent_CAGR_including_cashflow',
            displayName: 'Vermögen in 10 Jahren',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'earning_in_ten_years_at_2_percent_CAGR_including_cashflow',
            displayName: 'Gewinn in 10 Jahren',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
        {
            id: 'return_on_own_funds_yearly_including_cashflow',
            displayName: 'Eigenkapital-Rendite',
            isSortableForInvestors: true,
            isSortableForOwnerOccupants: false,
        },
    ]

    const sortByOptionsForInvestor = realEstateProperties.filter(property => property.isSortableForInvestors == true)
    const sortByOptionsForOwnerOccupants = realEstateProperties.filter(property => property.isSortableForOwnerOccupants == true)

    return {
        realEstateProperties,
        sortByOptionsForInvestor,
        sortByOptionsForOwnerOccupants
    }
}