<template>
    <div class="search-bar">
        <div class="search-bar--content">
            <property-type-toggle :is-reactive="true" v-if="showSearchInputsDesktop"/>
            <location-input :is-unlimited-options="true" :is-reactive="true"/>
            <monthly-rate-input :is-reactive="true" v-if="showSearchInputsDesktop"/>
            <own-funds-input :is-reactive="true" v-if="showSearchInputsDesktop"/>
            <rooms-input :is-reactive="true" v-if="showSearchInputsDesktop"/>
            <living-area-input :is-reactive="true" v-if="showSearchInputsDesktop"/>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import PropertyTypeToggle from './PropertyTypeToggle.vue'
import LocationInput from './LocationInput.vue'
import MonthlyRateInput from './MonthlyRateInput.vue'
import OwnFundsInput from './OwnFundsInput.vue'
import RoomsInput from './RoomsInput.vue'
import LivingAreaInput from './LivingAreaInput.vue'

const store = useStore()
const showSearchInputsDesktop = computed(() => ['lg', 'xl'].includes(store.state.screenSize))
</script>

<style lang="scss">
.search-bar label {
    color: #FFF;
    font-size: 0.75rem;
}

.search-bar {
    background-color: #3B4868;
    display: flex;
    justify-content: center;
    height: 6rem;
    padding: 1rem 1.5rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;

    &--content {
        align-items: center;
        display: flex;
        gap: 0.75rem;
        max-width: 1200px;
        min-width: 1200px;
        padding: 0 1.5rem;

    }
}

.living-area-input {
    min-width: 5.5rem;
}

.rooms-input {
    min-width: 5rem;
}

@media (max-width: 1200px) {
    .search-bar--content {
        min-width: 100%;
        padding: 0;
    }
}
</style>
