<template>
    <div class="comparison">
        <h4 class="comparison--title">
            Die Zinskosten sind um
            <span class="comparison--title--highlight">{{ displaydifferenceRentBuy }} {{ displayDifferencDescription }}</span>
            als die Kaltmiete
        </h4>
        <div class="comparison-body">
            <div class="bar-charts">
                <ap-bar
                    :primary-height="monthlyRateColumnHeightInPercent"
                    :secondary-height="monthlyInterestColumnHeightInPercent"
                    :value-label="displaymonthlyRate"
                    primary-color="#56DDE1"
                    secondary-color="#39B3B7"
                    headline="Kaufen"
                    label="Rate pro Monat"
                    labelColor="#39B3B7" />
                <ap-bar
                    :primary-height="predRentColumnHeightInPercent"
                    :secondary-height="monthlyInterestColumnHeightInPercent"
                    :secondary-is-line="true"
                    :secondary-label="displaydifferenceRentBuy"
                    :value-label="displayPredRent"
                    primary-color="#D9D9D9"
                    headline="Mieten"
                    label=" Kaltmiete pro Monat"
                    labelColor="grey" />
            </div>
            <div class="legend">
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square primary" />
                    <div><span class="legend--item--name">Sparbetrag:</span> {{ displaymonthlyAmortizationTenYearAverage }} der Monatsrate fließen als Tilgung direkt in deinen Vermögensaufbau.</div>
                </div>
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square secondary" />
                    <div><span class="legend--item--name">Zinskosten:</span> {{ displaymonthlyInterestTenYearAverage }} der Kaufkosten fallen beim Kauf monatlich als Kosten in Form von Zinsen an.</div>
                </div> 
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square tertiary" />
                    <div><span class="legend--item--name">Kostendifferenz:</span>Zwischen Zinskosten und vergleichbarer Kaltmiete einer ähnlichen Immobilie.</div>
                </div> 
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square quaternery" />
                    <div>
                        <span class="legend--item--name">Vergleichsmiete:*</span>
                        Potentielle Kaltmiete pro m² für dieses Objekt.
                        <span class="legend--item--caption">*Dieser Wert ist auf Basis von umliegenden Immobilien mit ähnlichen Objektdetails sowie Standort- und Preisfaktoren berechnet und dient als Indikator.</span></div>
                </div> 
                <hr class="separator">
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const monthlyRate = computed(() => store.state.expose.monthly_rate)
const monthlyInterestTenYearAverage = computed(() => store.state.expose.interest_depreciation_per_month_10_year_average)
const monthlyAmortizationTenYearAverage = computed(() => store.state.expose.monthly_rate - store.state.expose.interest_depreciation_per_month_10_year_average)
const predRent = computed(() => store.state.expose.rent)

const displaymonthlyRate = computed(() => `${monthlyRate.value?.toLocaleString('de')} €`)
const displaymonthlyInterestTenYearAverage = computed(() => `${monthlyInterestTenYearAverage.value?.toLocaleString('de')} €`)
const displaymonthlyAmortizationTenYearAverage = computed(() => `${monthlyAmortizationTenYearAverage.value?.toLocaleString('de')} €`)
const displayPredRent = computed(() => `${predRent.value?.toLocaleString('de')} €`)

const monthlyRateColumnHeightInPercent = computed(() => monthlyRate.value > predRent.value
    ? 100
    : monthlyRate.value / predRent.value * 100)

const monthlyInterestColumnHeightInPercent = computed(() => monthlyInterestTenYearAverage.value / Math.max(monthlyRate.value, predRent.value) * 100)

const predRentColumnHeightInPercent = computed(() => monthlyRate.value > predRent.value
    ?  predRent.value / monthlyRate.value * 100
    : 100)

const displaydifferenceRentBuy = computed(() => `${Math.abs(monthlyInterestTenYearAverage.value - predRent.value).toLocaleString('de')} €`)
const displayDifferencDescription = computed(() => monthlyInterestTenYearAverage.value < predRent.value
    ? 'günstiger'
    : 'teurer')


</script>

<style lang="scss" scoped>
@import '../../../../Styles/_comparisonCharts.scss';

.comparison--title--highlight {
    color: #F3B502;
}

.legend--item--square {
    &.primary {
        background-color: #56DDE1;
    }

    &.secondary {
        background-color: #39B3B7;
    }
    
    &.tertiary {
        background-color: #F3B502;
    }

    &.quaternery {
        background-color: #D9D9D9;
    }
}
</style>