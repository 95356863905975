<template>
    <div class="location-map-container">
        <ol-map class="location-map-container--map-body"
            :loadTilesWhileAnimating="true"
            :loadTilesWhileInteracting="true"
            :mouseWheelZoom="false">

            <!-- base of map -->
            <ol-view
                ref="view"
                :center="propertyCoords"
                :rotation="rotation"
                :zoom="zoom"
                :projection="projection" />
            
            <ol-tile-layer>
                <!-- <ol-source-osm /> -->
                <ol-source-bingmaps
                    apiKey="AjtUzWJBHlI3Ma_Ke6Qv2fGRXEs0ua5hUQi54ECwfXTiWsitll4AkETZDihjcfeI"
                    imagerySet="Road" />
            </ol-tile-layer>

            <!-- location feature Markers -->
            <ol-vector-layer>
                <ol-source-vector>
                    <ol-feature v-for="(feature, index) in locationFeatures" :key="index">

                        <ol-geom-point :coordinates="tranformCoords(feature.geometry.coordinates[0], feature.geometry.coordinates[1])" />

                        <ol-style>
                            <ol-style-icon
                                :src="makeLocationFeatureLogoLink(feature.category)"
                                :scale="locationFeatureScale"
                                :anchor="[0.5, 0.25]" />
                        </ol-style>

                        <!-- location feature hover element -->
                        <ol-overlay
                            :position="tranformCoords(feature.geometry.coordinates[0], feature.geometry.coordinates[1])"
                            positioning="top-center"
                            :offset="[0, 0]" >
                            <template v-slot="">
                                <div
                                    class="location-feature-label-hover-obejct"
                                    @mouseover="selectLocationFeature(index)"
                                    @mouseleave="unselectLocationFeature()" >
                                </div>
                            </template>
                        </ol-overlay>

                        <!-- location feature label -->
                        <ol-overlay
                            :position="tranformCoords(feature.geometry.coordinates[0], feature.geometry.coordinates[1])"
                            positioning="bottom-center"
                            :offset="[0, 0]" >

                            <template v-slot="">
                                <ap-tile
                                    v-if="selectedLocationFeatureIndex === index"
                                    class="location-feature-label" >
                                    <div>{{ feature.category }}: {{ feature.distance_m }} m</div>
                                    <div v-if="feature.label">{{ feature.label }}</div>
                                </ap-tile>
                            </template>
                        </ol-overlay>

                    </ol-feature>
                </ol-source-vector>
            </ol-vector-layer>

            <!-- property Marker -->
            <ol-vector-layer>
                <ol-source-vector>
                    <ol-feature>
                        <ol-geom-point :coordinates="propertyCoords" />
                        <ol-style>
                            <ol-style-icon
                                :src="propertyMarkerLogoLink"
                                :scale="propertyMarkerScale"
                                :anchor="[0.5, 1]" />
                        </ol-style>
                    </ol-feature>
                </ol-source-vector>
            </ol-vector-layer>

            <!-- selected location feature Marker -->
            <ol-vector-layer v-if="selectedLocationFeatureIndexFromList != -1">
                <ol-source-vector>
                    <ol-feature>
                        <ol-geom-point :coordinates="tranformCoords(selectedLocationFeatureFromList.geometry.coordinates[0], selectedLocationFeatureFromList.geometry.coordinates[1])" />
                        <ol-style>
                            <ol-style-icon
                                :src="makeLocationFeatureLogoLink(selectedLocationFeatureFromList.category)"
                                :scale="selectedLocationFeatureScale"
                                :anchor="[0.5, 0.25]" />
                        </ol-style>
                    </ol-feature>
                </ol-source-vector>
            </ol-vector-layer>

            <!-- control features -->
            <ol-fullscreen-control />
            <ol-zoom-control />
            <ol-scaleline-control />

        </ol-map>
    </div>
</template>

<script setup>
import { ref, computed, defineProps, toRef } from 'vue'
import { useStore } from 'vuex'
import * as proj from 'ol/proj'

const store = useStore()

const props = defineProps({
    selectedLocationFeature: {
        type: Number,
        default: -1
    }
})
const selectedLocationFeatureIndexFromList = toRef(props, 'selectedLocationFeature')
const locationFeatures = computed(() => store.state.locationFeatures)
const selectedLocationFeatureFromList = computed(() =>
    selectedLocationFeatureIndexFromList.value >= 0
    ? locationFeatures.value[selectedLocationFeatureIndexFromList.value]
    : {})
const propertyMarkerScale = 3
const locationFeatureScale = 1.2
const selectedLocationFeatureScale = 2

const projection = ref('EPSG:3857')
const zoom = ref(15)
const rotation = ref(0)

const propertyLng = computed(() => store.state.expose.geometry.coordinates[0])
const propertyLat = computed(() => store.state.expose.geometry.coordinates[1])
const propertyCoords = computed(() => proj.transform([propertyLng.value, propertyLat.value], 'EPSG:4326', 'EPSG:3857'))

const tranformCoords = (lng, lat) => {
    return proj.transform([lng, lat], 'EPSG:4326', 'EPSG:3857')
}

const propertyMarkerLogoLink = ref('https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/location-feature-logos/immobilie.svg')
const makeLocationFeatureLogoLink = (category) => {
    return `https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/location-feature-logos/${category.replace('ä', 'ae')}.svg`
}

const selectedLocationFeatureIndex = ref()
const selectLocationFeature = (index) => {
    selectedLocationFeatureIndex.value = index
}
const unselectLocationFeature = () => selectedLocationFeatureIndex.value = undefined

</script>

<style lang="scss">
.location-feature-label {
    text-align: center;
    margin: 1rem;
}
.location-feature-label-hover-obejct {
    height: 1rem;
    width: 1rem;
}
.location-map-container {
    height: auto;
    width: 100%;

    &--map-body {
        height: 100%;
    }
}

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

.location-map-container .ol-viewport {
    border-radius: 1rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);

}

@media (max-width: 900px) {
    
    .location-map-container {
        height: 25rem;
    }
}
</style>