<template>
    <div class="property-sort">
        <div class="property-sort--main">
            <ap-dropdown
                class="property-sort--direction"
                :value="selectedSortDirection"
                :options="sortDirections"
                @select="selectSortDirection($event)" />
            <ap-dropdown
                class="property-sort--order"
                :value="selectedSortBy"
                :options="sortByOptions"
                @select="selectSortBy($event)" />
        </div>
        <filter-menu-mobile v-if="showFilterMenuMobile"/>
        <div class="fixed-to-bottom">
            <filter-menu-mobile v-if="showFilterMenuMobile && isScrollY"/>
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRef, ref, computed } from 'vue'
import { useStore } from 'vuex'
import useRealEstateProperties from '../helpers/use-real-estate-properties'
import FilterMenuMobile from './FilterMenuMobile.vue'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const {
    sortByOptionsForInvestor,
    sortByOptionsForOwnerOccupants,
} = useRealEstateProperties()

const sortByOptions = computed(() => store.state.matchingInvestInputs.isInvestor
    ? sortByOptionsForInvestor.map(sortByOptionsForInvestor => ({ id: sortByOptionsForInvestor.id, title: sortByOptionsForInvestor.displayName }))
    : sortByOptionsForOwnerOccupants.map(sortByOptionsForOwnerOccupants => ({ id: sortByOptionsForOwnerOccupants.id, title: sortByOptionsForOwnerOccupants.displayName })))

const selectedSortBy = ref(sortByOptions.value.find(option => option.id === store.state.matchingInvestInputs.sortBy))

const selectSortBy = (option) => {
    selectedSortBy.value = option
    store.state.matchingInvestInputs.sortBy = option.id
    if (isReactive.value) {
        store.dispatch("updateUrl")
        store.dispatch("getMatchingInvestment")
    }
}

const sortDirections = ref([
    {id: 0, value: true, title: 'Aufsteigend'},
    {id: 1, value: false, title: 'Absteigend'}
])
const selectedSortDirection = ref(
    store.state.matchingInvestInputs.sortAscending
    ? sortDirections.value[0]
    : sortDirections.value[1])
const selectSortDirection = (option) => {
    selectedSortDirection.value = option
    store.state.matchingInvestInputs.sortAscending = option.value
    store.state.matchingInvestInputs.page = 1
    if (isReactive.value) {
        store.dispatch("updateUrl")
        store.dispatch("getMatchingInvestment")
    }
}

const showFilterMenuMobile = computed(() => ['xs', 'sm', 'md'].includes(store.state.screenSize))

const isScrollY = ref(false)

const checkScrollY = () => {
    if (window.scrollY > 250) isScrollY.value = true
    else isScrollY.value = false
}

window.addEventListener("scroll", checkScrollY)

</script>

<style lang="scss">
.property-sort {
    display: flex;
    gap: 0.5rem;
    align-self: flex-end;

    &--main {
        display: flex;
        gap: 0.5rem;
    }
}

.property-sort--direction {
    width: 8rem !important;
}

.property-sort--order {
    width: 13rem !important;
}

@media (max-width: 575px) {
    .property-sort {
        flex-direction: column-reverse;
        width: 100%;
    }

    .property-sort--direction {
        width: 11.5rem !important;
    }

    .property-sort--order {
        width: 100%;
    }
}

.fixed-to-bottom {
    bottom: 0.625rem;
    right: 0.625rem;
    position: fixed;
    z-index: 1000;
    
    > .toggle-mobile-filter {
        background-color: #3B4868;
        border: #3B4868;
    }
}
</style>