<template>
    <div class="living-area-input">
        <label class="living-area-input--label" for="living-area-input--field">Wohnfl. ab</label>
        <ap-dropdown
            id="living-area-input--field"
            :value="selectedLivingArea"
            :options="livingAreaOptions"
            @select="selectLivingArea($event)" />
    </div> 
</template>

<script setup>
import { defineProps, toRef, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false,
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const livingAreaOptions = [
    {id: 10, title: '10 m²'},
    {id: 20, title: '20 m²'},
    {id: 30, title: '30 m²'},
    {id: 40, title: '40 m²'},
    {id: 50, title: '50 m²'},
    {id: 60, title: '60 m²'},
    {id: 70, title: '70 m²'},
    {id: 80, title: '80 m²'},
    {id: 90, title: '90 m²'},
    {id: 100, title: '100 m²'},
    {id: 120, title: '120 m²'},
    {id: 130, title: '130 m²'},
]

const selectedLivingArea = ref(livingAreaOptions.find(livingArea => livingArea.id === store.state.matchingInvestInputs.minLivingArea) ?? livingAreaOptions[0])

const selectLivingArea = (option) => {
    selectedLivingArea.value = option
    store.state.matchingInvestInputs.minLivingArea = option.id
    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

</script>

<style lang="scss" scoped>
#living-area-input--field > :first-child {
    height: 2.5rem;
}
</style>