<template>
    <div class="price-filter">
        <label for="min-price">Kaufpreis ab</label>
        <ap-slider
            v-model="store.state.matchingInvestInputs.minPrice"
            :show-input-field="showInputField"
            id="min-price"
            min="0"
            :max="maxMinPrice"
            step="1000"
            @change="sortListings()" />
        <hr class="price-filter--separator">
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    showInputField: {
        type: Boolean,
        default: true,
    },
    isReactive: {
        type: Boolean,
        default: false
    }
})
const showInputField = toRef(props, 'showInputField')
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const maxMinPrice = computed(() => store.state.matchingInvestInputs.maxMonthlyRate * 12 / 0.06)

const sortListings = () => {
    if (isReactive.value) {
        store.dispatch("updateUrl")
        store.dispatch("getMatchingInvestment")
    }
}

</script>

<style lang="scss">
.price-filter {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.price-filter .ap-slider {
    gap: 0.25rem !important;
    height: 70px !important;
    max-width: 100% !important;
    width: 100% !important;
}

.price-filter .ap-slider--input-field {
    max-width: 100% !important;
    width: 100% !important;
}

.price-filter label {
    font-weight: 600;
}

.price-filter #min-price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.price-filter--separator {
    margin: 1rem 0 0 0;
}
</style>