<template>
    <div class="energy-filter">
        <div class="energy-filter--title">Energieträger</div>
        <div class="energy-filter--filters">
            <ap-checkbox
                label="Solar"
                v-model="store.state.matchingInvestInputs.energyFilters.isSolarEnergy"
                @change="updateFilter('isSolarEnergy', isSolarEnergy)" />
            <ap-checkbox
                label="Erdwärme"
                v-model="store.state.matchingInvestInputs.energyFilters.isGeothermalEnergy"
                @change="updateFilter('isGeothermalEnergy', isGeothermalEnergy)" />
            <ap-checkbox
                label="Pellets"
                v-model="store.state.matchingInvestInputs.energyFilters.isPelletsHeating"
                @change="updateFilter('isPelletsHeating', isPelletsHeating)" />
            <ap-checkbox
                label="Holz"
                v-model="store.state.matchingInvestInputs.energyFilters.isWoodHeating"
                @change="updateFilter('isWoodHeating', isWoodHeating)" />
            <ap-checkbox
                label="Fernwärme"
                v-model="store.state.matchingInvestInputs.energyFilters.isDistrictHeating"
                @change="updateFilter('isDistrictHeating', isDistrictHeating)" />
            <ap-checkbox
                label="Flüssiggas"
                v-model="store.state.matchingInvestInputs.energyFilters.isLiquidGasEnergy"
                @change="updateFilter('isLiquidGasEnergy', isLiquidGasEnergy)" />
            <ap-checkbox
                label="Öl"
                v-model="store.state.matchingInvestInputs.energyFilters.isOlEnergy"
                @change="updateFilter('isOlEnergy', isOlEnergy)" />
            <ap-checkbox
                label="Gas"
                v-model="store.state.matchingInvestInputs.energyFilters.isGasEnergy"
                @change="updateFilter('isGasEnergy', isGasEnergy)" />
            <ap-checkbox
                label="Elektro"
                v-model="store.state.matchingInvestInputs.energyFilters.isElectricEnergy"
                @change="updateFilter('isElectricEnergy', isElectricEnergy)" />
            <ap-checkbox
                label="Blockheizkraftwerk"
                v-model="store.state.matchingInvestInputs.energyFilters.isCombinedHeatAndPowerUnit"
                @change="updateFilter('isCombinedHeatAndPowerUnit', isCombinedHeatAndPowerUnit)" />
            <ap-checkbox
                label="Kohle"
                v-model="store.state.matchingInvestInputs.energyFilters.isCoalEnergy"
                @change="updateFilter('isCoalEnergy', isCoalEnergy)" />
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const isSolarEnergy = computed(() => store.state.matchingInvestInputs.energyFilters.isSolarEnergy)
const isGeothermalEnergy = computed(() => store.state.matchingInvestInputs.energyFilters.isGeothermalEnergy)
const isPelletsHeating = computed(() => store.state.matchingInvestInputs.energyFilters.isPelletsHeating)
const isWoodHeating = computed(() => store.state.matchingInvestInputs.energyFilters.isWoodHeating)
const isDistrictHeating = computed(() => store.state.matchingInvestInputs.energyFilters.isDistrictHeating)
const isLiquidGasEnergy = computed(() => store.state.matchingInvestInputs.energyFilters.isLiquidGasEnergy)
const isOlEnergy = computed(() => store.state.matchingInvestInputs.energyFilters.isOlEnergy)
const isGasEnergy = computed(() => store.state.matchingInvestInputs.energyFilters.isGasEnergy)
const isElectricEnergy = computed(() => store.state.matchingInvestInputs.energyFilters.isElectricEnergy)
const isCombinedHeatAndPowerUnit = computed(() => store.state.matchingInvestInputs.energyFilters.isCombinedHeatAndPowerUnit)
const isCoalEnergy = computed(() => store.state.matchingInvestInputs.energyFilters.isCoalEnergy)

const updateFilter = (filterName, value) => {
    store.state.matchingInvestInputs.energyFilters[filterName] = value
    if (isReactive.value) {
        store.dispatch("getMatchingInvestment")
    }
}

</script>

<style lang="scss" scoped>
.energy-filter {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.energy-filter--title {
    font-weight: 600;
}


.energy-filter--separator {
    margin: 0.75rem 0 0 0;
}

.energy-filter--filters {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    margin: 0 0 0 1rem;
}
</style>