<template>
    <div class="owner-occupant-properties">
        <div class="owner-occupant-properties--wrapper">
            <ap-radial-progress v-if="monthlyRate"
                class="owner-occupant-properties--monthly-rate"
                :value="monthlyRate"
                unit="€"
                :min="minMonthlyRate"
                :max="maxMonthlyRate"
                :lowerBreakpoint="0"
                :upperBreakpoint="0"
                :isDecreasingOrder="true"
                label="Mtl. Rate"
                :size="radialProgressSize" />
                <info-box propertyId="monthlyRateOwnerOccupant" />
        </div>

        <div class="owner-occupant-properties--wrapper">
            <ap-radial-progress v-if="interest"
                class="owner-occupant-properties--interest"
                :value="interest"
                unit="%"
                :min="minInterest"
                :max="maxInterest"
                :lowerBreakpoint="75"
                :upperBreakpoint="45"
                :isDecreasingOrder="true"
                label="Zins"
                :size="radialProgressSize" />
            <info-box propertyId="interest" />
        </div>

        <div class="owner-occupant-properties--wrapper">    
            <ap-radial-progress v-if="creditTerm"
                class="owner-occupant-properties--credit-term"
                :value="creditTerm"
                unit="Jahre"
                :min="minCreditTerm"
                :max="maxCreditTerm"
                :lowerBreakpoint="75"
                :upperBreakpoint="40"
                :isDecreasingOrder="true"
                label="Laufzeit"
                :size="radialProgressSize" />
            <info-box propertyId="creditTerm" />
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRef, computed, ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import InfoBox from '@/components/MatchingInvest/InfoBox.vue'

const props = defineProps({
    listing: {
        type: Object,
        default: undefined,
    },
})
const listing = toRef(props, 'listing')

const store = useStore()

const monthlyRate = computed(() => listing.value.monthly_rate)
const minMonthlyRate = computed(() => store.state.matchingInvestListingsMeta.minMonthlyRate)
const maxMonthlyRate = computed(() => store.state.matchingInvestListingsMeta.maxMonthlyRate)

const interest = computed(() => listing.value.interest_in_percent)
const minInterest = computed(() => store.state.matchingInvestListingsMeta.maxInterest)
const maxInterest = computed(() => store.state.matchingInvestListingsMeta.minInterest)

const creditTerm = computed(() => listing.value.credit_term)
const minCreditTerm = ref(store.state.matchingInvestListingsMeta.maxCreditTerm)
const maxCreditTerm = ref(store.state.matchingInvestListingsMeta.minCreditTerm)


// responsiveness start -> Changes size of radial charts from medium to small below screensize 900px.
const screenWidth = ref(window.innerWidth)
var radialProgressSize = "medium"

const checkScreen = () => {
    screenWidth.value = window.innerWidth
    if (screenWidth.value <= 500) {
        radialProgressSize = "small"
        return
    }
    radialProgressSize = "medium"
    return
}

onBeforeMount(() => {
    window.addEventListener('resize', checkScreen)
    checkScreen()
})
// responsiveness end
</script>

<style scoped lang="scss">

.owner-occupant-properties--wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.owner-occupant-properties {
    display: flex;
    gap: 3rem;
}

@media (max-width: 1200px) {
    .owner-occupant-properties {
        gap: 0;
        justify-content: space-around;
    }
}

@media (max-width: 500px) {
    .owner-occupant-properties {
        justify-content: space-between;
    }
}
</style>