<template>
    <div class="not-found-view">
        <div class="not-found-view--content">
            <div class="not-found-view--content--text">
                <h1>Hoppla!</h1>
                <div class="not-found-text-large">Diese Immobilie ist wie ein Traumhaus im Nebel verschwunden. Vielleicht ist es aber auch nur ein Fall von 'Makler-Magie' – <span class="animation">"diese Seite hat nie existiert!"</span></div>
                <div class="not-found-text-small">Keine Sorge, wir helfen Dir eine noch bessere Immobilie zu finden. Hier geht's zurück zur Suche.</div>
                <ap-button
                    label="Zur Immobiliensuche"
                    @click="navigateToLink()" 
                    size="large"/>
            </div>
            <div class="not-found-view--content--image">
                <img :src="NotFound" alt="404 Not Found">
            </div>
        </div>
    </div>
</template>

<script setup>
import NotFound from '@/assets/images/404-not-found.svg'
import { useRouter } from 'vue-router';

const router = useRouter()

const navigateToLink = () => router.push('/')
</script>

<style lang="scss" scoped>

h1 {
    color: #3fe395 !important;
    font-size: 6rem !important;
    font-weight: 600 !important;
}
.not-found-view {
    background-color: #3B4868;
    
    &--content {
        display: flex;
        gap: 2rem;
        width: 1200px;
        margin: auto;
        max-width: 1200px;
        min-height: calc(100vh - 4rem);
        padding: 3rem 2rem;
        

        &--text {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
        }

        &--image {
            align-items: center;
            display: flex;
            justify-content: center;

            > img {
            height: 30rem
        }
        }
    }
}

.not-found-text-large {
    color: #FFF;
    font-size: 1.5rem;
}
.not-found-text-small {
    color: #FFF;
    font-size: 1rem;
    font-style: italic;
}



$gradient-color-1: #FF23A7;
$gradient-color-2: #10DDC2;

// Keyframes
@keyframes animationgradienttitle {
  0% {
      background-position: 0 200px;
  }
  100% {
      background-position: 200px 0;
  }
}

.animation {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animationgradienttitle;
    background: -webkit-linear-gradient(left, $gradient-color-1,$gradient-color-2 , $gradient-color-1);
    background-size: 200px 200px;
    font-style: italic;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 1200px) {
    .not-found-view--content {
        flex-direction: column;
        width: initial;
    }
}

@media (max-width: 750px) {
    .not-found-view--content--image > img {
        height: initial;
        width: 100%;
    }
}

@media (max-width: 575px) {
    .not-found-view--content {
        flex-direction: column-reverse;
    }

    .not-found-view--content--text > h1 {
        font-size: 4rem !important;
    }
}
</style>