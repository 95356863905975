<template>
    <ap-tile id="financing-cta" class="financing-cta new-tile-shadow">
        <h3 class="financing-cta--title">Wie Kreditwürdig bist du mit dieser Immobilie?</h3>

        <div class="financing-cta--body">
            <input-fields
                @request-financing-proposals="openFinancingProposals($event)" />
            <div class="financing-cta--body--col-right ap-tile new-tile-shadow">
                <financing-tool-description />
            </div>
        </div>
        <div id="financing-proposals"></div>
        <financing-proposals v-if="isFinancingProposalsOpen"/>
    </ap-tile>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import FinancingProposals from './FinancingCTA/FinancingProposals.vue'
import FinancingToolDescription from './FinancingCTA/FinancingToolDescription.vue'
import InputFields from './FinancingCTA/InputFields.vue'

const store = useStore()

const isFinancingProposalsOpen = ref(false)

const openFinancingProposals = ({
        income,
        selectedEmploymentType,
        birthdate,
        ownFunds,
    }) => {

    store.state.financingData.financingPurpose = 'KAUF'
    store.state.financingData.price = store.state.expose.price
    store.state.financingData.modernizationCost = 0
    store.state.financingData.propertyType = store.state.matchingInvestExpose.propertyType == 'wohnung_kaufen'
    ? 'EIGENTUMSWOHNUNG'
    : 'EINFAMILIENHAUS'
    store.state.financingData.comercialUsage = false
    store.state.financingData.isRentedOut = store.state.matchingInvestExpose.isInvestor
    store.state.financingData.street = ''
    store.state.financingData.houseNumber = ''
    store.state.financingData.postcode = store.state.expose.postcode
    store.state.financingData.city = store.state.expose.city
    store.state.financingData.constructionYear = store.state.expose.constructionYear
    store.state.financingData.livingArea = store.state.expose.livingArea
    store.state.financingData.rent = store.state.matchingInvestExpose.isInvestor
    ? store.state.expose.rent
    : 0
    store.state.financingData.explorationMode = false

    store.state.customerData.monthlyRate = store.state.expose.monthly_rate
    store.state.customerData.income = income
    store.state.customerData.employmentType = selectedEmploymentType
    store.state.customerData.birthDate = birthdate
    store.state.customerData.ownFunds = ownFunds

    isFinancingProposalsOpen.value = false
    setTimeout(() => {
        isFinancingProposalsOpen.value = true
    }, "100")
}

</script>

<style lang="scss" scoped>
.financing-cta {
    background-color: #EBF7F8 !important;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    &--body {
        display: flex;
        gap: 1rem;
    }

    &--title {
        color: #39B3B7;
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
    }
}
.financing-cta--body--col-right {
    align-items: center;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 2rem;
    width: 50%;
}
.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 1200px) {
    .financing-cta {
        margin-bottom: 3rem;
    }
}

@media (max-width: 900px) {
    .financing-cta--body {
        flex-direction: column;
    }

    .financing-cta--body--col-right, .input-fields {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .financing-cta--body--col-right {
        display: none;
    }
}
</style>