<template>
    <div class="banner-tile-cta ap-tile new-tile-shadow">
        <div class="banner-tile-cta--image"></div>
        <div class="banner-tile-cta--overlay"></div>
        <div class="banner-tile-cta--content">
            <h3 class="banner-tile-cta--content--title">Auf der Suche nach einem bezahlbaren Preis für deine zukünftige Immobilie?</h3>
            <div class="banner-tile-cta--content--description">Nutze jetzt den kostenlosen Suchauftrag von Across Property. <span v-show="screenSizeMobile">Wir suchen für dich und schicken dir passende Angebote für dein Vorhaben.</span></div>
            <div class="banner-tile-cta--content--form">
                <!-- TODO: Form currently has to functionality. Make it open Dialog to acquire email and tel and create search support.-->
                <ap-input
                    class="banner-tile-cta--content--form--input"
                    placeholder="Deine E-Mail Adresse"
                    type="email"
                    v-model="store.state.anonymousUserEmail"
                    @enter="openContactDialog()" />

                <ap-button 
                    class="banner-tile-cta--content--form--button dark"
                    label="Suchauftrag Starten"
                    @click="openContactDialog()" />
            </div>
            <span class="warning" v-if="showInvalidEmailAlert">Bitte geben Sie eine gültige E-Mail an!</span>
        </div>
    </div>
</template>

<script setup>
import  { computed, ref, onBeforeMount} from 'vue'
import  { useStore } from 'vuex'
import UserDataSubmissionDialog from './UserDataSubmissionDialog.vue'
import openDialog from '@/essentials/components/ap-dialog/open-dialog'

const store = useStore()

const isEmailValid = computed(() => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return emailRegex.test(store.state.anonymousUserEmail)
})
const isSubmissionAttempted = ref(false)
const showInvalidEmailAlert = computed(() => !isEmailValid.value && isSubmissionAttempted.value)

const openContactDialog = async () => {
    isSubmissionAttempted.value = true
    const sendRequestFunction = store._actions?.saveIdentifiedUserData[0]
    
    if (isEmailValid.value) {
        openDialog(UserDataSubmissionDialog, {
            title: 'Suchauftrag abgeben',
            subtitle: 'Sie bekommen wöchentlich die besten Angebote zugeschickt, die in Ihr Budget passen.',
            email: store.state.anonymousUserEmail,
            callToAction: 'searchRequestOrdered',  // auf keinen Fall verändern - Niko
            sendRequestFunction: sendRequestFunction,
        })
    }
}

// resonsiveness start. Part of the description dissappears on mobile devices.

const screenSizeMobile = ref(false)
const screenWidth = window.innerWidth

const checkScreenSize = () => {
    if (screenWidth <= 575) {
        screenSizeMobile.value = false
        return
    }
    screenSizeMobile.value = true
}

onBeforeMount(() => {
    window.addEventListener('resize', checkScreenSize)
    checkScreenSize()
})

// responsiveness end

</script>

<style lang="scss" scoped>
.banner-tile-cta {
    min-height: 16rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    
}

.banner-tile-cta--content {
    position: relative;
    padding: 1rem;

    &--description {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    &--form {
        display: flex;
        gap: 0.75rem;
        margin-top: 1.25rem;

        &--button {
            min-width: 13.75rem;
        }

        &--input {
            width: 27rem;
        }
    }


    &--title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 48px;
        max-width: 35.5rem;
    }
}


.banner-tile-cta--image {
    // TODO: Change Image URL and upload to AWS
    background-image: url(https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/stock/real-estate-app-buildings.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.banner-tile-cta--overlay {
    background: linear-gradient(47deg, #FFF3E4 21.35%, #ffffffa1 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.new-tile-shadow {
    border: none !important;
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 140px 0px rgba(143, 155, 186, 0.20) !important;
}

.new-tile-shadow:hover {
    backdrop-filter: blur(9.5px) !important;
    box-shadow: -10px 0px 80px 0px rgba(96, 109, 142, 0.23) !important;
}

@media (max-width: 1200px) { 
    .banner-tile-cta--content--title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .banner-tile-cta--content--form--input {
            width: 100%;
            max-width: 100%;
        }
}

@media (max-width: 900px) {
    .banner-tile-cta--content--form {
        flex-direction: column;

        &--button {
            width: 100%;
        }
    }
}
</style>