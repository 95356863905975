<template>
    <div class="ap-footer" :class="[ selectedVariant ]">
        <hr class="ap-footer--separator">
        <div class="ap-footer--body">
            <div class="ap-footer--contact">
                <div class="ap-footer--contact--email">
                    <h6>E-Mail:</h6>
                    <a :href="acrossPropertyMailTo" target="_blank">
                        {{ acrossPropertyEmail }}
                    </a>
                </div>
                <div>
                    <h6>Besuch uns auf LinkedIn:</h6>
                    <img class="ap-footer--contact--linkedin--icon-link"
                        :src="linkedInIcon"
                        @click="openLinkedIn()" />
                </div>
                <h6>Copyright &#169; 2023 Across Property</h6>
            </div>
            <div class="ap-footer--landing-page-links">
                <h4>Across Property</h4>
                <div class="ap-footer--landing-page-links--content">
                    <a :href="acrossPropertyAboutUs" target="_blank">Über Uns</a>
                    <a :href="acrossPropertyWiki" target="_blank">Immowiki</a>
                    <a :href="acrossPropertyImpressum" target="_blank">Impressum</a>
                    <a :href="acrossPropertyPrivacyPolicy" target="_blank">Datenschutzerklärung</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import externalAssets from '@/assets/external-assets'
import linkedInIcon from '../../assets/linkedin-icon.png'

const props = defineProps({
    variant: String,
})

const selectedVariant = computed(() => props.variant ?? 'light')

const acrossPropertyEmail = computed(() => externalAssets.emails.acrossProperty.info)
const linkedinLink = computed(() => externalAssets.links.linkedin)
const acrossPropertyAboutUs = computed(() => externalAssets.links.acrossProperty.aboutUs)
const acrossPropertyWiki = computed(() => externalAssets.links.acrossProperty.wiki)
const acrossPropertyImpressum = computed(() => externalAssets.links.acrossProperty.impressum)
const acrossPropertyPrivacyPolicy = computed(() => externalAssets.links.acrossProperty.privacyPolicy)
const acrossPropertyMailTo = computed(() => `mailto:${acrossPropertyEmail.value}`)

const openLinkedIn = () => {
    window.open(linkedinLink , '_blank').focus()
}
</script>

<style lang="scss" scoped>
a {
    color: #3B4868 !important;
}
.ap-footer {
    width: 100%;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.1);

    &--body {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 75rem;
        padding: 2rem 1.5rem;
    }

    &--separator {
        margin: 0;
    }

    &.light {
        background-color: white;
        color: black;
    }
}

.ap-footer--contact {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;

    &--email {
        display: flex;
        flex-direction: column;
    }

    &--linkedin--icon-link {
        cursor: pointer;
        height: 2rem;
        margin: 1rem 0;
        width: 2.375rem;
    }
}

.ap-footer--landing-page-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 40%;

    &--content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}
.consultant-cta-mobile + .ap-footer {
    margin-bottom: 4.5rem;
}

@media (max-width: 640px) {
    .ap-footer {
        text-align: center;
        gap: 3rem;
        flex-direction: column-reverse;
    }
    .ap-footer--contact, .ap-footer--landing-page-links{
        margin: 0 auto;
        width: 100%
    }
}

</style>