<template>
    <div class="comparison">
        <h4 class="comparison--title">
            Diese Immobilie ist
            <span class="comparison--title--highlight">{{ displayDifferencePriceMarket }} {{displayDifferencDescription}}</span>
            als umliegende Immobilien
        </h4>
        <div class="comparison-body">
            <div class="bar-charts">
                <ap-bar
                    :primary-height="propertyColumnHeightInPercent"
                    :value-label="displayPropertyPricePerSquareMeter"
                    primary-color="#39B3B7"
                    headline="Kaufpreis"
                    label="Preis pro m²"
                    labelColor="#39B3B7"/>
                <ap-bar
                    :primary-height="medianColumnHeightInPercent"
                    :value-label="displayMedianPricePerSquareMeter"
                    primary-color="#D9D9D9"
                    headline="Vergleich"
                    label="Preis pro m²"
                    labelColor="#616569"/>
            </div>
            <div class="legend">
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square primary" />
                    <div><span class="legend--item--name">Dieses Objekt:</span> Der aktuelle Kaufpreis pro m² für dieses Objekt.</div>
                </div>
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square secondary" />
                    <div><span class="legend--item--name">Umliegende Immobilien:</span>
                        Preis pro m² für umliegende Immobilien.
                        <span class="legend--item--caption">*Dieser Wert entspricht dem Median umliegender Immobilien des gleichen Immobilientyps und Region. Er dient als Indikator.</span></div>
                </div> 
                <hr class="separator">
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const propertyPricePerSquareMeter = computed(() => store.state.expose.price_m2)
const medianPricePerSquareMeter = computed(() => store.state.exposeStats.medianPricePerSquareMeter)

const displayPropertyPricePerSquareMeter = computed(() => `${propertyPricePerSquareMeter.value?.toLocaleString('de')} €`)
const displayMedianPricePerSquareMeter = computed(() => `${medianPricePerSquareMeter.value?.toLocaleString('de')} €`)

const propertyColumnHeightInPercent = computed(() => propertyPricePerSquareMeter.value > medianPricePerSquareMeter.value
    ? 100
    : propertyPricePerSquareMeter.value / medianPricePerSquareMeter.value * 100)

const medianColumnHeightInPercent = computed(() => propertyPricePerSquareMeter.value > medianPricePerSquareMeter.value
    ?  medianPricePerSquareMeter.value / propertyPricePerSquareMeter.value * 100
    : 100)

const displayDifferencePriceMarket = computed(() => `${Math.abs(propertyPricePerSquareMeter.value - medianPricePerSquareMeter.value).toLocaleString('de')} €`)
const displayDifferencDescription = computed(() => propertyPricePerSquareMeter.value < medianPricePerSquareMeter.value
    ? 'günstiger'
    : 'teurer')

</script>

<style lang="scss" scoped>
@import '../../../../Styles/_comparisonCharts.scss';

.legend--item--square {
    &.primary {
        background-color: #39B3B7;
    }

    &.secondary {
        background-color: #D9D9D9;
    }
}

.comparison--title--highlight {
    color: #F3B502;
}
</style>