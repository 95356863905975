export default [
    {
        id: 'rent',
        title: 'Potentielle Miete',
        content: 'Die potentielle Miete für diese Immobilie wird auf Basis von hunderten Vergleichsmieten in dieser Umgebung berechnet.',
        link: ''
    },
    {
        id: 'interest',
        title: 'Zinseinschätzung',
        content: 'Die Zinseinschätzung wird auf Basis tagesaktueller Zinsangebote berechnet. Für die individuelle Bewertung werden Informationen zur einzelnen Immobilie, wie Kaufpreis und Nebenkosten sowie persönliche Präferenzen wie Eigenkapitaleinsatz hinzugezogen.',
        link: '',
    },
    {
        id: 'monthlyRateInvestor',
        title: 'Monatsrate',
        content: 'Auf Basis des geschätzten Zinses und des nötigen Darlehens wird die geringst mögliche Monatsrate für diese Immobilie berechnet. Falls eine größere Tilgung gewünscht ist, kann dies in einem persönlichen Beratungsgespräch berechnet werden.',
        link: '',
    },
    {
        id: 'monthlyRateOwnerOccupant',
        title: 'Monatsrate',
        content: 'Auf Basis des geschätzten Zinses und des nötigen Darlehens werden dir ausschließlich Immobilien gezeigt, die mit deiner gewünschten Monatsrate finanzierbar sind.',
        link: '',
    },
    {
        id: 'monthlySurplus',
        title: 'Monatlicher Überschuss',
        content: 'Der hier errechnete monatliche Überschuss entspricht der geschätzten Kaltmiete abgezogen der Monatrate.',
        link: '',
    },
    {
        id: 'loanAmount',
        title: 'Darlehensbetrag',
        content: 'Der Darlehensbetrag berechnet sich aus den Gesamtkosten für den Erwerb abzüglich dem Eigenkapital. (Kaufpreis + Grunderwerbsteuer + Notarkosten + Maklergebühr - Eigenkapital)',
        link: '',
    },
    {
        id: 'creditTerm',
        title: 'Laufzeit des Darlehens',
        content: 'So lange dauert es bis du das Darlehen für diese Immobilie abbezahlt hast. Dieser Wert wird mit dem angegeben Zins und deiner gewünschten Rate beispielhaft für die gesamte Kreditlaufzeit gerechnet. Bei Fragen zur Laufzeit sprechen Sie mit einem unserer Berater.',
        link: '',
    },
]
