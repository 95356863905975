<template>
    <div class="own-funds-input">
        <label for="own-funds-input--field">Eigenkapital</label>
        <ap-input
            id="own-funds-input--field"
            v-model="store.state.matchingInvestInputs.ownFunds"
            type="number"
            size="small"
            unit="€"
            @change="checkBounds()" />
    </div>
</template>

<script setup>
import { defineProps, toRef, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false,
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const minValue = ref(0)
const maxValue = ref(1000000)

const checkBounds = () => {
    if (store.state.matchingInvestInputs.ownFunds < minValue.value) store.state.matchingInvestInputs.ownFunds = minValue.value
    if (store.state.matchingInvestInputs.ownFunds > maxValue.value) store.state.matchingInvestInputs.ownFunds = maxValue.value
    if (isReactive.value) {
        store.dispatch('updateUrl')
        store.dispatch('getMatchingInvestment')
    }
}

</script>

<style lang="scss" scoped>
#own-funds-input--field > :first-child {
    height: 2.5rem;
}
</style>