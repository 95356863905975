<template>
    <ap-dialog class="contact-dialog" show-close-button @close="emit('close', isEmailVerified)">
        <template #default>
            <contact-dialog-success
                v-if="isEmailVerified"
                :statuses="statuses"
                @close="emit('close', $event)" />
            <contact-dialog-verify-email
                v-else-if="hasSentRequest"
                :statuses="statuses" />
            <contact-dialog-form
                v-else
                :show-error-message="showErrorMessage"
                :title="props.title"
                :subtitle="props.subtitle"
                :email="props.email"
                :firstName="props.firstName"
                :lastName="props.lastName"
                :phone="props.phone"
                @send-request="sendRequest($event)" />
        </template>
    </ap-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import ContactDialogForm from './UserDataSubmissionDialog/ContactDialogForm.vue'
import ContactDialogVerifyEmail from './UserDataSubmissionDialog/ContactDialogVerifyEmail.vue'
import ContactDialogSuccess from './UserDataSubmissionDialog/ContactDialogSuccess.vue'

const props = defineProps({
    title: {
        type: String,
    },
    subtitle: {
        type: String,
    },
    email: String,
    callToAction: String,

    financingRequest: Object,
    hasVerifiedEmail: {
        type: Boolean,
        default: false,
    },
    sendRequestFunction: Function,
    verifyEmailFunction: Function,
})

const hasSentRequest = ref(false)
const showErrorMessage = ref(false)
const statuses = ref([
    {
        id: 1,
        label: 'Formular ausgefüllt',
        activeLabel: 'Formular ausfüllen',
    },
    {
        id: 2,
        label: 'E-Mail bestätigt',
        activeLabel: 'E-Mail bestätigen',
    },
    {
        id: 3,
        label: "Los geht's",
        activeLabel: "Los geht's",
    },
])

const isEmailVerified = ref(props.hasVerifiedEmail)

const sendRequest = async ({
        action,
        contact,
        acceptedPrivacyPolicy,
        verifiedEmail,
        identifier
    }) => {
    action['action'] = props.callToAction
    if (props.sendRequestFunction) {
        try {
            const response = await props.sendRequestFunction({
                action,
                contact,
                acceptedPrivacyPolicy,
                verifiedEmail,
                identifier,
            })
            hasSentRequest.value = true
            isEmailVerified.value = response.isEmailVerified
        } catch (error) {
            showErrorMessage.value = true
        }
    }
}

const emit = defineEmits(['close'])
</script>

<!-- TODO: make style scoped - only now to avoid repeating styles across subcomponents -->
<style lang="scss">
.contact-dialog {
    h3 {
        font-weight: 600;
    }
    
    // TODO: remove once headers are standarized
    h5 {
        color: #3B4868;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
    }

    h6 {
        color: #39B3B7;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
    }

    // TODO: remove once paragraphs are standarized
    p {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25;
        margin: 0;
        text-align: center;
    }
}
</style>