<template>
    <div class="info-box"
        @click.stop="toggleVisibilityOnClick()"
        @mouseover="openOnHover()"
        @mouseleave="closeOnHover()"
        v-on-click-outside="close">
        <img :src="InfoQuestionCircle" alt="Info" >
        <transition name="fade">
            <ap-tile v-if="showInfoBox" class="info-box--content">
                <h3 v-if="!hideTitle">
                    {{ content.title }}
                </h3>
                <div v-if="!hideContent">
                    {{ content.content }}
                </div>
                <a v-if="showLink">
                    {{ content.link }}
                </a>
            </ap-tile>
        </transition>
    </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import InfoBoxContent from './info-box-content.js'
import InfoQuestionCircle from '@/assets/icons/info-question-circle.svg'
import { booleanProp, stringProp } from 'vue-ts-types'
import { computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    propertyId: stringProp().required,
    hideTitle: booleanProp().withDefault(false),
    hideContent: booleanProp().withDefault(false),
    hideLink: booleanProp().withDefault(false),
    ignoreOnHover: booleanProp().withDefault(false),
})

const store = useStore()
const content = InfoBoxContent.find((e) => e.id === props.propertyId)
const showInfoBox = ref(false)
const showLink = computed(() => !props.hideLink && !!content.link)

const toggleVisibilityOnClick = () => {
    if (store.state.screenSize === 'xs') {
        showInfoBox.value = !showInfoBox.value
    }
}

const openOnHover = () => {
    if (props.ignoreOnHover) {
        return
    }
    showInfoBox.value = true
}

const close = () => {
    showInfoBox.value = false
}

const closeOnHover = () => {
    if (props.ignoreOnHover) {
        return
    }
    close()
}
</script>

<style lang="scss" scoped>
.info-box {
    align-items: center;
    bottom: 0;
    cursor: help;
    display: flex;
    height: 1.125rem;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 1.125rem;
}

.info-box--content {
    border: none ;
    bottom: 1.5rem;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 39%);
    font-size: 0.875rem;
    left: 50%;
    line-height: 1.25rem;
    min-width: 10rem;
    max-width: 20rem;
    position: absolute;
    text-align: left;
    transform: translate(-50%, 0);
    width: 20rem !important;
    z-index: 10000;

    > h3 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
    }
}

.fade-enter-active {
    transition-delay: 300ms;
}

@media (max-width: 575px) {
    .info-box--content {
        bottom: unset;
        left: 50%;
        min-width: 0;
        position: fixed;
        transform: translate(-50%, calc(-50% - 0.5rem));
        width: calc(100vw - 1.5rem) !important;
    }


    .fade-enter-active {
        transition-delay: 0;
    }
}
</style>