<template>
    <div class="start-search-view">
        <div class="start-search-view--content">
            <div class="start-search-view--content--title">
                <h1 class="fade-in">Deine Immobilie finden war nie einfacher.<span class="start-search-view--content--title--highlight">Mit der richtigen Finanzierung.</span></h1>
                <!-- <h1>Die erste Budgetzentrierte Immobiliensuche:<span class="start-search-view--content--title--highlight">Entdecke Immobilien, die zu Dir passen und bezahlbar sind</span></h1> -->
            </div>
            <search-box />
        </div>
        <div class="start-search-view--trapezoid" />
    </div>
</template>


<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import SearchBox from './SearchInputs/SearchBox.vue'

const route = useRoute()
const store = useStore()

onMounted(async () => {
    await store.dispatch('saveAnonymousUserData', {
            action: 'pageView',
            path: route.path,
    })

    const loadElement = document.querySelector('.fade-in')
    loadElement.classList.add('fade-in--loaded')
})

</script>

<style lang="scss" scoped>
.fade-in {
    opacity: 0;
    transform: translateY(1.5rem);
    transition: opacity 300ms ease-out, transform 300ms ease-out;

    
    &--loaded {
        opacity: 1;
        transform: translateY(0);
    }
}


.start-search-view {
    background-image: url("https://across-property-images.s3.eu-central-1.amazonaws.com/search-app-test/stock/woman-carrying-luggage.jpeg");
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 4rem);
    padding-top: 5.75rem;
    position: relative;

    --scrollbar-width: 1rem;
}

.start-search-view--background-image {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}
.start-search-view--content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    
    &--title h1 {
        color: #fff !important;
        font-size: 48px;
        font-weight: 600;
        position: relative;
        text-align: center;
        z-index: 1 !important;

        
    }
    
    &--title--highlight {
        background: linear-gradient(215deg, #3FE395 0%, #39B3B7 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: block;
    }

    &--title--subtitle {
        color: white !important;
        position: relative;
        z-index: 1 !important;
    }
}
.start-search-view--trapezoid {
    color: #3B4868;
    background-color: #3B4868;
    clip-path: polygon(0 0, 100% 0, 100% 30vh, 0 60vh);
    height: 60vh;
    width: 100%;
    position: absolute;
    top: 0;
}

@media (max-width: 575px) {
    .start-search-view {
        height: 100%;
    }

    .start-search-view--content {
        background-color: #3B4868 !important;

        &--title--highlight {
            font-size: 1.5rem !important;
        }
        
        &--title h1 {
            font-size: 1.25rem !important;
            line-height: 2rem !important;
        }
    }

    .start-search-view--content {
        gap: 1.5rem;
    }
}
</style>