<template>
	<div id="bootstrap-overrides" :class="navbarStyling">
		<TopNavbarPublic />
		<router-view />
	</div>
</template>

<script setup>
import { onMounted, computed } from 'vue'
import TopNavbarPublic from './components/Generel/TopNavbarPublic.vue'
import addResizeListener from './essentials/add-resize-listener'

addResizeListener()

const navbarStyling = computed(() => 'add-margin-top-for-navbar')

onMounted(async () => {
	document.title = "Across Property"
})
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;400;600;700&display=swap');

/* styling cookie script */
#cookiescript_description > span {
	color: #FFF;
}

#cookiescript_manage > span {
	color: #FFF;
}

#cookiescript_accept {
	background-color: #3fe396 !important;
	font-weight: 800 !important;
}
/* End styling cookie script */

#app {
	background-color: #FFF;
	height: 100%;
}

* {
font-family: "Outfit", 'Inter', Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #3B4868;
}

* .font-weight-600 {
	color: inherit;
	font-size: inherit;
	font-weight: 600;
}

* .font-weight-700 {
	color: inherit;
	font-size: inherit;
	font-weight: 700;
}

#bootstrap-overrides h1 {
	color: #3B4868;
	font-size: 2rem;
}

h4 {
	color: #3B4868;
}

.spacer-top {
	padding-top: 60px;
}

.spacer-bottom {
	margin-top: 100px;
}

/* spacing to compensate for fixed navbar height */
#spacing-navbar {
	padding-top: 15px;
}

/* link color */
#bootstrap-overrides a {
	text-decoration: none;
	color: #39b3b7;
	transition: color .2s;
}

#bootstrap-overrides a:hover {
	color: #2B898C;
}

.invalid-input {
	color: #ff3300;
	font-size: 14px;
}

input::placeholder {
	color: rgb(170, 170, 170) !important;
}

.info-icon {
	width: 15px;
	margin-left: 5px;
}

.icon {
	width: 15px;
}

/* Tooltip container */
.tooltip-info {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltip-info .tooltiptext-info {
	visibility: hidden;
	background-color: #999;
	color: #fff;
	text-align: center;
	margin-left: 5px;
	padding: 5px 10px;
	border-radius: 6px;
	top: -5px;
	left: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-info:hover .tooltiptext-info {
	visibility: visible;
}

.form-range {
	margin-top: 7px;
}

/* tiles (box) around communities and listings*/
.ap-tile {
	border: 1px solid #ced4da;
	border-radius: 1rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

	backdrop-filter: blur(9.5px);
	padding: 1rem;
}

.ap-tile-market {
	min-height: 0px;
}

.ap-tile:hover {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* "button-ap-1" best combined with bootstrap "btn" class */
#bootstrap-overrides .button-ap-1 {
	background-color: #3fe396;
	color: #fff;
	font-weight: 800;
	padding: 10px 35px;
	border: 1px solid #3fe396;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

#bootstrap-overrides .button-ap-1:hover {
	background-color: #fff;
	color: #3fe396;
}

/* "button-ap-2" best combined with bootstrap "btn" class */
#bootstrap-overrides .button-ap-2 {
	background-color: #39b3b7;
	color: #fff;
	font-weight: 800;
	padding: 10px 35px;
	border: 1px solid #39b3b7;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

#bootstrap-overrides .button-ap-2:hover {
	background-color: #fff;
	color: #39b3b7;
}

#bootstrap-overrides .button-ap-3 {
	background-color: #39b3b7;
	color: #fff;
	font-weight: 800;
	padding: 10px 35px;
	border: 1px solid #39b3b7;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

#bootstrap-overrides .button-ap-3:hover {
	background-color: #fff;
	color: #39b3b7;
}

/* feedback button fixed to bottom right */
.button-feedback {
	position: fixed;
	bottom: 50px;
	right: 30px;
	z-index: 960;
	animation: wobble 11s ease infinite;
}

/* badges on market segment overview tiles */
#bootstrap-overrides .badge-ap {
	background-color: #3fe396;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
}

/* input range (Sliders) */
#bootstrap-overrides .form-range::-webkit-slider-thumb {
	background: #39b3b7;
}

#bootstrap-overrides .form-range::-webkit-slider-thumb:active {
	background: #39b3b7a8;
}

.description{
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	text-align: left;
}

.background-broker {
	position: fixed;
	height: 100%;
	width: 100%;
	/* margin: 0 auto; */
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.5;
	z-index: 970;
}


.very-small-text {
	font-size: 0.6rem
}

.small-text {
	font-size: 0.8rem
}

.clickable {
	cursor: pointer
}

.selected {
	font-size: 0.8rem;
	font-weight: bold;
	color: #39b3b7;
}

.unselected {
	font-size: 0.6rem;
	cursor: pointer;
	vertical-align: middle;
}

.warning {
	font-size: 0.6rem;
	color: red;
}

.background-darkend {
	position: fixed;
	height: 100%;
	width: 100%;
	/* margin: 0 auto; */
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.5;
	z-index: 998;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
