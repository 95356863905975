<template>
    <div class="apartment-feature-filter">
        <div class="apartment-feature-filter--title">Merkmale</div>
        <div class="apartment-feature-filter--filters">
            <ap-checkbox
                label="Vermietet"
                v-model="store.state.matchingInvestInputs.featureFilters.isRentedOut"
                @change="updateFilter('isRentedOut', isRentedOut)" />
            <ap-checkbox
                label="Balkon oder Terrasse"
                v-model="store.state.matchingInvestInputs.featureFilters.hasBalcony"
                @change="updateFilter('hasBalcony', hasBalcony)" />
            <ap-checkbox
                label="Erdgeschoss"
                v-model="store.state.matchingInvestInputs.featureFilters.isGroundFloor"
                @change="updateFilter('isGroundFloor', isGroundFloor)" />
            <ap-checkbox
                label="Dachgeschoss"
                v-model="store.state.matchingInvestInputs.featureFilters.isTopFloor"
                @change="updateFilter('isTopFloor', isTopFloor)" />
        </div>
        <hr class="apartment-feature-filter--separator">
    </div>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    isReactive: {
        type: Boolean,
        default: false
    }
})
const isReactive = toRef(props, 'isReactive')

const store = useStore()

const isRentedOut = computed(() => store.state.matchingInvestInputs.featureFilters.isRentedOut)
const hasBalcony = computed(() => store.state.matchingInvestInputs.featureFilters.hasBalcony)
const isGroundFloor = computed(() => store.state.matchingInvestInputs.featureFilters.isGroundFloor)
const isTopFloor = computed(() => store.state.matchingInvestInputs.featureFilters.isTopFloor)

const updateFilter = (filterName, value) => {
    store.state.matchingInvestInputs.featureFilters[filterName] = value
    if (isReactive.value) {
        store.dispatch("updateUrl")
        store.dispatch("getMatchingInvestment")
    }
}

</script>

<style lang="scss">
.apartment-feature-filter {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.apartment-feature-filter--title {
    font-weight: 600;
}


.apartment-feature-filter--separator {
    margin: 0.75rem 0 0 0;
}

.apartment-feature-filter--filters {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    margin: 0 0 0 1rem;
}
</style>