<template>
    <div
        class="ap-dropdown"
        :data-disabled="disabled"
        :data-open="isOpen"
        @click="toggleDropdown()"
        v-on-click-outside="closeDropdown">
        <div class="ap-dropdown--selection-title">
            {{ displayTitle }}
        </div>
        <transition name="fade">
            <div
                v-if="isOpen"
                class="ap-dropdown--open"
                :class="{ 'limit-max-height': limitMaxSize }"
                :style="limitMaxSizeStyle">
                <div
                    v-for="option in options"
                    :key="option.id"
                    :data-disabled="option.disabled"
                    class="ap-dropdown--open--option"
                    @click.stop="select(option)">
                    {{ option.title }}
                </div>
            </div>
        </transition>
        <img v-if="isOpen" :src="chevronUpIcon" class="ap-dropdown--icon" />
        <img v-else :src="chevronDownIcon" class="ap-dropdown--icon" />
    </div>
</template>

<script setup>
import { computed, ref, defineProps, defineEmits } from 'vue'
import chevronDownIcon from '../../assets/chevron-down.svg'
import chevronUpIcon from '../../assets/chevron-up.svg'

const props = defineProps({
    value: Object, // TODO: same type as one option in options array 
    options: Array, // TODO: an option should have an id, title, disabled (optional) field
    disabled: Boolean,
    limitMaxSize: String,
})

const isOpen = ref(false)
const displayTitle = computed(() => props.value?.title ?? 'Bitte auswählen...')
const limitMaxSizeStyle = computed(() => `max-height: ${props.limitMaxSize * 2.5}rem;`)

const toggleDropdown = () => {
    if (!props.disabled) {
        isOpen.value = !isOpen.value
    }
}

const closeDropdown = () => {
    isOpen.value = false
}

const select = (option) => {
    if (option.disabled) {
        return
    }
    if (props.value?.id !== option.id) {
        emit('select', option)
    }
    closeDropdown()
}

const emit = defineEmits(['select'])
</script>

<style lang="scss" scoped>

.ap-dropdown {
    --dropdown-height: 2.5rem;

    background-color: white;
    border: 0.0625rem #C2C9D1 solid;
	border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.3125rem rgba(black, 10%);
    transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;

	cursor: pointer;
    display: inline-block;
    height: var(--dropdown-height);
    position: relative;
	width: 100%;

    &--selection-title {
		color: #3B4868;
        font-size: 0.875rem;
        line-height: 1;
        padding: 0.75rem 0.5rem;
    }
		
    &--icon {
        animation: rotation 300ms  linear;
        height: calc(var(--dropdown-height) / 2);
        opacity: 0.6;
        pointer-events: none;
        position: absolute;
        right: calc(var(--dropdown-height) / 4);
        top: calc(var(--dropdown-height) / 4);

        @keyframes rotation {
            from {
                transform: rotate(180deg);
            }

            to {
                transform: rotate(0deg);
            }
        }
    }

    &[data-open=true] {
        border: #39B3B7 0.0625rem solid;
		box-shadow: 0 0 0.3125rem #39B3B755;
    }

    &:hover {
        border: #39B3B7 0.0625rem solid;
		box-shadow: 0 0 0.3125rem #39B3B755;
    }
}

.ap-dropdown[data-disabled=true] {
    background-color: rgba(black, 0.15);
    cursor: not-allowed;
}

.ap-dropdown[data-disabled=true] > .ap-dropdown--selection-title {
    color: rgba(black, 0.25);
}

.ap-dropdown--open {
    background-color: white;
    border: 0.0625rem #C2C9D1 solid;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.3125rem rgba(black, 15%);
    height: auto;
    max-height: 15rem;
    overflow-y: scroll;
    right: 0;
    position: absolute;
    top: calc(var(--dropdown-height) + 0.5rem);
    width: 100%;
    z-index: 10000;

    &--option {
        align-items: center;
        color: #3B4868;
        display: flex;
        height: var(--dropdown-height);
        padding: 0 0.5rem;
    }

    &--option:not([data-disabled=true]):hover {
        background-color: #EBF7F8;
        color: #3B4868;
    }

    &--option[data-disabled=true] {
        color: rgba(#3B4868, 0.5);
        cursor: not-allowed;
    }

    &--option:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }

    &--option:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    &--option:not(.ap-dropdown--open--option:last-child) {
        border-bottom: 0.0625rem rgba(#C2C9D1, 0.25) solid;
    }

    &.limit-max-height {
        overflow-y: auto;
    }
}
</style>