<template>
    <div class="comparison" id="rent-comparison">
        <h4 class="comparison--title">
            Die pot. Miete dieser Immobilie ist
            <span class="comparison--title--highlight">{{ displayDifferenceRentMarket }} {{displayDifferencDescription}}</span>
            als umliegende Immobilien
        </h4>
        <div class="comparison-body">
            <div class="bar-charts">
            <ap-bar
                :primary-height="propertyColumnHeightInPercent"
                :value-label="displayPropertyRentPerSquareMeter"
                primary-color="#39B3B7"
                headline="Kaltmiete"
                label="Preis pro m²"
                labelColor="#39B3B7"/>
            <ap-bar
                :primary-height="medianColumnHeightInPercent"
                :value-label="displayMedianRentPerSquareMeter"
                primary-color="#D9D9D9"
                headline="Vergleich"
                label="Preis pro m²"
                labelColor="#616569"/>
            </div>
            <div class="legend">
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square primary" />
                    <div><span class="legend--item--name">Dieses Objekt:</span>
                        Potentielle Kaltmiete pro m² für dieses Objekt.
                        <span class="legend--item--caption">*Dieser Wert ist auf Basis von umliegenden Immobilien mit ähnlichen Objektdetails sowie Standort- und Preisfaktoren berechnet und dient als Indikator.</span></div>
                </div>
                <hr class="separator">
                <div class="legend--item">
                    <div class="legend--item--square secondary" />
                    <div><span class="legend--item--name">Umliegende Immobilien:</span>
                        Mietpreis pro m² für umliegende Immobilien.
                        <span class="legend--item--caption">*Dieser Wert entspricht dem Median umliegender Immobilien des gleichen Immobilientyps und Region. Er dient als Indikator.</span></div>
                </div> 
                <hr class="separator">
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const propertyRentPerSquareMeter = computed(() => store.state.expose.rent_m2)
const medianRentPerSquareMeter = computed(() => store.state.exposeStats.medianRentPerSquareMeter)

const displayPropertyRentPerSquareMeter = computed(() => `${propertyRentPerSquareMeter.value?.toLocaleString('de')} €`)
const displayMedianRentPerSquareMeter = computed(() => `${medianRentPerSquareMeter.value?.toLocaleString('de')} €`)

const propertyColumnHeightInPercent = computed(() => propertyRentPerSquareMeter.value > medianRentPerSquareMeter.value
    ? 100
    : propertyRentPerSquareMeter.value / medianRentPerSquareMeter.value * 100)

const medianColumnHeightInPercent = computed(() => propertyRentPerSquareMeter.value > medianRentPerSquareMeter.value
    ?  medianRentPerSquareMeter.value / propertyRentPerSquareMeter.value * 100
    : 100)

const displayDifferenceRentMarket = computed(() => `${Math.abs(propertyRentPerSquareMeter.value - medianRentPerSquareMeter.value).toLocaleString('de')} €`)
const displayDifferencDescription = computed(() => propertyRentPerSquareMeter.value < medianRentPerSquareMeter.value
    ? 'günstiger'
    : 'teurer')

</script>

<style lang="scss" scoped>
@import '../../../../Styles/_comparisonCharts.scss';

.comparison--title--highlight {
    color: #F3B502;
}

.legend--item--square {
    &.primary {
        background-color: #39B3B7;
    }

    &.secondary {
        background-color: #D9D9D9;
    }
}
</style>