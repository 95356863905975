<template>
    <div class="consultant-cta-mobile">
        <ap-button 
        class="consultant-cta-mobile--button dark"
        label="Kostenlose Erstberatung"
        @click="openContactDialog()" />
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import UserDataSubmissionDialog from './UserDataSubmissionDialog.vue'
import openDialog from '@/essentials/components/ap-dialog/open-dialog'

const store = useStore()

const openContactDialog = async () => {
    const sendRequestFunction = store._actions?.saveIdentifiedUserData[0]

    openDialog(UserDataSubmissionDialog, {
        title: 'Lassen Sie sich zur Immobilie und dessen Finanzierung beraten.',
        subtitle: 'Unsere Experten gehen speziell auf Ihre Wünsche ein.',
        email: store.state.anonymousUserEmail,
        callToAction: 'consultantOrdered', // auf keinen Fall verändern - Niko
        sendRequestFunction: sendRequestFunction,
    })
}

</script>

<style lang="scss" scoped>
$image-height: 4.5rem;

.consultant-cta-mobile {
    align-items: center;
    background: #3B4868;
    display: flex;
    gap: 1rem;
    padding:  1rem 1.5rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;

    &--button {
        width: 100%;
    }
}



</style>