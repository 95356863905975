import { createWebHistory, createRouter } from 'vue-router'
import MatchingInvestView from '../components/MatchingInvestView.vue'
import UnsubscribeView from '../components/UnsubscribeView.vue'
import NotFoundView from '../components/NotFoundView.vue'

const routes = [
	{
		name: 'start',
		path: '/',
		component: MatchingInvestView,
		children: [
			{
				name: 'results',
				path: '/results',
				component: MatchingInvestView,
			},
			{
				name: 'expose',
				path: '/expose',
				component: MatchingInvestView,
			},
			{
				name: 'email-verification',
				path: '/email-verification',
				component: MatchingInvestView,
			},
		]
	},
	{
		name: 'unsubscribeSearchRequest',
		path: '/unsubscribe/searchRequest',
		component: UnsubscribeView,
	},
	{
		name: 'not-found',
		path: '/:pathMatch(.*)*',
		component: NotFoundView,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
